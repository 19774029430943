import React from 'react';
import rawToReact from 'Utils/bbcode/RawToReact';

import SvgIcon from 'Components/svg/SvgIcon';
import DateComponent from 'Components/common/dateComponent';

import { resetOverlay, showLoginModal, showConfirmModal } from 'Actions/UiActions';
import { updateFollowing } from 'Actions/ForumviewActions';

class ForumComponent extends React.Component {
	subscribeForum = ( event ) => {
		let userState = this.props.ustate, isSub, subType;

		event.stopPropagation(); // stop here

		if( userState.id ) {
			if (typeof this.props.forum !== 'undefined' ) {
				isSub = !!this.props.forum.isSubscribed;
				subType = 'forum';
			} else {
				isSub = !!this.props.thread.isSubscribed;
				subType = 'topic';
			}

			if( isSub  ) {
				this.props.dispatch( showConfirmModal( 'Unsubscribe', `Would you like to unsubscribe from this ${subType}?`, () => this.props.dispatch( updateFollowing(this.props) ) ) );
			} else {
				this.props.dispatch( updateFollowing(this.props) );
			}

		} else {
			// for guests show the login modal
			this.props.dispatch( resetOverlay() );
			this.props.dispatch( showLoginModal() );
		}
	};

	render() {
		const {id, title, topicCounter, lastPost, isRead, isSubscribed } = this.props.forum;

		return (
			<li onClick={ () => this.props.onClick( id ) } >
				<div className={ `forum${ isRead ? '' : ' indicator' }${ isSubscribed ? ' subscribed' : '' }` }>
					<div className="title">
						{ rawToReact(title) }
					</div>
					<div className="subscribe" onClick={ this.subscribeForum } >
						<SvgIcon size="20px" icon="subscribe"/>
					</div>
					<div className="stats">
						<div className='left-stats'>
							<SvgIcon size="12px" icon="forum" />
							{ topicCounter.toLocaleString() } topics
						</div>
						<DateComponent timestamp={ lastPost } icon={ true } />
					</div>
				</div>
			</li>
		);
	}
}

export default ForumComponent;