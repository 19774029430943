import {
	FETCH_RELEASE_NOTES,
	FETCH_RELEASE_NOTES_SUCCESS,
	RELEASE_NOTES_FAILURE,
	RESET_RELEASE_NOTES,
	SET_RELEASE_NOTES,
} from '../actions/ReleaseNotesActions';

import { LOADING_BEHAVIOR } from '../actions/InfiniteScrollActions';

import merge from 'lodash/merge';

const initialState = {
	entities: {},
	result: {
		notes: []
	},
	page: 0,
	isFetching: false,
	loaded: false,
	lastError: '',
	pages: []
};

export default function releaseNotes( prevState = initialState, action ) {

	switch( action.type ) {
		case FETCH_RELEASE_NOTES:
			return {
				...prevState,
				isFetching: true,
				loaded: !(action.loadingBehavior === LOADING_BEHAVIOR.REFRESH)
			};

		case FETCH_RELEASE_NOTES_SUCCESS:
			if( action.loadingBehavior === LOADING_BEHAVIOR.REFRESH ) {
				return {
					...prevState,
					...action.data,
					lastError: '',
					isFetching: false,
					loaded: true,
					pages: [ parseInt( action.data.page ) ]
				};

			} else {
				let pages, notes;
				if( action.loadingBehavior === LOADING_BEHAVIOR.PREPEND ) {
					notes = [ ...action.data.result.notes, ...prevState.result.notes ];
					pages = [ action.data.page, ...prevState.pages ];
				} else {
					notes = [ ...prevState.result.notes, ...action.data.result.notes ];
					pages = [ ...prevState.pages, action.data.page ];
				}
				return merge(
					{},
					prevState,
					action.data,
					{
						result: {
							notes: notes
						},
						lastError: '',
						isFetching: false,
						loaded: true,
						pages: pages
					}
				);
			}

		case RELEASE_NOTES_FAILURE:
			return Object.assign( {}, prevState, {
				lastError: action.error,
				isFetching: false
			} );

		case RESET_RELEASE_NOTES:
			return initialState;

		case SET_RELEASE_NOTES:
			return {
				...action.state,
				cacheDate: action.date,
				cacheChanged: false,
			};

		default:
			return prevState;
	}
};

