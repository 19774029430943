import { createSelector } from 'reselect';

const routerSelector = state => state.router;
const topifyPresetsSelector = state => state.topifyPresets;
const userStateSelector = state => state.ustate;
const uiStateSelector = state => state.ui;
const adsStateSelector = state => state.ads;

export default createSelector(
	[routerSelector, topifyPresetsSelector, userStateSelector, uiStateSelector, adsStateSelector],
	(router, topifyPresets, ustate, ui, ads) => {
		return {
			topifyPresets: topifyPresets,
			ads: ads,
			ustate: ustate,
			router: router,
			overlayElements: {
				sidebarOpened: ui.sidebarOpened,
				searchBarOpened: ui.searchBarOpened,
				headerOverflowOpened: ui.headerOverflowOpened,
				loginModalOpened: ui.loginModalOpened,
				confirmModalOpened: ui.confirmModalOpened,
				globalLoading: ui.globalLoading,
				replyModalOpened: ui.replyModal.opened,
				userModalOpened: ui.userModal.opened,
				shareMenuOpened: ui.shareMenuOpened,
				announcementsOpen: ui.announcementsOpen
			},
			styleTheme: ui.userSettings.styleTheme
		}
	}
);
