import React from 'react';
import { connect } from 'react-redux';

import queryString from 'query-string';

import forumViewSelector from 'Selectors/forumViewSelector';

import { fetchForum } from 'Actions/ForumviewActions';
import { onPageView } from 'Actions/adsActions';
import { resetInfinite } from 'Actions/InfiniteScrollActions';
import { resetForum } from 'Actions/ForumviewActions';
import { resetThread } from 'Actions/ThreadviewActions';
import { resetSelectedItem } from 'Actions/MetaActions';
import { closeAnnouncements } from 'Actions/UiActions';

import Breadcrumb from 'Components/common/BreadcrumbComponent';
import ThreadList from 'Components/threads/ThreadListComponent';
import AnnouncementsView from 'Components/forums/AnnouncementsViewComponent';
import ForumList from 'Components/forums/ForumListComponent';
import AdComponent from 'Components/ads/AdComponent';
import InfiniteScroll from 'Components/infiniteScroll/InfiniteScrollComponent';
import LoaderCss from 'Components/common/LoaderCssComponent';
import OffLineNote from 'Components/alerts/OfflineNoteComponent';

class ForumViewComponent extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			loaded: false,
			forumId: null,
			page: 1
		};
	}

	static getDerivedStateFromProps( nextProps, prevState ) {
		let newState = {},
			forumId = nextProps.match.params.forumId || null,
			parsedQueryString = (nextProps.location && nextProps.location.search ? queryString.parse( nextProps.location.search ) : {}),
			page = parseInt( parsedQueryString.page ) || 1;

		// update page and forumId
		if( forumId !== prevState.forumId ) {
			newState.forumId = forumId;
		}
		if( page !== prevState.page ) {
			newState.page = page;
		}

		// state update necessary?
		return Object.keys( newState ).length ? newState : null;
	}

	componentDidMount() {
		this.props.dispatch( resetSelectedItem() );
		this.props.dispatch( resetInfinite() );
		this.props.dispatch( resetForum() );
		this.props.dispatch( resetThread() );
		this.props.dispatch( closeAnnouncements() );

		this.props.dispatch( fetchForum( {
			id: this.state.forumId,
			page: this.state.page
		} ) );
	}

	componentWillUnmount() {
		this.props.dispatch( resetSelectedItem() );
		this.props.dispatch( resetForum() );
		this.props.dispatch( resetThread() );
		this.props.dispatch( resetInfinite() );
		this.props.dispatch( closeAnnouncements() );
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			nextProps.forumview.loaded !== this.props.forumview.loaded
			|| nextProps.forumview.isFetching !== this.props.forumview.isFetching
			|| nextProps.forumview.result.topics.length !== this.props.forumview.result.topics.length
			|| nextProps.forumview.forumId !== this.state.forumId
			|| nextState.forumId !== this.state.forumId
		);
	}

	componentDidUpdate( prevProps, prevState ) {
		if( prevState.forumId !== this.state.forumId ) {
			this.props.dispatch( resetSelectedItem() );
			this.props.dispatch( closeAnnouncements() );

			requestAnimationFrame( () => document.getElementById( 'appContainer' ).scrollTop = 0 );

			this.props.dispatch( fetchForum( {
				id: this.state.forumId,
				page: 1,
				forceRefresh: true
			} ) );
		}

		if( this.props.forumview.loaded && !prevProps.forumview.loaded ) {
			// trigger ad updates
			this.props.dispatch( onPageView( { captify: "", pageTransition: true } ) );
		}
	}

	loadPage = ( page, forceRefresh ) => {
		this.props.dispatch(fetchForum({
			id: this.state.forumId,
			page: page,
			forceRefresh: forceRefresh
		}));
	};

	render() {
		const { loaded, isFetching, lastError, totalPages } = this.props.forumview;

		if( totalPages === 0 && lastError === 'No connection' && !isFetching ) {
			return <OffLineNote />
		}

		if( !loaded ) {
			return <LoaderCss />;
		}

		let campaignAd = <AdComponent key={ 'Campaign_Top_Ad' } adType={ 108 } adName="Campaign_Top_Ad" />,
			bottomAdUnit = <AdComponent key={ 'Bottom_Ad' } adType={ 2 } adName="Bottom_Content_Rectangle" isFetching={ isFetching } />;

		return (
			<React.Fragment>
				<Breadcrumb routeParams={this.props.match.params} />
				<AnnouncementsView />
				<ForumList />
				{ campaignAd }
				<InfiniteScroll
					scrollRef={ i => this.infiniteScroll = i }
					isFetching={isFetching}
					loadPage={this.loadPage}
					className={ 'threads' }
				>
						<ThreadList inContentAd={ true } />
				</InfiniteScroll>
				{bottomAdUnit}
			</React.Fragment>
		);
	}
}

export default connect( forumViewSelector )( ForumViewComponent );