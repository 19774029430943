import React from 'react';
import PropTypes from 'prop-types';

const AFSAdComponent = ({ adName }) => {
	return <div id={ adName } className="afs-ad" style={{width:'auto', height:'auto'}} />;
};

AFSAdComponent.propTypes = {
	adName: PropTypes.string.isRequired
};

export default AFSAdComponent;