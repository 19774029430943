import { combineReducers } from 'redux';

import ads from 'Reducers/ads';
import forumview from 'Reducers/forumview';
import infiniteScroll from 'Reducers/infinitescroll';
import meta from 'Reducers/meta';
import ustate from 'Reducers/ustate';
import threadview from 'Reducers/threadview';
import messagesview from 'Reducers/messagesview';
import notices from 'Reducers/notices';
import topifyPresets from 'Reducers/topifypresets';
import searchresult from 'Reducers/searchresult';
import ui from 'Reducers/ui';
import userprofileview from 'Reducers/userprofile';
import photogallery from 'Reducers/photogallery';
import onlineUsers from 'Reducers/onlineUsers';
import releaseNotes from 'Reducers/releaseNotes';
import portalpage from 'Reducers/portalpage';
//import widgetState from 'Reducers/widgetReducer';

export default combineReducers( {
	ads,
	forumview,
	infiniteScroll,
	meta,
	ustate,
	threadview,
	messagesview,
	notices,
	topifyPresets,
	searchresult,
	ui,
	userprofileview,
	photogallery,
	onlineUsers,
	releaseNotes,
	portalpage,
//	widgetState
} );
