import React from 'react';
import PropTypes from 'prop-types';

const InContentAdComponent = ({ adName, height }) => {
	return <div id={ adName } style={ { width:'100%', height: ( height || 'auto' ) } } />;
};

InContentAdComponent.propTypes = {
	adName: PropTypes.string.isRequired
};

export default InContentAdComponent;
