import React from 'react';
import { connect } from 'react-redux';

import SvgIcon from 'Components/svg/SvgIcon';

import { gotoRegister, offLineNotification } from "Actions/GlobalMetaActions";
import { registrationBarToggle } from "Actions/UiActions";
import { trackGAEvent } from "Actions/adsActions";

class registrationCall extends React.PureComponent {
	constructor(props) {
		super( props );
		this.state = {
			wasHidden: this.props.wasHidden
		}
	}

	register = () => {
		if( this.props.isOnline === false ) {
			this.props.dispatch( offLineNotification( { name: 'OfflineAction' } ) );
			return false;
		}

		// Record the Google Analytics event.
		this.props.dispatch( trackGAEvent( 1, 'register', 'userAction', 'registerBar' ) );

		this.props.dispatch( gotoRegister( this.props.registerUrl, this.props.thisLocation ) );
	};

	toggleNotch = () => {
		if( this.regBar ) {
			this.regBar.classList.add( 'animation' );
			this.regBar.classList.toggle( 'collapsed' );
		}
		this.props.dispatch( registrationBarToggle() );
	};

	render() {
		if( !this.props.registerUrl ) {
			return null;
		}

		let notch = this.props.type === 'breadcrumb' ? <span className="notch" onClick={ this.toggleNotch }><SvgIcon size="16px" icon="angle_up" /></span> : null,
			className = `${ this.props.type || 'index' }${ this.state.wasHidden || !this.props.isOnline ? ' collapsed' : '' }` ;

		return <div id="registerCall" className={ className } ref={ r => this.regBar = r } >
			<span>Sign up now! It's free and easy.</span>
			<div className="action-buttons inline-buttons">
				<button className="action-button autosize-button neutral" onClick={ this.register }>Register &#x25B8;</button>
			</div>
			{ notch }
		</div>;
	}
}

const mapStateToProps = ( state ) => {
	return {
		registerUrl: state.ustate.registerUrl,
		thisLocation: state.router.location,
		wasHidden: state.ui.registrationBarHidden,
		isOnline: state.meta.isOnline
	}
};

export default connect( mapStateToProps )( registrationCall );