import { ADD_PHOTO, REMOVE_PHOTO, SHOW_GALLERY, HIDE_GALLERY, RESET_GALLERY } from 'Actions/PhotoGalleryActions'
import last from 'lodash/last'

const initialState = {
	items: {},
	postId: null,
	isOpen: false,
	options: {
		shareEl: false,
		shareButtons: [],
		closeOnScroll: true,
		closeOnVerticalDrag: true
	}
};

export default function photogallery( prevState = initialState, action ) {
	let prevStateItems = prevState.items,
		items = {};
	switch( action.type ) {
		case ADD_PHOTO:
			let prevItems = prevStateItems[ action.item.postId ];

			if( prevItems === undefined ) {
				items = Object.assign(
					{},
					prevStateItems,
					{
						[ action.item.postId ]: [
							{
								...action.item,
								index: 0
							}
						]
					}
				);

			} else if( prevItems.find( item => item.id === action.item.id ) ) { // skip duplicates
				return prevState;

			} else {
				let lastItem = last( prevItems ),
					lastIndex = lastItem.index;
				items = Object.assign(
					{},
					prevStateItems,
					{
						[ action.item.postId ]: [
							...prevItems,
							{
								...action.item,
								index: (lastIndex + 1)
							}
						]
					}
				);
			}
			return {
				...prevState,
				items
			};

		case REMOVE_PHOTO:
			items = prevStateItems;
			// delete all attachments for the post Ids given
			action.items.forEach( postId => delete items[ postId ] );

			return {
				...prevState,
				items
			};

		case SHOW_GALLERY:
			return {
				...prevState,
				postId: action.postId,
				options: Object.assign(
					prevState.options,
					action.options
				),
				isOpen: true
			};

		case HIDE_GALLERY:
			return {
				...prevState,
				postId: null,
				isOpen: false
			};

		case RESET_GALLERY:
			return initialState;

		default:
			return prevState;
	}
}