import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Thread from 'Components/threads/ThreadComponent';

import { goToThread } from 'Actions/ForumviewActions';

import forumViewSelector from 'Selectors/forumViewSelector';

class StickyThreadListComponent extends React.Component {
	gotoThread = ( event ) => {
		let id = event.currentTarget.dataset.threadid || null;
		this.props.dispatch( goToThread( id ) );
	};

	buildTopic = (key, topic, users) => {
		return (
			<Thread
				key={ `sticky_${key}` }
				thread={ topic }
				isSubscribed={ topic.isSubscribed }
				isSubscribable={ true }
				user={ users[topic.forumUser] }
				users={ users }
				dispatch={ this.props.dispatch }
				ustate={ this.props.ustate }
				isSelected={ !!( this.props.meta.selectedElements[ topic.id ] ) }
				onClick={ this.gotoThread }
			/>
		);
	};

	render() {
		const {entities: {users, sticky}, result} = this.props.forumview;

		if( result.sticky.length === 0 ) {
			return null
		}

		const stickyList = result.sticky.map( ( k, i ) => this.buildTopic( i, sticky[ k ], users ) );

		return (
			<div id="stickies" className="threads sticky">
				<div className="list-summary">
					Sticky Topics ( { result.sticky.length } Topic{ result.sticky.length === 1 ? '' : 's' } )
				</div>
				<ul>
					{ stickyList }
				</ul>
			</div>
		);
	}
}

export default withRouter( connect( forumViewSelector )( StickyThreadListComponent ) );