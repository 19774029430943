import {
	SET_GLOBAL_META,
	TOGGLE_SELECTED_ITEM,
	RESET_SELECTED_ITEM,
	SET_CACHE_BUST,
	RESET_META_LOADING,
	SET_META,
	ONLINE_STATUS
} from '../actions/MetaActions';

import { FORUMS_INDEX_FAILURE } from "../actions/ForumsIndexActions";
import { FORUM_SUCCESS, FORUM_FETCH, FORUM_FAILURE } from '../actions/ForumviewActions';
import { MESSAGES_SUCCESS, MESSAGES_FETCH, MESSAGES_FAILURE } from '../actions/MessagesviewActions';
import { FETCH_ONLINE_USERS_SUCCESS, FETCH_ONLINE_USERS, ONLINE_USERS_FAILURE } from '../actions/OnlineUsersActions';
import { PORTALPAGE_SUCCESS, PORTALPAGE_FETCH, PORTALPAGE_FAILURE } from '../actions/PortalPageActions';
import { SEARCH_SUCCESS, SEARCH_FETCH, SEARCH_FAILURE } from '../actions/SearchResultActions';
import { THREAD_SUCCESS, THREAD_FETCH, THREAD_FAILURE } from '../actions/ThreadviewActions';
import { USERPROFILE_SUCCESS, USERPROFILE_FETCH, USERPROFILE_FAILURE } from '../actions/UserprofileActions';
import { WIDGET_SUCCESS, WIDGET_FETCH } from '../actions/WidgetActions';
import {
	FETCH_RELEASE_NOTES,
	FETCH_RELEASE_NOTES_SUCCESS,
	RELEASE_NOTES_FAILURE
} from '../actions/ReleaseNotesActions';

const state = {
	cacheBust: 0,
	isFetchingAny: false,
	isLoading: false,
	isOnline: true,
	page: 1,
	pagesize: 10,
	path: [],
	permissions: {},
	postid: null,
	selectedElements: {},
	subTitle: null,
	threadid: null,
	title: 'Loading...',
	total: 0,
	totalPages: 0
};

const initialState = {
	...state,
	prevState: {}
};

let pathName = '';

export default function meta( prevState = initialState, action ) {
	switch( action.type ) {
		case TOGGLE_SELECTED_ITEM:
			let seletedItem = { ...prevState.selectedElements };

			if( seletedItem[ action.id ] ) { // delete it if it's already there
				delete seletedItem[ action.id ];
			} else { // add it if it is not
				seletedItem[ action.id ] = true;
			}

			return {
				...prevState,
				selectedElements: seletedItem
			};

		case RESET_SELECTED_ITEM:
			return {
				...prevState,
				selectedElements: {}
			};

		case SET_GLOBAL_META:
			// Expand default values keeping isOnline, cacheBust and selectedItems
			let newState = Object.assign( {}, state, {
				isOnline: prevState.isOnline,
				cacheBust: prevState.cacheBust,
				selectedElements: prevState.selectedElements
			}, action.meta );
			newState.total = newState.total ? parseInt( newState.total ) : 0;
			return {
				...prevState,
				...newState,
				title: action.title,
				subTitle: action.subTitle,
				isLoading: false,
				isFetchingAny: prevState.isFetchingAny
			};

		case SET_CACHE_BUST:
			return {
				...prevState,
				cacheBust: action.cacheBust
			};

		case '@@router/LOCATION_CHANGE': // We use this to trigger location change events
		case 'Navigation/NAVIGATE':
			let isLoading = false;
			if( action.payload ) { // web version
				isLoading = prevState.isLoading ? true : (pathName !== action.payload.pathname);
				pathName = action.payload.pathname;
			} else { // native version
				if( [ 'DrawerOpen', 'DrawerClose' ].indexOf( action.routeName ) === -1 ) {
					isLoading = prevState.isLoading ? true : (pathName !== action.routeName);
					pathName = action.routeName;
				}
			}
			return {
				...prevState,
				isLoading: isLoading
			};

		case FORUM_FETCH:
		case MESSAGES_FETCH:
		case FETCH_ONLINE_USERS:
		case PORTALPAGE_FETCH:
		case SEARCH_FETCH:
		case THREAD_FETCH:
		case USERPROFILE_FETCH:
		case WIDGET_FETCH:
		case FETCH_RELEASE_NOTES:
			return {
				...prevState,
				isFetchingAny: true
			};

		case FORUMS_INDEX_FAILURE:
		case FORUM_FAILURE:
		case MESSAGES_FAILURE:
		case SEARCH_FAILURE:
		case THREAD_FAILURE:
		case USERPROFILE_FAILURE:
		case ONLINE_USERS_FAILURE:
		case RELEASE_NOTES_FAILURE:
		case PORTALPAGE_FAILURE:
		case RESET_META_LOADING:
		case FORUM_SUCCESS:
		case MESSAGES_SUCCESS:
		case FETCH_ONLINE_USERS_SUCCESS:
		case PORTALPAGE_SUCCESS:
		case SEARCH_SUCCESS:
		case THREAD_SUCCESS:
		case USERPROFILE_SUCCESS:
		case WIDGET_SUCCESS:
		case FETCH_RELEASE_NOTES_SUCCESS:
			return {
				...prevState,
				isFetchingAny: false,
				isLoading: false
			};

		case SET_META:
			return action.state;

		case ONLINE_STATUS:
			return {
				...prevState,
				isOnline: action.isOnline
			};

		default:
			return prevState;
	}
}