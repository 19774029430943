import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import isEqual from 'lodash/isEqual';

import Notice from 'Components/alerts/NoticeComponent';

import { noticeShown } from "Actions/NoticesActions";

class NoticesListComponent extends React.Component {
	constructor( props ) {
		super( props );
		this.noticesShown = [];
	}

	shouldComponentUpdate( nextProps ) {
		return (
			nextProps.isLoading !== this.props.isLoading ||
			!isEqual( nextProps.notices.entities, this.props.notices.entities )
		);
	}

	componentDidUpdate() {
		if( this.noticesShown.length ) {
			this.props.dispatch( noticeShown( this.noticesShown ) );
		}
		this.noticesShown = [];
	}

	buildNotice = ( key, notice ) => {
		return (
			<Notice
				key={ `notice_${ key }` }
				notice={ notice }
				dispatch={ this.props.dispatch }
				isLoggedIn={ this.props.userId }
			/>
		);
	};

	render() {
		if( this.props.isFetching ) {
			return null;
		}

		const { entities, result, shown } = this.props.notices;
		let counter = 0;

		const noticesList = result.map( ( notice, i ) => {
			if(
				shown[ notice ] === undefined && // did we already show it?
				entities[ notice ].content // and do we have content?
			) {
				counter++;
				// if it's persistent show it always until the user closes it
				if( entities[notice].persistent === false ) {
					this.noticesShown.push( notice );
				}
				return this.buildNotice( i, entities[ notice ] );
			}
		} );

		if( counter === 0 ) {
			return null;
		}

		return (
			<div id="notices" className="threads notices" >
				<ul>
					{ noticesList }
				</ul>
			</div>
		);
	}
}

NoticesListComponent.propTypes = {
	notices: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	userId: PropTypes.number.isRequired,
	isFetching: PropTypes.bool,
};

const mapStateToProps = ( state ) => {
	return {
		notices: state.notices,
		isLoading: state.meta.isLoading,
		userId: state.ustate.id
	};
};

export default connect( mapStateToProps )( NoticesListComponent );