export function threadFollowing( prevState, action ) {
	let entities = '';

	if( prevState.entities.topics && prevState.entities.topics[ action.threadId ] ) {
		entities = 'topics';
	} else if( prevState.entities.sticky && prevState.entities.sticky[ action.threadId ] ) {
		entities = 'sticky';
	} else {
		return prevState;
	}

	let prevEntitiesThread = prevState.entities[ entities ];
	let thread = prevState.entities[ entities ][ action.threadId ];

	thread.isSubscribed = action.follow;
	let entitiesThreads = {
		[ entities ]: Object.assign( {}, prevEntitiesThread, { [ action.threadId ]: thread } )
	};
	return Object.assign( {}, prevState, entitiesThreads );
}

export function forumFollowing( prevState, action ) {
	if( prevState.entities.forums ) {
		let prevEntitiesForum = prevState.entities.forums,
			forum = prevState.entities.forums[ action.forumId ];

		forum.isSubscribed = action.follow;
		let entitiesForum = {
			forums: Object.assign( {}, prevEntitiesForum, { [ action.forumId ]: forum } )
		};
		return Object.assign( {}, prevState, entitiesForum );
	}
	return prevState;
}