import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Thread from 'Components/threads/ThreadComponent';
import forumViewSelector from 'Selectors/forumViewSelector';

import AdComponent from 'Components/ads/AdComponent';

import { goToThread } from 'Actions/ForumviewActions';
import { getPageSize } from 'Actions/UserSettingsActions';

class ThreadListComponent extends React.Component {
	getFSAdUnit( topic, pageNum, isTop ) {
		let searchProp = {
			isTop: isTop,
			page: pageNum,
			showposts: false,
			searchQuery: this.props.forumview.query.searchQuery
		};

		return <AdComponent key={ `In_Search_Ad_${ isTop ? 'Top' : topic.id }` } adType={ 0 } adName={ `In_Search_Ad_${ isTop ? 'Top' : topic.id }` } search={ searchProp } tag="li" />;
	}

	gotoThread = ( event ) => {
		let id = event.currentTarget.dataset.threadid || null;

		this.props.dispatch( goToThread( id ) );
	};

	buildTopic = ( topic, i, pageNum, pageSize ) => {
		const { entities } = this.props.forumview,
			isSearch = this.props.isSearch;

		let the_topic = [],
			adUnit = null,
			k = i + 1;

		if( isSearch ) {
			if( pageNum === 1 && k === 1 ) {
				// we want the top search ad at the top, before the thread
				the_topic.push( this.getFSAdUnit( {}, pageNum, true ) );

			} else if( k % 10 === 0 ) {
				adUnit = this.getFSAdUnit( topic, pageNum, false );
			}

		} else {
			let lastPos = k === parseInt( this.props.forumview.total );

			if( k % 5 === 0 ) { // every 5 threads
				adUnit = <AdComponent key={`In_Content_Ad_${ topic.id }`} adType={ 1 } adName={'In_Content_Ad_' + topic.id} tag="li"/>;

			} else if( this.props.inContentAd && pageNum === 1 && ( k === 3 || ( lastPos && k < 3 ) ) ) { // after 3rd thread on 1st page or at the bottom if less than 3
				adUnit = <AdComponent key={ 'In_Content_Ad' } adType={ 5 } fallbackAdType={ [ 1 ] } adName="In_Content_Ad" tag="li" />;
			}
		}

		let thisUser = this.props.showLast && topic.lastUserid && entities.users[ topic.lastUserid ] ? entities.users[ topic.lastUserid ] : entities.users[ topic.forumUser ];


		let thread = <Thread
				key={ `topic_${ topic.id }` }
				thread={ topic }
				isSubscribed={ topic.isSubscribed }
				isSubscribable={ true }
				isSearch={ isSearch }
				user={ thisUser }
				dispatch={ this.props.dispatch }
				ustate={ this.props.ustate }
				showLast={ this.props.showLast }
				isSelected={ !!( this.props.meta.selectedElements[ topic.id ] ) }
				onClick={ this.gotoThread }
			/>;
		the_topic.push( thread );

		if( adUnit ) {
			the_topic.push( adUnit );
		}

		return the_topic;
	};

	buildPage = ( pageNum, pageSize ) => {
		const { entities, result } = this.props.forumview;

		let topics = result.topics.filter( e => entities.topics[ e ] && entities.topics[ e ].page === pageNum );
		return topics.map( ( id, i ) => this.buildTopic( entities.topics[ id ], i, pageNum, pageSize ) );
	};

	summary(){
		const {entities: {forums}, forumId, total} = this.props.forumview;
		if ( forums && forums.hasOwnProperty(forumId) ) {
			return <div className="summary">{ total.toLocaleString() } Topics</div>
		}
		return ''
	}

	render() {
		const { result } = this.props.forumview;

		if (result.topics.length === 0) {
			return <span />
		}

		const summary = this.props.page === 1 ? this.summary() : null,
			pageSize = this.props.dispatch( getPageSize() );
		let threadListPage = this.buildPage( this.props.page, pageSize );

		return (
			<React.Fragment>
				{ summary }
				<ul key={ this.props.page } ref={ this.props.listRef } className={`scroll_page thread_page page_${this.props.page}`}>
					{ threadListPage }
				</ul>
			</React.Fragment>
		);
	}
}

ThreadListComponent.propTypes = {
	dispatch: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
};

export default withRouter( connect( forumViewSelector )( ThreadListComponent ) );
