import React from 'react';

import Loadable from 'react-loadable';

// lazy loaded chunks
const UserModal = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/modals/UserModalComponentGuests' ),
	loading: () => null
} );
const UserModalLogged = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/modals/UserModalComponentLogged' ),
	loading: () => null
} );

const UserModalComponent = ( { isLoggedIn } ) => {
	return isLoggedIn ? <UserModalLogged /> : <UserModal />;
};

export default UserModalComponent;
