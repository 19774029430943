import React from 'react';

import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import configureStore from '../store/configureStore';
import { Route, Switch, Redirect } from 'react-router-dom';
import createHistory from 'history/createHashHistory';

import PageComponent from 'Components/PageComponent';

const history = createHistory();
const store = configureStore( history );

export default class Root extends React.Component {
	render() {
		return (
			<Provider store={ store }>
				<ConnectedRouter history={ history }>
					<Switch>
						{/* If it's a v2 URL Redirect to new format */}
						<Redirect from="/forumsite/:id*" to={ location.hash.replace( /(#\/forumsite\/\d+\/)/i, '/' ) } />
						<Route component={ PageComponent } />
					</Switch>
				</ConnectedRouter>
			</Provider>
		);
	};
}
