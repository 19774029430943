import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isEqual from 'lodash/isEqual';

import forumViewSelector from 'Selectors/forumViewSelector';
import { receiveGlobalMeta } from "Actions/MetaActions";
import { fetchForum, resetForum } from 'Actions/ForumviewActions';
import { onPageView, yieldmoWrapperCleaner } from 'Actions/adsActions';
import ForumList from 'Components/forums/ForumListComponent';
import LoaderCss from 'Components/common/LoaderCssComponent';
import RegistrationCall from 'Components/common/RegistrationCallComponent';
import OffLineNote from 'Components/alerts/OfflineNoteComponent';
import WidgetHotDealsComponent from "Components/widgets/WidgetHotDealsComponent";

import rawToReact from 'Utils/bbcode/RawToReact';
import AdComponent from 'Components/ads/AdComponent';
import prettyDate from 'Utils/time';

class ForumsIndexViewComponent extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			userBanned: false,
			forumId: null
		};
	}

	static getDerivedStateFromProps( nextProps ) {
		return {
			userBanned: nextProps.ustate && nextProps.ustate.is_banned && Object.keys( nextProps.ustate.is_banned ).length > 0,
			forumId: nextProps.forumview.forumId || null,
		};
	}

	componentDidMount() {
		this.props.dispatch( resetForum() );

		// is this index the landing page per config?
		let thisIsIndex = ( process.env.config.IndexRoute === 'index' && process.env.config.section.home === false );

		if(
			thisIsIndex &&
			this.props.firstLoad &&
			(
				this.props.initialRoute ||
				this.props.userSettings.landingPage !== 'index'
			)
		) {
			return;
		}

		requestAnimationFrame( () => document.getElementById( 'appContainer' ).scrollTop = 0 );

		// if the user is banned, skip getting data
		if( this._checkBannedUser() ) {
			return;
		}

		this.props.dispatch( fetchForum( {
			id: -1,
			size: 10,
			page: 1,
			forceRefresh: true
		} ) );
	}

	componentWillUnmount() {
		this.props.dispatch( resetForum() );
		yieldmoWrapperCleaner();
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			nextProps.meta.isOnline !== this.props.meta.isOnline ||
			nextProps.forumview.loaded !== this.props.forumview.loaded ||
			nextProps.forumview.isFetching !== this.props.forumview.isFetching ||
			(
				nextProps.forumview.entities !== undefined &&
				nextProps.forumview.entities.forums !== undefined &&
				!isEqual( nextProps.forumview.entities.forums, this.props.forumview.entities.forums )
			) ||
			nextState.userBanned !== this.state.userBanned ||
			nextState.forumId !== this.state.forumId
		);
	}

	componentDidUpdate( prevProps ) {
		if( this.props.forumview.loaded && !prevProps.forumview.loaded ) {
			requestAnimationFrame( () => document.getElementById( 'appContainer' ).scrollTop = 0 );

			// trigger ad updates
			this.props.dispatch( onPageView( { captify: "", pageTransition: true } ) );
		}
		// if we are back online check if we have data or get it
		if(
			prevProps.meta.isOnline === false
			&& this.props.meta.isOnline
			&& this.props.forumview.result.forums.length === 0
		) {
			this.props.dispatch( fetchForum( {
				id: -1,
				size: 10,
				page: 1,
				forceRefresh: true
			} ) );
		}
	}

	groupForums = () => {
		let { entities: { forums }, result } = this.props.forumview,
			structure = {},
			displayOrder = [],
			showHotDeals = this.props.hotDeals.deals && this.props.hotDeals.deals.length;

		result.forums.forEach(
			( v ) => {
				let forum = forums[ v ];

				if( parseInt( forum.parent ) === -1 ) { // Categories
					displayOrder.push( v );
				}

				// forums under top level create their own category
				// or categories under categories
				if( forum.isForum || parseInt( forum.parent ) !== -1 ) {
					let parent = parseInt( forum.parent ) !== -1 ? forum.parent : forum.id;
					if( !structure[ parent ] ) {
						structure[ parent ] = [];
					}
					structure[ parent ].push( forum.id );
				}
			}
		);

		return displayOrder
			.filter( t => structure[ t ] !== undefined ) // API sends missing data sometimes.
			.map(
				( v, i ) => {
					let adUnit = i === 1 ? <AdComponent key={`ad${ i }`} adType={ 9 } fallbackAdType={[ 5, 1 ]} adName={'In_Content_Ad'}/> : null,
						hotDealsWidget = showHotDeals && i === 1 ? <WidgetHotDealsComponent key={ `widget_hotdeals` } widgetName={ 'Hot Deals on WiseGuide' } widget={ this.props.hotDeals } /> : null,
						adUnitIncontent = ( i === 0 ); // first category

					let forumGroup = [
						<div key={`forumgroup${i}`}>
							<div className="forumGroup">
								{ rawToReact( forums[ v ].title ) }
							</div>
							<ForumList forumList={structure[ v ]} adUnitIncontent={adUnitIncontent}/>
						</div>
					];
					if( adUnit ) {
						forumGroup.push( adUnit );
					}
					if( hotDealsWidget ) {
						forumGroup.push( hotDealsWidget );
					}

					return forumGroup;
				}
			);
	};

	_checkBannedUser = () => {
		if( this.state.userBanned ) {
			this.props.dispatch( receiveGlobalMeta( null, 'User Banned', this.props.forumSite.f_title ) );
			return true;
		}
		return false;
	};

	render() {
		const {forumSite, ustate, forumview: { result, lastError, isFetching } } = this.props;

		let registerNow = ustate.id ? null : <RegistrationCall />,
			lists = this.props.forumview.loaded ?
				this.groupForums() :
				( result.forums.length === 0 && lastError === 'No connection' && !isFetching ? <OffLineNote /> : <LoaderCss /> );

		if( this.state.userBanned ) {
			let liftDate = parseInt( ustate.is_banned.liftdate ) > 0 ? <div className="">Date the ban will be lifted: { prettyDate( ustate.is_banned.liftdate, 'MMM DD, YYYY h:m a' ) }</div> : null;
			lists = <div className="forumGroup">
				<ul>
					<li>
						<div className="forum message">
							<div className="">You have been banned for the following reason: <b>{ustate.is_banned.reason}</b>.</div>
							{ liftDate }
						</div>
					</li>
				</ul>
			</div>;
		}

		return (
			<div className="forumRoot nolinks nooptimize norewrite noskim" >
				<div className="coverphoto">
					<img crossOrigin="anonymous" className="no-ad" src={forumSite.cover_photo} alt={forumSite.f_title} />
				</div>
				<div className="detail">
					<div className="title">
						{forumSite.f_title}
					</div>
					<div className="url">
						{forumSite.f_url}
					</div>
				</div>
				{ registerNow }
				{ lists }
				<AdComponent key={ 'Bottom_Ad' } adType={ 2 } adName="Bottom_Content_Rectangle" isFetching={ isFetching } />
			</div>
		);
	}
}

ForumsIndexViewComponent.propTypes = {
	forumSite: PropTypes.shape({
		cover_photo: PropTypes.string.isRequired,
		f_title: PropTypes.string.isRequired,
		f_url: PropTypes.string.isRequired
	})
};

export default connect( forumViewSelector )( ForumsIndexViewComponent );
