import React from 'react';
import PropTypes from 'prop-types';

const BlockthroughAdComponent = ( { adName, btId } ) => {
	const btContainer = btId ? <span className="bt-uid-tg" data-uid={ btId } style={ { display: 'none !important' } } /> : null;
	return <div key={ `${adName}_bt` } >{ btContainer }</div>;
};

BlockthroughAdComponent.propTypes = {
	adName: PropTypes.string.isRequired,
	btId: PropTypes.string
};

export default BlockthroughAdComponent;
