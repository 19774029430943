import React from 'react';

import CountDown from 'Components/common/CountDownComponent';

class RetryNote extends React.PureComponent {
	constructor(props) {
		super( props );

		this.state = {
			buttonActive: !isFinite( this.props.timeRetry )
		}
	}

	countDownDone = () => {
		this.setState( { buttonActive: true } )
	};

	render() {
		const { timeRetry, className, message, retry } = this.props;

		const retryMsg = timeRetry && isFinite( timeRetry ) ?
			<p className="subheader-selector">You can retry <CountDown time={ parseInt( timeRetry ) } prefix={ true } onComplete={ this.countDownDone } /></p> :
			null;

		return <header className={`thread topLink ${ className || ''  }`}>
			<p className={ `subheader-selector${ this.state.buttonActive && isFinite( timeRetry ) ? ' hidden' : '' }`}>{ message ? message : 'Oops! error loading!' }</p>
			{retryMsg}
			<p className="action-buttons">
				<button className={ `medium-button action-button${ this.state.buttonActive ? '' : ' hidden' }` } onClick={ retry }>Retry</button>
			</p>
		</header>;
	}
}

export default RetryNote;