import {
	GEOLOCATION,
	SHOW_INTERSTITIAL,
	HIDE_INTERSTITIAL,
	ADS_PERMISSIONS,
	RESET_HIDDEN_ADS,
	DOCUMENT_VISIBLE,
	GDPR_CONSENT
} from 'Actions/adsActions';

import { UPDATE_PAGE_NUMBER } from 'Actions/InfiniteScrollActions';

const initialState = {
	geoLocation: {
		allow: true,
		error: false,
		latitude: '',
		longitude: '',
		timestamp: 0
	},
	showInterstitial: false,
	vendorAds: false,
	noAdsPermissions: false,
	secondaryAds: false,
	resetHiddenAds: 0,
	documentVisible: false,
	GDPRConsent: {
		cookiesOk: false,
		trackUserOk: false,
		fcStatus: null
	},
	analytics: 0
};

export default function ads( state = initialState, action ) {
	switch( action.type ) {
		case GEOLOCATION:
			return Object.assign( {}, state, { geoLocation: action.location } );

		case SHOW_INTERSTITIAL:
			return {
				...state,
				showInterstitial: true
			};

		case HIDE_INTERSTITIAL:
			return {
				...state,
				showInterstitial: false
			};

		case ADS_PERMISSIONS:
			return {
				...state,
				vendorAds: typeof action.values.vendorAds !== 'undefined' ? action.values.vendorAds : state.vendorAds,
				noAdsPermissions: typeof action.values.noAdsPermissions !== 'undefined' ? action.values.noAdsPermissions : state.noAdsPermissions,
				secondaryAds: typeof action.values.secondaryAds !== 'undefined' ? action.values.secondaryAds : state.secondaryAds
			};

		case RESET_HIDDEN_ADS:
			return {
				...state,
				resetHiddenAds: Date.now()
			};

		case DOCUMENT_VISIBLE:
			return {
				...state,
				documentVisible: action.visible
			};

		case GDPR_CONSENT:
			let GDPRConsent = { ...state.GDPRConsent };

			// personalized ads
			// let doNotTrack = ( navigator.doNotTrack != 'unspecified' && navigator.doNotTrack != '1');
			GDPRConsent.trackUserOk = typeof action.data.trackUserOk === 'boolean' ? action.data.trackUserOk : GDPRConsent.trackUserOk || false;
			// we need to prevent that old popup gets overwrote when FC sends an UNKNOWN status
			// Note: fix this when we start sending FC status
			if( GDPRConsent.fcStatus < 0 ) {
				action.data.cookiesOk = null;
			}
			// cookies consent, if user allows ads tracking implicitly we have cookies allowed
			GDPRConsent.cookiesOk = GDPRConsent.trackUserOk || (typeof action.data.cookiesOk === 'boolean' ? action.data.cookiesOk : GDPRConsent.cookiesOk);

			// save fcConsent given
			GDPRConsent.fcStatus = GDPRConsent.fcStatus !== -1 ? action.data.fcStatus : GDPRConsent.fcStatus;

			return {
				...state,
				GDPRConsent: GDPRConsent
			};

		case UPDATE_PAGE_NUMBER:
		case '@@router/LOCATION_CHANGE': // We use this to trigger location change events
			return {
				...state,
				analytics: Date.now()
			};

		default :
			return state;
	}
}
