import React from 'react';
import { connect } from 'react-redux';

import announcementsViewSelector from 'Selectors/announcementsViewSelector';
import { openAnnouncements, closeAnnouncements } from 'Actions/UiActions';

import AnnouncementsList from 'Components/threads/AnnouncementThreadListComponent';
import StickiesList from 'Components/threads/StickyThreadListComponent';
import SvgIcon from 'Components/svg/SvgIcon';

class AnnouncementsViewComponent extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			otherContent: true,
			sticky: false,
			announcement: false
		};

		this.annContainer = {};
	}

	static getDerivedStateFromProps( nextProps ) {
		// We need to check if we only have stickies in the page
		if(
			(nextProps.forumview.entities.forums === undefined || Object.keys( nextProps.forumview.entities.forums ).length <= 1) &&
			(nextProps.forumview.entities.topics === undefined || Object.keys( nextProps.forumview.entities.topics ).length === 0)
		) {
			return { otherContent: false };
		}
		return null;
	}

	componentDidMount() {
		this.props.dispatch( closeAnnouncements() );

		// Hide announcements notes
		setTimeout( () => document.querySelectorAll( '.showNote' ).forEach( a => a ? a.classList.remove( 'showNote' ) : null ), 50 );
	}

	componentWillUnmount() {
		this.props.dispatch( closeAnnouncements() );
	}

	shouldComponentUpdate(nextProps) {
		return (
			nextProps.forumview.loaded !== this.props.forumview.loaded
			|| nextProps.forumview.isFetching !== this.props.forumview.isFetching
			|| nextProps.forumview.forumId !== this.props.forumview.forumId
			|| nextProps.announcementsOpen !== this.props.announcementsOpen
		);
	}

	componentDidUpdate() {
		let open = document.querySelectorAll( ".announcements.open" );

		if( open.length && open[ 0 ] ) {
			requestAnimationFrame( () => open[0].scrollTop = 0 );
		}

		// Hide announcements notes
		requestAnimationFrame( () => requestAnimationFrame( () => document.querySelectorAll( '.showNote' ).forEach( a => a ? a.classList.remove( 'showNote' ) : null ) ) );
	}

	announcementsView = ( e ) => {
		if( this.props.announcementsOpen && this.state[ e.currentTarget.dataset.type ] ) {
			this.setState( { [ e.currentTarget.dataset.type ]: false } );
			this.props.dispatch( closeAnnouncements() );

		} else if( this.annContainer[ e.currentTarget.dataset.type ] ) {
			this.setState( { [ e.currentTarget.dataset.type ]: true } );
			this.props.dispatch( openAnnouncements() );
		}
	};

	buildPage( type ) {
		const { result } = this.props.forumview;

		let elementsLength = 0,
			elementsList = null,
			iconName = 'error_outline',
			hasNew = false,
			showNote = null;

		switch( type ) {
			case 'sticky':
				iconName = 'pin';
				elementsLength = result.sticky.length;
				elementsList = <StickiesList key="stickiesList" />;
				hasNew = elementsLength && result.users[ 0 ] !== 0 ? Object.keys( this.props.forumview.entities.sticky ).some( ( s ) => this.props.forumview.entities.sticky[ s ].unread ) : false;
				showNote = hasNew ? <span className='note'>Unread Stickies!</span> : null;
				break;

			case 'announcement':
				iconName = 'announcement';
				elementsLength = result.announcements.length;
				elementsList = <AnnouncementsList key="announcementsList" />;
				hasNew = elementsLength && result.users[ 0 ] !== 0 ? Object.keys( this.props.forumview.entities.announcements ).some( ( a ) => this.props.forumview.entities.announcements[ a ].unread ) : false;
				showNote = hasNew ? <span className='note'>Unread Announcements!</span> : null;
				break;
		}

		let areAnnouncementsOpen = this.state[ type ] && elementsLength > 0 && this.props.announcementsOpen ? ' open' : '',
			announcementsIcon = this.state.otherContent && elementsLength > 0 ?
				<div
					key={ `icon_${type}` }
					className={ `announcements-icon ${ type }${ this.state[ type ] ? ' open' : '' }${ hasNew ? ' showNote' : '' }` }
					data-type={ type }
					onClick={ this.announcementsView }
				>
					<SvgIcon icon={ this.props.announcementsOpen ? 'clear' : iconName } size="16px" />
					{ showNote }
				</div> :
				null;

		// if we have no topics and no sub-forums show stickies and announcements as regular threads
		let page = [];
		// If we need the icon
		if( announcementsIcon ) {
			page.push( announcementsIcon );
		}
		// If we have elements
		if( elementsLength > 0 ) {
			let section = <section key={ type } className={`thread pivotal announcements${ areAnnouncementsOpen }`} ref={( h ) => this.annContainer[ type ] = h}>
					{elementsList}
				</section>;
			page.push( section );
		}

		return page;
	}

	buildElements() {
		let announcements = [];

		// get announcements if we have any
		announcements.push( this.buildPage( 'announcement' ) );

		// if we have stickies include them
		announcements.push( this.buildPage( 'sticky' ) );

		return announcements;
	}

	render() {
		const { loaded } = this.props.forumview;
		let anyOpen = this.props.announcementsOpen ? ' open' : '',
			noOtherContent = this.state.otherContent ? '' : ' alone';

		if( !loaded ) {
			return null;
		}

		return <section className={ `announcements-container${ anyOpen }${ noOtherContent }` }>{ this.buildElements() }</section>;
	}
}

export default connect( announcementsViewSelector )( AnnouncementsViewComponent );