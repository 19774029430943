import { createSelector } from 'reselect';

const metaSelector = state => state.meta;
const userSelector = state => state.ustate;

export default createSelector(
	[metaSelector, userSelector],
	(meta, ustate) => {
		return {
			path: meta.path,
			userId: ustate.id
		};
	}
);