import React from 'react';
import rawToReact from 'Utils/bbcode/RawToReact';

let ForumLinkComponent = (props) => {
	let openLink = () => window.open( props.forum.link, '', 'noopener' );

	return (
		<li>
			<div className="forum"  onClick={ openLink } >
				<div className="title">
					{ rawToReact( props.forum.title ) }
				</div>
				<div className="link">
					{ props.forum.link }
				</div>
			</div>
		</li>
	)
};

export default ForumLinkComponent;