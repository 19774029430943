import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isEqual from 'lodash/isEqual';

import SvgIcon from 'Components/svg/SvgIcon';
import { dismissNotice, noticeShown } from "Actions/NoticesActions";

import decode from 'Utils/encoding/decode';
import rawToReact from 'Utils/bbcode/RawToReact';

class NoticeComponent extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			hidden: false
		};
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			!isEqual( nextProps, this.props ) ||
			!isEqual( nextState, this.state )
		);
	}

	saveDismissedNotice = ( e ) => {
		e.stopPropagation();
		this.props.dispatch( dismissNotice( this.props.notice.id ) );
		this.handleClose( e );
	};

	showDismiss = () => {
		let { notice, isLoggedIn } = this.props;
		return ( notice && notice.dismissible && isLoggedIn );
	};

	handleClose = ( e ) => {
		e.stopPropagation();
		this.setState( { hidden: true } );
		this.props.dispatch( noticeShown( [ this.props.notice.id ] ) );
	};

	expandMessage = ( event ) => {
		if( event.currentTarget ) {
			let collapsed = event.currentTarget.classList.contains( 'collapsed' );

			if( !collapsed ) {
				requestAnimationFrame( () => document.getElementById( 'appContainer' ).scrollTop = 0 );
			}

			event.currentTarget.classList.toggle( 'collapsed' );
		}
	};

	render() {
		if( this.state.hidden ) { return null; }

		let closeX = this.showDismiss() ?
			<div className="action-buttons notice-buttons">
				<button className='action-button' onClick={ this.saveDismissedNotice } >
					<SvgIcon size="1rem" icon="delete_forever"/> Dismiss notice
				</button>
			</div> :
			<div className="notice-buttons" onClick={ this.handleClose } >
				<SvgIcon size="1.5rem" icon="cancel"/>
			</div>;

		let content_showed = decode( rawToReact( this.props.notice.content.replace( /\n{2,}/m, "\n" ).replace( /^\n+/, '' ) ) );

		return (
			<li key={ `notice_${ this.props.notice.id }`} className="notice collapsed" onClick={ this.expandMessage } >
				<div className="nolinks noskim thread">
					<div className="right">
						<div className="thread-info">
							{ closeX }
						</div>
						<div className="notice-content" >
							{ content_showed }
						</div>
					</div>
				</div>
			</li>
		)
	}
}

NoticeComponent.propTypes = {
	notice: PropTypes.object.isRequired,
	isLoggedIn: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.number
	]).isRequired
};

export default connect()( NoticeComponent );