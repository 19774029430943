import React from 'react';

const TopifyAlertsTemplate = ( props ) => {
	let closeX = <span className='s-alert-close' onClick={ props.handleClose } />;

	return (
		<div className={ props.classNames } id={ props.id } style={ props.styles }>
			<div className="s-alert-box-inner" onClick={ props.handleClose }>
				{ closeX }
				{ props.message }
			</div>
		</div>
	);
};

export default TopifyAlertsTemplate;