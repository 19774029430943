import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import isEqual from 'lodash/isEqual';

import threadViewSelector from 'Selectors/threadViewSelector';

import AdComponent from 'Components/ads/AdComponent';
import InThreadAdComponent from 'Components/ads/inThreadAdComponent';
import Post from 'Components/posts/PostComponent';
import LoaderCss from 'Components/common/LoaderCssComponent';

import { resetThread } from 'Actions/ThreadviewActions';
import { resetSelectedItem } from 'Actions/MetaActions';
import { resetGallery } from 'Actions/PhotoGalleryActions';
import { getPageSize } from 'Actions/UserSettingsActions';

import { pathToPost } from 'Utils/nav/NavHelpers';

class PostListComponent extends React.Component {
	componentDidMount() {
		this.props.dispatch( resetSelectedItem() );
		window.addEventListener( 'popstate', this._resetThread );
		window.addEventListener( 'popstate', this._resetGallery );
	}

	componentWillUnmount(){
		window.removeEventListener( 'popstate', this._resetThread );
		window.removeEventListener( 'popstate', this._resetGallery );
		this.props.dispatch( resetSelectedItem() );
	}

	shouldComponentUpdate( nextProps ) {
		return (
			!isEqual( nextProps.meta.selectedElements, this.props.meta.selectedElements ) ||
			!isEqual( nextProps.threadview.entities, this.props.threadview.entities ) ||
			nextProps.threadview.cacheDate !== this.props.threadview.cacheDate ||
			nextProps.page !== this.props.page
		);
	}

	_resetThread = () => {
		if( !/topics|search/.test( this.props.match.url ) ) {
			this.props.dispatch( resetSelectedItem() );
			this.props.dispatch( resetThread() );
		}
	};

	_resetGallery = () => {
		if( !/topics|search/.test( this.props.match.url ) ) {
			this.props.dispatch( resetGallery() );
		}
	};

	gotoPost = ( event ) => {
		let id = event.currentTarget.dataset.postid || null;

		if( isFinite( parseInt( id ) ) ) {
			this.props.history.push( pathToPost( id ) );
		}
	};

	getThreadTitle( topicId ) {
		if( topicId && this.props.isUserProfile ) {
			let topic = this.props.threadview.entities.topics[ parseInt( topicId) ];
			return topic.title;
		}
		return '';
	}

	getFSAdUnit( post, pageNum, isTop ) {
		let searchProp = {
			isTop: isTop,
			page: pageNum,
			showposts: true,
			searchQuery: this.props.threadview.query.searchQuery
		};

		return <AdComponent key={ `In_Search_Ad_${ isTop ? 'Top' : post.id }` } adType={ 0 } adName={ `In_Search_Ad_${ isTop ? 'Top' : post.id }` } search={ searchProp } tag="li" />;
	}

	buildPost = ( post, i, pageNum, pageSize ) => {
		const {entities: {posts, users}, page, total, totalPages } = this.props.threadview,
			{ ustate } = this.props;

		let isSearch = this.props.isSearch,
			isUserProfile = this.props.isUserProfile,
			postNum = post.num || 1 + i + ( (pageNum - 1) * pageSize );

		let renderedPost = [],
			adUnit = null;

		switch( true ) {
			case isUserProfile:
				// no Ads on user profile
				break;

			case isSearch:
				if( pageNum === 1 && i === 0 ) {
					// we want the top search ad at the top, before the thread
					renderedPost.push( this.getFSAdUnit( {}, pageNum, true ) );

				} else if( ( i + 1 ) % 10 === 0 ) {
					adUnit = this.getFSAdUnit( post, pageNum, false, );
				}
				break;

			default:
				let k = i + 1,
					isFirst = ( i === 0 && pageNum === 1 ) , // Let's assume we want the first ad on the first page
					isLast = page === totalPages && Object.keys( posts ).length === k, // we are in the last page and last post
					adType = isFirst ? 5 : 1,
					fallbackAdType = adType === 5 ? [ 1 ] : [];

				switch( true ) {
					case isLast && total < 4:
						// Vendor ad at the bottom if total posts < 4. No fallback or anything else but the Vendor ad.
						adUnit = <AdComponent key={ `In_Content_Ad_3_${ post.id }` } adType={ 208 } last={ true } adName={ 'In_Content_Ad_3_' + post.id } tag="li" />;
						break;

					case pageNum === 1 && k === 3:
						// On first page after the 3th post - Taboola Widget ad
						adUnit = <InThreadAdComponent key={ `taboola_inthread_${ post.id }` } adContainerId="taboola-mid-article-stream-thumbnails" className="" tag="li" />;
						break;

					case pageNum === 1 && k === 4:
						// after 4th post show VendorAd ( 208 ) if we have it,
						// otherwise fallback to 320x186 - yieldmo Hyperscroll/Outstream ( 205 )
						// or fallback to regular 300x250
						adUnit = <AdComponent key={`In_Content_Ad_2_${ post.id }`} adType={ 208 } fallbackAdType={ [ 205, 1 ] } adName={'In_Content_Ad_2_' + post.id} tag="li" inPosts={ true } />;
						break;

					case !isLast && (k % 8) === 0:
						// Every 8 posts unless is the last thing in the page - Sharethrough ( 105 )
						adUnit = <AdComponent key={ `In_Content_Ad_1_${ post.id }` } adType={ 105 } fallbackAdType={ [ 1 ] } adName={ 'In_Content_Ad_1_' + post.id } tag="li" inPosts={ true } />;
						break;

					case !isLast && ( isFirst || (k % 4) === 0 ):
						// Every 4 posts unless we have already one and the top position on first page but never last position in last page - Yieldmo or Top
						adUnit = <AdComponent key={ `In_Content_Ad_${ post.id }` } adType={ adType } fallbackAdType={ fallbackAdType } adName={ 'In_Content_Ad_' + post.id } tag="li" inPosts={ true } />;
						break;

					default:
						adUnit = null;
				}
		}

		let thePost = <Post
					key={ `post_${post.id}` }
					isSearch={ isSearch }
					isUserProfile={ isUserProfile }
					postId={ post.id }
					postNum={ postNum }
					edited={ post.edited && post.edited.when ? post.edited.when : 0 }
					post={ post }
					users={ users }
					perms={ ustate.perms }
					allowHtml={ !!( this.props.threadview.allowhtml ) }
					threadTitle={ this.getThreadTitle( post.topic ) }
					onClick={ this.gotoPost }
				/>;

		renderedPost.push( thePost );

		if( adUnit ) {
			renderedPost.push( adUnit );
		}

		return renderedPost;
	};

	buildPage = ( pageNum, pageSize ) => {
		const { entities, result } = this.props.threadview;

		let post = result.posts.filter( e => entities.posts[ e ] && entities.posts[ e ].page === pageNum );
		return post.map( ( id, i ) => this.buildPost( entities.posts[ id ], i, pageNum, pageSize ) );
	};

	render() {
		if( !this.props.threadview.loaded ) {
			return <LoaderCss key="loader" />;
		}

		const pageSize = this.props.dispatch( getPageSize() );
		let postsListPage = this.buildPage( this.props.page, pageSize );

		return (
			<ul key={ this.props.page } ref={ this.props.listRef } className={`scroll_page post_page page_${this.props.page}`}>
				{ postsListPage }
			</ul>
		);
	}
}

PostListComponent.propTypes = {
	threadview: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
};

PostListComponent.defaultProps = {
	isSearch: false,
	isUserProfile: false
};

export default withRouter( connect( threadViewSelector )( PostListComponent ) );
