import fixUrlProtocol from 'Utils/fixUrlProtocol';

import { CLEAR_FIRST_LOAD } from 'Actions/GlobalMetaActions';

let forumsite = TopifyPresets.forumData.forumSite[ 0 ],
	newForumsite = {};

// Fix protocols in URLs
newForumsite.f_url = fixUrlProtocol( forumsite.f_url );
newForumsite.url = fixUrlProtocol( forumsite.url );
// parse some data
newForumsite.dfpTargets = JSON.parse( forumsite.dfpTargets ) || {};
newForumsite.dfpTags = forumsite.dfpTags ? JSON.parse( forumsite.dfpTags ) : [];
newForumsite.secondaryAdForumIDs = forumsite.secondaryAdForumIDs ? JSON.parse( forumsite.secondaryAdForumIDs ) : [];
newForumsite.noAdGroupIDs = forumsite.noAdGroupIDs ? JSON.parse( forumsite.noAdGroupIDs ) : [];
newForumsite.vendorAdsGroupIDs = forumsite.vendorAdsGroupIDs ? JSON.parse( forumsite.vendorAdsGroupIDs ) : [ -1 ];
newForumsite.adProviders = forumsite.adProviders || {};

const initialState = {
	firstLoad: true,
	forumSite: Object.assign( {}, TopifyPresets.forumData.forumSite[ 0 ], newForumsite ),
	forumInfo: TopifyPresets.forumData.forumInfo[ 0 ],
	initialRoute: TopifyPresets.embedRoute,
	initialId: TopifyPresets.embedId,
	secondaryId: TopifyPresets.secondaryId,
	initialPage: TopifyPresets.embedPage,
	message: TopifyPresets.message || {},
	userInfo: TopifyPresets.userInfo,
	GDPR: !!(TopifyPresets.isGDPR),
	ffUrl: fixUrlProtocol( TopifyPresets.ffUrl ),
	hotDeals: TopifyPresets.hotDeals || { deals: [] }
};

export default function topifyPresets( state = initialState, action ) {
	switch( action.type ) {
		case CLEAR_FIRST_LOAD:
			return {
				...state,
				firstLoad: false,
				initialRoute: null,
				initialId: null,
				secondaryId: null,
				initialPage: null,
				message: null,
				userInfo: null
			};

		default :
			return state;
	}
}

