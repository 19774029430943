//canvas.toBlob polyfill for safari
import 'Utils/polyfills/canvas-to-blob';

//intersectionObserver polyfill
import 'Utils/polyfills/intersectionObserver';

import 'babel-polyfill';

// fetch
import 'isomorphic-fetch';

// RunTime public path from TopifyPresets
import 'Utils/public-path';

import 'normalize.css';
import 'Styles/app.scss';
import 'Styles/page.scss';
import 'Styles/widgets.scss';
import 'Styles/old_phones.scss';
import 'Styles/header.scss';
import 'Styles/sidebar.scss';
import 'Styles/breadcrumb.scss';
import 'Styles/infinitescroller.scss';
import 'Styles/pageselector.scss';
import 'styles/overflow_menu.scss';
import 'Styles/searchbar.scss';
import 'Styles/registerBar.scss';
import 'Styles/usericon.scss';
import 'Styles/animate.scss';
import 'Styles/forumview.scss';
import 'Styles/post.scss';
import 'Styles/polls.scss';
import 'Styles/modals.scss';
import 'Styles/searchview.scss';
import 'Styles/messages.scss';
import 'Styles/userprofile.scss';
import 'Styles/settings.scss';
import 'Styles/releasenotes.scss';
import 'Styles/register.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import Root from 'Containers/Root';

import TopifyConsoleUtil from 'Utils/topifyConsole';
import { deviceType } from 'Utils/deviceType';

if( window.performance && window.performance.mark ) {
	window.performance.mark( 'init' );

	window.addEventListener( "load", () => window.performance.mark( 'mark_fully_loaded' ), { capture: true, once: true, passive: true } );
}

const topifyConsoleLabel = [ "%cTopify", "display: inline-block; color: #fff; background: #3870a6; padding: 1px 4px; border-radius: 3px;" ];

window.TOPIFY_VERSION = TOPIFY_VERSION;

console.log( ...topifyConsoleLabel, 'Topify version', TOPIFY_VERSION );

/* Prepare topifyConsole */
/**
 * Adding to the querystring: 'topify_debug=<log_level>'
 * We'll have more or less levels on console when "topifyConsole" in used,
 * this setting doesn't interfere in anyway with other loggers in console
 *
 * We have 5 levels going from 0 to 4:
 *   0 - no logs   * => default on production
 *   1 - only errors
 *   2 - warning, error and timing
 *   3 - info, warning, error and timings
 *   4 - all logs  * => default on development
 *
 */
let _debugMode = () => {
	let locRe = /[?&]topify_debug=([^&]+)/i,
		location = locRe.exec( window.location.search ) || locRe.exec( window.location.hash ),
		query_location = location && location.length > 0 ? { topify_debug: parseInt( location[ 1 ] ) } : false;

	return (
		Number.isInteger( parseInt( query_location[ 'topify_debug' ] ) ) ?
			parseInt( query_location[ 'topify_debug' ] ) :
			false
	);
};

let _debug = () => {
	let debugMode = _debugMode(),
		validMode = Number.isInteger( debugMode ) === true && debugMode <= 4 && debugMode >= 0,
		defaultMode = 'production' === process.env.NODE_ENV ? 0 : 4;

	return validMode ? debugMode : defaultMode;
};

window.topifyConsole = new TopifyConsoleUtil( _debug(), topifyConsoleLabel );
/* topifyConsole done */

// Device Type detection
deviceType();

// Render the main component into the dom
ReactDOM.render(
	<Root />,
	document.getElementById( 'appContainer' )
);
