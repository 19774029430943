import { FORUM_SUCCESS } from 'Actions/ForumviewActions';
import { MESSAGES_SUCCESS } from 'Actions/MessagesviewActions';
import { FETCH_ONLINE_USERS_SUCCESS } from 'Actions/OnlineUsersActions';
import { PORTALPAGE_SUCCESS } from 'Actions/PortalPageActions';
import { SEARCH_SUCCESS } from 'Actions/SearchResultActions';
import { THREAD_SUCCESS } from 'Actions/ThreadviewActions';
import { USERPROFILE_SUCCESS } from 'Actions/UserprofileActions';
//import { WIDGET_SUCCESS } from 'Actions/WidgetActions';

import { SHOWN_NOTICE } from 'Actions/NoticesActions';

const initialState = {
	entities: {},
	result: [],
	shown: {}
};

export default function notices( prevState = initialState, action ) {
	switch( action.type ) {
		case FORUM_SUCCESS:
		case MESSAGES_SUCCESS:
		case FETCH_ONLINE_USERS_SUCCESS:
		case PORTALPAGE_SUCCESS:
		case SEARCH_SUCCESS:
		case THREAD_SUCCESS:
		case USERPROFILE_SUCCESS:
//		case WIDGET_SUCCESS:
			if( action.data.entities === undefined || action.data.result === undefined ) {
				return prevState;
			}

			let notices = {
				entities: action.data.entities.notices || {},
				result: action.data.result.notices || []
			};
			return Object.assign( {}, prevState, notices );

		case SHOWN_NOTICE:
			let newShownState = Object.assign( {}, prevState.shown );
			if( action.notices.length ) {
				action.notices.map( ( nid ) => {
					newShownState[ nid ] = true;
				} );
			}
			return Object.assign( {}, prevState, { shown: newShownState } );

		default:
			return prevState;
	}
}