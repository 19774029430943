import {
	FORUM_FETCH,
	FORUM_SUCCESS,
	FORUM_FAILURE,
	FORUM_RESET,
	FORUM_FOLLOWING,
	THREAD_FOLLOWING,
	SET_FORUM,
	FORUM_REQUEST_EPIC,
} from '../actions/ForumviewActions';

import { ANNOUNCEMENT_READ } from '../actions/ThreadviewActions';

import { LOADING_BEHAVIOR } from '../actions/InfiniteScrollActions';
import { isCachedChangedFor } from '../actions/CacheActions';

import { threadFollowing, forumFollowing } from "./followingReducerHelper";

import merge from 'lodash/merge';
import union from 'lodash/union';
import uniq from 'lodash/uniq';

const initialState = {
	isFetching: false,
	isBgFetching: false,
	cacheDate: null,
	cacheChanged: false,
	loaded: false,
	lastError: '',
	entities: {},
	pages: [],
	result: {
		forums: [],
		topics: [],
		users: [],
		sticky: [],
		announcements: []
	},
	forumId: null,
	page: null,
	totalPages: 0,
};

export default function forumview( prevState = initialState, action ) {
	switch( action.type ) {
		case FORUM_FETCH:
			return Object.assign( {},
				prevState,
				{
					forumId: action.forum.id,
					isFetching: true,
					// Only set loaded to false if we are doing a full refresh
					loaded: !(action.forum.loadingBehavior === LOADING_BEHAVIOR.REFRESH)
				} );

		case FORUM_SUCCESS:
			if( prevState.forumId !== action.forum.id || action.forum.loadingBehavior === LOADING_BEHAVIOR.REFRESH ) {
				return merge(
					{},
					action.data,
					{
						isFetching: false,
						isBgFetching: false,
						lastError: '',
						cacheDate: null,
						cacheChanged: isCachedChangedFor( {
							prevResult: prevState.result.topics,
							prevPage: prevState.page,
							currResult: action.data.result.topics,
							currPage: action.data.page
						} ),
						loaded: true,
						forumId: action.forum.id,
						pages: [ action.data.page ],
					}
				)
			} else {
				let topicsResult, usersResult, pages;
				if( action.forum.loadingBehavior === LOADING_BEHAVIOR.PREPEND ) {
					topicsResult = uniq( [ ...action.data.result.topics, ...prevState.result.topics ] );
					usersResult = union( action.data.result.users, prevState.result.users );
					pages = uniq( [ action.data.page, ...prevState.pages ] )
				} else if( action.forum.loadingBehavior === LOADING_BEHAVIOR.APPEND ) {
					topicsResult = uniq( [ ...prevState.result.topics, ...action.data.result.topics ] );
					usersResult = union( prevState.result.users, action.data.result.users );
					pages = uniq( [ ...prevState.pages, action.data.page ] )
				}

				return merge(
					{},
					prevState,
					action.data,
					{
						isFetching: false,
						isBgFetching: false,
						lastError: '',
						cacheDate: null,
						cacheChanged: isCachedChangedFor( {
							prevResult: prevState.result.topics,
							prevPage: prevState.page,
							currResult: topicsResult,
							currPage: action.data.page
						} ),
						loaded: true,
					},
					{
						pages: pages,
						result: {
							topics: topicsResult,
							users: usersResult
						}
					}
				);
			}

		case FORUM_FAILURE:
			return Object.assign( {}, prevState, {
				lastError: action.error,
				isFetching: false
			} );

		case FORUM_RESET:
			return initialState;

		case FORUM_FOLLOWING: // used only in src
			return forumFollowing( prevState, action );

		case THREAD_FOLLOWING:
			return threadFollowing( prevState, action );

		case SET_FORUM:
			return {
				...action.state,
				cacheDate: action.date,
				cacheChanged: false,
			};

		case FORUM_REQUEST_EPIC:
			return {
				...prevState,
				isBgFetching: true
			};

		case ANNOUNCEMENT_READ:
			if( prevState.entities.announcements ) {
				let prevEntitiesAnnouncements = prevState.entities.announcements,
					announcement = prevState.entities.announcements[ action.announcement ];

				announcement.unread = false;
				let entitiesAnnouncements = {
					announcements: Object.assign( {}, prevEntitiesAnnouncements, { [ action.announcement ]: announcement } )
				};
				return Object.assign( {}, prevState, entitiesAnnouncements );
			}
			return prevState;

		default :
			return prevState;
	}
}

