import {
	FETCH_SUCCESS,
	UPDATE_PAGES_RESULT,
	UPDATE_PAGE_NUMBER,
	LOADING_BEHAVIOR,
	RESET_INFINITE,
	RETRIEVABLE_CONTENT
} from '../actions/InfiniteScrollActions';

import uniq from 'lodash/uniq';

const state = {
	id: null,
	loadType: null,
	path: null,
	page: 1,
	totalPages: 1,
	earliestLoadedPage: 1,
	latestLoadedPage: 1,
	pages: [],
	scrollDirection: null,
	retrievableContent: []
};

const initialState = {
	...state,
	prevState: state
};

export default function infiniteScroll( prevState = initialState, action ) {

	let scrollDirection = null;

	if( parseInt( prevState.page ) > parseInt( action.page ) ) {
		scrollDirection = "up"
	} else if( parseInt( prevState.page ) < parseInt( action.page ) ) {
		scrollDirection = "down"
	}

	switch( action.type ) {
		case FETCH_SUCCESS:
			if( action.loadingBehavior === LOADING_BEHAVIOR.REFRESH ) {
				return {
					...prevState,
					id: action.id,
					loadType: action.loadType,
					page: action.page,
					earliestLoadedPage: action.page,
					latestLoadedPage: action.page,
					pages: [ action.page ],
					totalPages: action.totalPages,
					scrollDirection: null,
					retrievableContent: []
				}
			} else {
				let earliestLoadedPage = prevState.earliestLoadedPage;
				let latestLoadedPage = prevState.latestLoadedPage;

				if( action.loadingBehavior === LOADING_BEHAVIOR.PREPEND && action.page < earliestLoadedPage ) {
					earliestLoadedPage -= 1;
				} else if(
					action.loadingBehavior === LOADING_BEHAVIOR.APPEND
					&& action.page > latestLoadedPage
					&& action.page <= prevState.totalPages
				) {
					latestLoadedPage += 1;
				}

				return {
					...prevState,
					id: action.id,
					loadType: action.loadType,
					page: prevState.page, // Don't update since this is infinite scrolling and we may not have actually scrolled all the way to that page yet
					pages: uniq( [ ...prevState.pages, action.page ] ).sort( ( a, b ) => a - b ),
					retrievableContent: [],
					earliestLoadedPage,
					latestLoadedPage,
					scrollDirection
				}
			}

		case UPDATE_PAGE_NUMBER:
			return {
				...prevState,
				page: action.page,
				scrollDirection
			};

		case UPDATE_PAGES_RESULT:
			let pages = uniq( [ ...prevState.pages, action.page ] ).sort( ( a, b ) => a - b );

			return {
				...prevState,
				pages,
				scrollDirection
			};

		case RETRIEVABLE_CONTENT:
			let retrievableContent = Number.isFinite( action.page ) ? uniq( [ ...prevState.retrievableContent, action.page ] ).sort( ( a, b ) => a - b ) : [];
			return {
				...prevState,
				retrievableContent: retrievableContent
			};

		case RESET_INFINITE:
			return initialState;

		default:
			return prevState;
	}
}

