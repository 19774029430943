import React from 'react';
import { connect } from 'react-redux';

import Loadable from 'react-loadable';

// lazy loaded chunks
const SearchView = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/search/SearchViewComponentGuests' ),
	loading: () => null
} );
const SearchViewLogged = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/search/SearchViewComponentLogged' ),
	loading: () => null
} );

class SearchViewComponentLoader extends React.PureComponent {
	render() {
		return this.props.isLoggedIn ? <SearchViewLogged { ...this.props } /> : <SearchView { ...this.props } />;
	}
}

const mapStateToProps = ( state ) => {
	return {
		isLoggedIn: state.ustate.id
	}
};

export default connect( mapStateToProps )( SearchViewComponentLoader );
