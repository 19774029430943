import {
	USERPROFILE_FETCH,
	USERPROFILE_SUCCESS,
	USERPROFILE_FAILURE,
	RESET_USERPROFILE
} from '../actions/UserprofileActions';

import { LOADING_BEHAVIOR } from '../actions/InfiniteScrollActions';

import merge from 'lodash/merge';
import union from 'lodash/union';

const initialState = {
	isFetching: false,
	firstLoad: true,
	loaded: false,
	lastError: '',
	entities: {},
	pages: [],
	user: {
		id: null,
		avatar: ''
	},
	result: {
		posts: [],
		topics: [],
	},
	page: 1,
	totalPages: 0
};

export default function userprofileview( prevState = initialState, action ) {
	switch( action.type ) {
		case USERPROFILE_FETCH:
			return Object.assign( {},
				prevState,
				{
					isFetching: true,
					// Only set loaded to false if we are doing a full refresh
					loaded: !(action.user.loadingBehavior === LOADING_BEHAVIOR.REFRESH)
				} );

		case USERPROFILE_SUCCESS:
			let user = action.user.id ? action.data.entities.users[ parseInt( action.user.id ) ] : prevState.user;

			if(
				parseInt( prevState.user.id ) !== parseInt( action.user.id )
				|| action.user.loadingBehavior === LOADING_BEHAVIOR.REFRESH
			) {
				return merge(
					{},
					action.data,
					{ user: user },
					{
						lastError: '',
						isFetching: false,
						loaded: true,
						firstLoad: true,
						pages: [ parseInt( action.data.page ) ]
					}
				);

			} else {
				let postsResult, topicsResult, usersResult, pages;

				if( action.user.loadingBehavior === LOADING_BEHAVIOR.PREPEND ) {
					postsResult = union( action.data.result.posts, prevState.result.posts );
					topicsResult = union( action.data.result.topics, prevState.result.topics );
					usersResult = union( action.data.result.users, prevState.result.users );
					pages = [ parseInt( action.data.page ), ...prevState.pages ];
				} else if( action.user.loadingBehavior === LOADING_BEHAVIOR.APPEND ) {
					postsResult = union( prevState.result.posts, action.data.result.posts );
					topicsResult = union( prevState.result.topics, action.data.result.topics );
					usersResult = union( prevState.result.users, action.data.result.users );
					pages = [ ...prevState.pages, parseInt( action.data.page ) ];
				}

				return merge(
					{},
					prevState,
					action.data,
					{ user: user },
					{
						lastError: '',
						isFetching: false,
						loaded: true,
						firstLoad: false
					},
					{
						pages: pages,
						result: {
							posts: postsResult,
							topics: topicsResult,
							users: usersResult
						}
					}
				);
			}

		case RESET_USERPROFILE:
			return initialState;

		case USERPROFILE_FAILURE:
			return Object.assign( {}, prevState, {
				lastError: action.error,
				isFetching: false
			} );

		default:
			return prevState;
	}

}

