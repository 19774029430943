import React from 'react';

import Loadable from 'react-loadable';

import LoadingComponent from "Components/header/LoadingComponent";

const loadingMsg = <header className="primary nolinks noskim"><div className="buttons" /><div className="title"><LoadingComponent text="Loading" /></div></header>;

// lazy loaded chunks
const Header = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/header/HeaderComponentGuests' ),
	loading: () => loadingMsg
} );
const HeaderLogged = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/header/HeaderComponentLogged' ),
	loading: () => loadingMsg
} );

const HeaderLoader = ( { isLoggedIn } ) => {
	return isLoggedIn ? <HeaderLogged /> : <Header />;
};

export default HeaderLoader;
