import React from 'react';

import { connect } from 'react-redux';

import isEqual from 'lodash/isEqual';

import SimpleThread from 'Components/threads/SimpleThreadComponent';
import { goToThread } from 'Actions/ForumviewActions';
import threadViewSelector from 'Selectors/threadViewSelector';

class RelatedThreadListComponent extends React.Component {
	shouldComponentUpdate( nextProps ) {
		return !isEqual( nextProps.threadview.result.relatedThreads, this.props.threadview.result.relatedThreads );
	}

	gotoThread = ( event ) => {
		let id = event.currentTarget.dataset.threadid || null;
		this.props.dispatch( goToThread( id ) );
	};

	buildTopic = (key, topic, users) => {
		return (
			<SimpleThread
				key={ `related_${key}` }
				thread={ topic }
				user={ users[topic.forumUser] }
				users={ users }
				ustate={ this.props.ustate }
				onClick={ this.gotoThread }
			/>
		);
	};

	render() {
		const {entities, result} = this.props.threadview;

		if( !result.relatedThreads || result.relatedThreads.length === 0 ) {
			return null
		}

		const relatedThreadsList = result.relatedThreads.map( ( k, i ) => this.buildTopic( i, entities.relatedThreads[ k ], entities.users ) );

		return (
			<div className="related_threads">
				<div className="thread title"><div>Related Threads</div></div>
				<ul>
					{ relatedThreadsList }
				</ul>
			</div>
		);
	}
}

export default connect( threadViewSelector )( RelatedThreadListComponent );