import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'Components/svg/SvgIcon';

class checkboxComponent extends React.Component {
	constructor( props ) {
		super(props);
		this.state = {
			itemChecked: !!props.checked,
			itemState: !!props.checked
		};
	}

	static getDerivedStateFromProps( nextProps, prevState ) {
		return nextProps.checked !== prevState.itemChecked ? { itemChecked: nextProps.checked, itemState: nextProps.checked } : null
	}

	onChangeHandle = ( event ) => {
		this.setState( { itemState: event.target.checked } );

		if( this.props.onChange ) {
			this.props.onChange( event );
		}
	};

	avoidPropagation = ( e ) => {
		e.stopPropagation();
	};

	render() {
		let { name, id, value, multiple, label, postLabel, className } = this.props,
			type = multiple ? 'checkbox' : 'radio',
			text = label ? <span>{ label }</span> : null,
			postText = postLabel ? <span>{ postLabel }</span> : null,
			icon = ( multiple ? 'checkbox' : 'radio' ) + ( this.state.itemState ? '_checked' : '' ),
			label_class = ( this.state.itemState ? 'checked ' : '' ) + className;

		return (
			<label className={ `custom-checkbox ${label_class}` } onClick={ this.avoidPropagation } >
				{ text }
				<input
					name={ name }
					id={ id }
					value={ value }
					checked={ this.state.itemState  }
					onChange={ this.onChangeHandle }
					type={ type }
					className="custom-checkbox"/>
				<div className="icon">
					<SvgIcon icon={ icon } size="24px" />
				</div>
				{ postText }
			</label>
		);
	}
}

checkboxComponent.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	multiple: PropTypes.bool.isRequired,
	checked: PropTypes.bool.isRequired,
	label: PropTypes.oneOfType([
    	PropTypes.string,
    	PropTypes.array
	]),
	postLabel: PropTypes.oneOfType([
    	PropTypes.string,
    	PropTypes.array
	]),
	className: PropTypes.string,
	onClick: PropTypes.func,
	onChange: PropTypes.func
};

export default checkboxComponent;