import React from 'react';

import UserIcon from 'Components/common/UserIconComponent';
import DateComponent from 'Components/common/dateComponent';
import SvgIcon from 'Components/svg/SvgIcon';

import rawToReact from "Utils/bbcode/RawToReact";
import decode from "Utils/encoding/decode";
import { pathToThread } from "Utils/nav/NavHelpers";

const SimpleThreadComponent = ( props ) => {

	const _openThreadNewTab = ( event ) => {
		event.preventDefault();
		event.stopPropagation();
		let path = pathToThread( props.thread.id );
		window.open( props.thread.href + `#${ path }`, `${ path }_${ Date.now() }` );
	};

	let { id, title, postsCount, lastDateline, dateline, unread, prefix_title_rich } = props.thread;

	const prefix = prefix_title_rich ? <span className="prefix">{ rawToReact( prefix_title_rich ) }</span> : null,
		openThread = <a className="open-new-tab" onClick={ _openThreadNewTab }><SvgIcon size="1em" icon="open_new"/></a>,
		forumtitle = decode( props.thread.forumtitle ),
		user = props.user || { avatar: '', userName: 'Guest' },
		replies = postsCount - 1;

	const statsData = postsCount ?
		<div className="stats">
			<div className="stats-counter"><SvgIcon size="20px" icon="reply"/>{ replies.toLocaleString() } { `Repl${ parseInt( replies ) !== 1 ? 'ies' : 'y' }` }</div>
			<DateComponent timestamp={ lastDateline } icon={ true } />
		</div> :
		<div className="stats single-right"><DateComponent timestamp={ dateline } icon={ true } /></div>;

	let classNames = `nolinks noskim thread${ props.ustate.id && unread ? ' indicator' : ''}`;

	return (
		<li onClick={ props.onClick } data-threadid={ id } >
			<div className={ classNames }>
				<div className="left">
					<UserIcon user={ user } skipElements={ { userName: true, status: true, userData: true } } noAction={ true } />
				</div>
				<div className="right">
					<div className="title">
						{ prefix }{ prefix ? ' ' : '' }{ rawToReact( title ) }{ openThread }
					</div>
					<div className="topic-forumTitle">
						{ forumtitle }
					</div>
					{ statsData }
				</div>
			</div>
		</li>
	);
};

export default SimpleThreadComponent;