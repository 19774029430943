import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Loadable from 'react-loadable';

import LoaderCss from 'Components/common/LoaderCssComponent';

// Potential landing pages are not lazy-loaded
const RootView = null;

//import RootView from 'Components/RootViewComponent';
import ForumsIndexView from 'Components/ForumsIndexViewComponent';
import ForumView from 'Components/ForumViewComponent';
import ThreadView from 'Components/ThreadViewComponent';

import SearchView from 'Components/SearchViewComponent';

// Common loadable props
const loadableProps = {
	loading: ( props ) => <LoaderCss lazyLoad={ true } {...props} />,
	timeout: 7000, // 7 secs
};

// Other primary components are Lazy-Loaded
const UserProfile = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/UserprofileViewComponent' ),
	...loadableProps
} );
const OnlineView = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/OnlineViewComponent' ),
	...loadableProps
} );
const ReleaseNotesView = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/ReleaseNotesViewComponent' ),
	...loadableProps
} );
const PortalPageView = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/PortalPageComponent' ),
	...loadableProps
} );
const RegisterView = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/RegisterComponent' ),
	...loadableProps
} );
const SettingsView = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/SettingsComponent' ),
	...loadableProps
} );
const LostPwd = Loadable( {
	loader: () => import( /* webpackChunkName: "otherComponents" */ 'Components/LostPwdComponent' ),
	...loadableProps
} );
const MessagesViewComponent = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/MessagesViewComponent' ),
	...loadableProps
} );
const MessagesFolderView = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/messages/MessagesFolderViewComponent' ),
	...loadableProps
} );
const MessageView = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/messages/MessageViewComponent' ),
	...loadableProps
} );
const UserCP = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/UserCPComponent' ),
	...loadableProps
} );

let Routes = () => {
	// Check the conf if we have home active
	let ComponentIndex = process.env.config.section.home && RootView ? RootView : ForumsIndexView, // if root exists use it
		indexRoute = <Route exact strict path="/" component={ ComponentIndex } />;

	return (
		<Switch>
			{indexRoute}

			<Route exact strict path="/forums" component={ForumsIndexView}/>
			<Route exact strict path="/forums/:forumId" component={ForumView}/>

			<Route exact strict path="/topics/:threadId" component={ThreadView}/>

			<Route exact strict path="/search/:searchId" component={SearchView}/>
			<Route exact strict path="/following" component={SearchView}/>
			<Route exact strict path="/latest" component={SearchView}/>
			<Route exact strict path="/participated" component={SearchView}/>
			<Route exact strict path="/updated" component={SearchView}/>

			<Route exact strict path="/userprofile/:userid" component={UserProfile}/>

			<Route exact strict path="/release" component={ReleaseNotesView}/>

			<Route exact strict path="/pms" component={MessagesViewComponent}/>
			<Route exact strict path="/pms/:folderId" component={MessagesFolderView}/>
			<Route exact strict path="/pm/:messageId" component={MessageView}/>

			<Route exact strict path="/online" component={OnlineView}/>

			<Route exact strict path="/portal/:pagename" component={PortalPageView}/>

			<Route exact strict path="/register" component={RegisterView}/>
			<Route exact strict path="/settings" component={SettingsView}/>
			<Route exact strict path="/usercp" component={UserCP}/>

			<Route exact strict path="/lostpwd" component={LostPwd}/>

			{/* Remove the trailing slash */}
			<Route exact strict path="/(.*)/" render={({ history: { location: { pathname, search, hash } } }) => (
				pathname.slice(-1) === '/' ?
					<Redirect to={`${pathname.slice(0, -1)}${search}${hash}`} /> : <Redirect to="/" />
			)} />

			{/* prevent incorrect URLs and redirect to home, this must be the last entry here */}
			<Redirect to="/"/>
		</Switch>
	);
};

export default Routes;