import {
	RESET_OVERLAY,
	TOGGLE_SIDEBAR_ON,
	TOGGLE_SIDEBAR_OFF,
	TOGGLE_SEARCHBAR,
	TOGGLE_HEADER_OVERFLOW,
	SHOW_LOGIN_MODAL, HIDE_LOGIN_MODAL,
	SHOW_CONFIRM_MODAL, HIDE_CONFIRM_MODAL,
	SHOW_GLOBAL_LOADING,
	HIDE_GLOBAL_LOADING,
	SHOW_REPLY_MODAL,
	HIDE_REPLY_MODAL,
	SHOW_REPLY_MODAL_LOADER,
	HIDE_REPLY_MODAL_LOADER,
	SHOW_REPLY_MODAL_ATTACHMENT_LOADER,
	HIDE_REPLY_MODAL_ATTACHMENT_LOADER,
	SHOW_USER_MODAL,
	HIDE_USER_MODAL,
	TOGGLE_SHARE_MENU,
	OPEN_ANNOUNCEMENTS,
	CLOSE_ANNOUNCEMENTS,
	TOGGLE_REGISTRATIONBAR
} from 'Actions/UiActions';

import {
	SET_POST_ATTACHMENT,
	SET_POST_HASH,
	REMOVE_POST_ATTACHMENT
} from 'Actions/AttachmentsActions';

import { CHANGE_USER_SETTINGS } from 'Actions/UserSettingsActions';

import { getSavedUserSettings, fixLandingPage } from 'Actions/UserSettingsActions';

let currentSettings = getSavedUserSettings();

const initialState = {
	sidebarOpened: false,
	searchBarOpened: false,
	headerOverflowOpened: false,
	shareMenuOpened: false,
	loginModalOpened: false,
	confirmModalOpened: false,
	confirmModalOptions: {
		header: "",
		text: "",
		action: null,
		onCancel: null
	},
	globalLoading: "",
	replyModal: {
		opened: false,
		loading: false,
		options: {},
		responseHash: {},
		attachments: [],
		attachmentLoading: false,
	},
	userModal: {
		opened: false,
		path: [],
		user: {},
	},
	userSettings: {
		showAttachmentsPreview: currentSettings && currentSettings.showAttachmentsPreview !== null ? currentSettings.showAttachmentsPreview : true,
		styleTheme: currentSettings && currentSettings.styleTheme ? currentSettings.styleTheme : 'light',
		loadingBehavior: currentSettings && currentSettings.loadingBehavior ? currentSettings.loadingBehavior : 'oldest',
		onlineUsersLoadingBehavior: currentSettings && currentSettings.onlineUsersLoadingBehavior ? currentSettings.onlineUsersLoadingBehavior : 'az',
		fontSize: currentSettings && currentSettings.fontSize ? currentSettings.fontSize : 'normal',
		pageSize: currentSettings && currentSettings.pageSize ? currentSettings.pageSize : 10,
		landingPage: fixLandingPage( currentSettings && currentSettings.landingPage ? currentSettings.landingPage : 'index' ),
		timestamp: currentSettings && currentSettings.timestamp ? currentSettings.timestamp : 0
	},
	announcementsOpen: false,
	registrationBarHidden: false,
};

export default function ui( state = initialState, action ) {
	switch( action.type ) {
		case RESET_OVERLAY:
			return {
				...state,
				sidebarOpened: false,
				searchBarOpened: false,
				headerOverflowOpened: false,
				shareMenuOpened: false
			};

		case TOGGLE_SIDEBAR_ON:
			return {
				...state,
				sidebarOpened: true,
				searchBarOpened: false,
				headerOverflowOpened: false,
				shareMenuOpened: false
			};

		case TOGGLE_SIDEBAR_OFF:
			return {
				...state,
				sidebarOpened: false,
				searchBarOpened: false,
				headerOverflowOpened: false,
				shareMenuOpened: false
			};

		case TOGGLE_SEARCHBAR:
			return {
				...state,
				searchBarOpened: !state.searchBarOpened,
				sidebarOpened: false,
				headerOverflowOpened: false,
				shareMenuOpened: false
			};

		case TOGGLE_HEADER_OVERFLOW:
			return {
				...state,
				headerOverflowOpened: !state.headerOverflowOpened,
				sidebarOpened: false,
				searchBarOpened: false,
				shareMenuOpened: false
			};

		case TOGGLE_SHARE_MENU:
			return {
				...state,
				headerOverflowOpened: false,
				sidebarOpened: false,
				searchBarOpened: false,
				shareMenuOpened: !state.shareMenuOpened
			};

		case SHOW_LOGIN_MODAL:
			return {
				...state,
				loginModalOpened: true,
				sidebarOpened: false,
				searchBarOpened: false,
				headerOverflowOpened: false,
				shareMenuOpened: false
			};

		case HIDE_LOGIN_MODAL:
			return {
				...state,
				loginModalOpened: false
			};

		case SHOW_CONFIRM_MODAL:
			return {
				...state,
				confirmModalOpened: true,
				confirmModalOptions: {
					header: action.header,
					text: action.text,
					action: action.action
				}
			};

		case HIDE_CONFIRM_MODAL:
			return {
				...state,
				confirmModalOpened: false,
				confirmModalOptions: {
					header: "",
					text: "",
					action: null,
					onCancel: null
				}
			};

		case SHOW_GLOBAL_LOADING:
			return {
				...state,
				headerOverflowOpened: false,
				globalLoading: (action.text || 'Loading')
			};

		case HIDE_GLOBAL_LOADING:
			return {
				...state,
				globalLoading: ""
			};

		case SHOW_REPLY_MODAL:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					opened: true,
					loading: false,
					options: action.options
				}
			};

		case HIDE_REPLY_MODAL:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					opened: false,
					loading: false,
					options: {},
					responseHash: {},
					attachments: [],
					attachmentLoading: false,
				}
			};

		case SHOW_REPLY_MODAL_LOADER:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					loading: true
				}
			};

		case HIDE_REPLY_MODAL_LOADER:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					loading: false
				}
			};

		case SET_POST_HASH:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					responseHash: action.responseHash
				}
			};

		case SET_POST_ATTACHMENT:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					attachments: [
						...state.replyModal.attachments,
						...action.attachments
					]
				}
			};

		case REMOVE_POST_ATTACHMENT:
			const attachments = state.replyModal.attachments.filter( attachment => attachment.id !== parseInt( action.id ) );
			let responseHash;
			if( attachments.length === 0 ) {
				responseHash = {};
			} else {
				responseHash = state.replyModal.responseHash;
			}
			return {
				...state,
				replyModal: {
					...state.replyModal,
					responseHash,
					attachments
				}
			};

		case SHOW_REPLY_MODAL_ATTACHMENT_LOADER:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					attachmentLoading: true,
				}
			};

		case HIDE_REPLY_MODAL_ATTACHMENT_LOADER:
			return {
				...state,
				replyModal: {
					...state.replyModal,
					attachmentLoading: false,
				}
			};

		case SHOW_USER_MODAL:
			return {
				...state,
				userModal: {
					...state.userModal,
					opened: true,
					path: action.path,
					user: action.user
				}
			};

		case HIDE_USER_MODAL:
			return {
				...state,
				userModal: {
					...state.userModal,
					opened: false,
				}
			};

		case CHANGE_USER_SETTINGS:
			return {
				...state,
				userSettings: {
					...state.userSettings,
					...action.userSettings
				}
			};

		case OPEN_ANNOUNCEMENTS:
			return {
				...state,
				announcementsOpen: true
			};

		case CLOSE_ANNOUNCEMENTS:
			return {
				...state,
				announcementsOpen: false
			};

		case TOGGLE_REGISTRATIONBAR:
			return {
				...state,
				registrationBarHidden: !state.registrationBarHidden
			};

		default :
			return state;
	}
}
