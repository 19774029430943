import { createSelector } from 'reselect';

import querySelector from 'Selectors/queryStringSelector';
import metaSelector from 'Selectors/metaSelector';

const threadviewSelector = (state,props) => props.threadview || state.threadview;
const userStateSelector = state => state.ustate;
const infiniteScrollSelector = state => state.infiniteScroll;
const topifyPresetsSelector = state => state.topifyPresets;

export default createSelector(
	[ threadviewSelector, metaSelector, userStateSelector, infiniteScrollSelector, topifyPresetsSelector, querySelector ],
	( threadview, meta, ustate, infiniteScroll, topifyPresets, query ) => {
		let forumid = () => {
			if( threadview.threadid && threadview.entities.topics ) {
				return parseInt( threadview.entities.topics[  threadview.threadid ].forum );

			} else {
				return null;
			}
		};

		return {
			forumId: forumid(),
			ustate: ustate,
			threadview: threadview,
			meta: meta,
			currentPage: infiniteScroll.page,
			totalPages: infiniteScroll.totalPages,
			forumSite: topifyPresets.forumSite,
			...query
		};
	}
);
