import { createSelector } from 'reselect';

const forumviewSelector = state => ( state.forumview.forumId || state.forumview.isFetching ? state.forumview : state.searchresult );
const uiStateSelector = state => state.ui;

export default createSelector(
	[forumviewSelector, uiStateSelector],
	(forumview, ui) => {
		return {
			forumview: forumview,
			announcementsOpen: ui.announcementsOpen
		}
	}
);
