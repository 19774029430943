import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import decode from 'Utils/encoding/decode';
import { trackGAEvent } from "Actions/adsActions";

class WidgetHotDealsComponent extends React.PureComponent {
	goToUrl = event => {
		let _url = event.currentTarget.dataset.url || null,
			_category = event.currentTarget.dataset.cat || null;
		if( _url ) {
			window.open( _url, '', '' );
			this.props.dispatch( trackGAEvent( 2, "true", _category, _url, 'hotDeals' ) );
		}
	};

	buildList( deal, order ) {
		return <li key={ deal.id } className="widget-deal">
			<div className="widget-dealContent">
				<span className="widget-dealTitle" data-url={ deal.deal_info.product_url } data-cat={ `mHotDealsPostLink${ order }` } onClick={ this.goToUrl }>{ deal.deal_info.brand }</span>
				<span data-url={ deal.deal_info.product_url } data-cat={ `mHotDealsPostLink${ order }` } onClick={ this.goToUrl }>{ decode( deal.deal_info.headline ) }</span>
			</div>
			<button className="widget-dealAction" data-dealid={ deal.id } data-url={ deal.deal_info.product_url } data-cat={ `mHotDealsDealLink${ order }` } onClick={ this.goToUrl } >View Deal</button>
		</li>
	}

	dealList() {
		// We need to use results to preserve the order received
		const { deals } = this.props.widget;
		return <ul className="widget-dealsContainer">{ deals.map( ( d, i )  => this.buildList( d, i ) ) }</ul>;
	}

	render() {
		if( !this.props.widget ) {
			return <span />
		}

		return <div id={ `widget-${ this.props.widgetName.replace( /\s+|\./ig, '_' ) }` } className="widget-hotDeals" >
			<div className="forumGroup">
				{ this.props.widgetName }
			</div>
			<div className="widgetContainer">
				{ this.dealList() }
			</div>
		</div>;
	}
}

WidgetHotDealsComponent.propTypes = {
	widget: PropTypes.object.isRequired,
	widgetName: PropTypes.string.isRequired
};

export default connect()( WidgetHotDealsComponent );