import {
	MESSAGES_FETCH, MESSAGES_SUCCESS,
	MESSAGES_FAILURE, MESSAGES_RESET,
	RESTORE_MESSAGES, SET_MESSAGES,
	MESSAGES_REQUEST_EPIC,
} from '../actions/MessagesviewActions';

import { LOADING_BEHAVIOR } from '../actions/InfiniteScrollActions';
import { isCachedChangedFor } from '../actions/CacheActions';

import merge from 'lodash/merge';
import union from 'lodash/union';
import uniq from 'lodash/uniq';

const initialState = {
	isFetching: false,
	isBgFetching: false,
	cacheDate: null,
	cacheChanged: false,
	loaded: false,
	lastError: '',
	entities: {},
	pages: [],
	result: {
		pms: [],
		pmfolders: [],
		users: []
	},
	pmroute: '',
	id: null,
};

export default function messagesview( prevState = initialState, action ) {

	switch( action.type ) {
		case MESSAGES_FETCH:
			return Object.assign( {},
				prevState,
				{
					isFetching: true,
					// Only set loaded to false if we are doing a full refresh
					loaded: !(action.loadingBehavior === LOADING_BEHAVIOR.REFRESH)
				} );

		case MESSAGES_SUCCESS:
			if(
				prevState.pmroute !== action.pmroute ||
				prevState.id !== action.id ||
				action.loadingBehavior === LOADING_BEHAVIOR.REFRESH
			) {

				return merge(
					{},
					action.data,
					{
						lastError: '',
						isFetching: false,
						isBgFetching: false,
						cacheDate: null,
						cacheChanged: isCachedChangedFor( {
							prevResult: prevState.result.pms,
							prevPage: prevState.page,
							currResult: action.data.result.pms,
							currPage: action.data.page
						} ),
						loaded: true,
						id: action.id,
						pmroute: action.pmroute,
						pages: [ parseInt( action.data.page ) ]
					},
					{
						prevState: prevState.prevState
					}
				);

			} else {
				let pmsResult, usersResult, pages;

				if( action.loadingBehavior === LOADING_BEHAVIOR.PREPEND ) {
					pmsResult = uniq( [ ...action.data.result.pms, ...prevState.result.pms ] );
					usersResult = union( action.data.result.users, prevState.result.users );
					pages = uniq( [ parseInt( action.data.page ), ...prevState.pages ] );
				} else if( action.loadingBehavior === LOADING_BEHAVIOR.APPEND ) {
					pmsResult = uniq( [ ...prevState.result.pms, ...action.data.result.pms ] );
					usersResult = union( prevState.result.users, action.data.result.users );
					pages = uniq( [ ...prevState.pages, parseInt( action.data.page ) ] );
				}

				return merge(
					{},
					prevState,
					action.data,
					{
						lastError: '',
						isFetching: false,
						cacheDate: null,
						cacheChanged: isCachedChangedFor( {
							prevResult: prevState.result.pms,
							prevPage: prevState.page,
							currResult: pmsResult || action.data.result.pms,
							currPage: action.data.page
						} ),
						loaded: true,
					},
					{
						pages: pages,
						result: {
							pms: pmsResult,
							users: usersResult
						}
					}
				);
			}

		case MESSAGES_FAILURE:
			return Object.assign( {}, prevState, {
					lastError: action.error,
					isFetching: false
				}
			);

		case MESSAGES_RESET:
			return initialState;

		case RESTORE_MESSAGES:
			return prevState.prevState || initialState;

		case SET_MESSAGES:
			return {
				...action.state,
				cacheDate: action.date,
				cacheChanged: false,
			};

		case MESSAGES_REQUEST_EPIC:
			return {
				...prevState,
				isBgFetching: true
			};

		default :
			return prevState;
	}
}

