import { createSelector } from 'reselect';

import querySelector from 'Selectors/queryStringSelector';

const infiniteScrollSelector = state => state.infiniteScroll;
const uiSelector = state => state.ui;
const metaSelector = state => state.meta;

export default createSelector(
	[ infiniteScrollSelector, uiSelector, metaSelector, querySelector ],
	( infiniteScroll, ui, meta, query ) => {
		return {
			...infiniteScroll,
			...query,
			isOnline: meta.isOnline,
			announcementsOpen: ui.announcementsOpen,
			shareMenuOpened: ui.shareMenuOpened,
			headerOverflowOpened: ui.headerOverflowOpened,
			sidebarOpened: ui.sidebarOpened,
			searchBarOpened: ui.searchBarOpened,
			userModalOpened: ui.userModal.opened
		};
	}
);
