import React from 'react';
import { connect } from 'react-redux';

import queryString from 'query-string';
import Loadable from 'react-loadable';

import PostList from 'Components/posts/PostListComponent';
import Breadcrumb from 'Components/common/BreadcrumbComponent';
import InThreadAdComponent from 'Components/ads/inThreadAdComponent';
import RelatedThreadListComponent from 'Components/threads/RelatedThreadListComponent';
import AdComponent from 'Components/ads/AdComponent';

import LoaderCss from 'Components/common/LoaderCssComponent';
import OffLineNote from 'Components/alerts/OfflineNoteComponent';
import InfiniteScroll from 'Components/infiniteScroll/InfiniteScrollComponent';

import threadViewSelector from 'Selectors/threadViewSelector';

import { fetchThread, resetThread } from 'Actions/ThreadviewActions';
import { onPageView, refreshTaboola } from 'Actions/adsActions';
import { resetInfinite } from 'Actions/InfiniteScrollActions';
import { resetGallery } from 'Actions/PhotoGalleryActions';
import { resetMetaLoading } from 'Actions/MetaActions';

const PhotoSwipe = Loadable( {
	loader: () => import( /* webpackChunkName: "inThread" */ 'Components/common/PhotoSwipe' ),
	loading: () => null
} );
const PollData = Loadable( {
	loader: () => import( /* webpackChunkName: "inThread" */ 'Components/posts/PollDataComponent' ),
	loading: ( props ) => <LoaderCss style={ { marginTop: '50px' } } { ...props } />
} );

class ThreadViewComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			threadId: null,
			postId: null,
			page: null,
			loadTaboola: false,
			loadTaboolaWidget: false
		};
	}

	static getDerivedStateFromProps( nextProps, state ) {
		let newState = {};

		if( state.threadId !== nextProps.match.params.threadId ) {
			newState.threadId = nextProps.match.params.threadId ? parseInt( nextProps.match.params.threadId ) : null;
		}

		let parsedQueryString = ( nextProps.history.location && nextProps.history.location.search ? queryString.parse( nextProps.history.location.search ) : {} ),
			page = parsedQueryString.page,
			postid = parsedQueryString.postid;

		if( state.postId !== postid ) {
			newState.postId = postid ? parseInt( postid ) : null;
		}

		if( state.page !== page ) {
			newState.page = page ? parseInt( page ) : null;
		}

		if( !nextProps.threadview.isFetching && nextProps.currentPage && nextProps.totalPages ) {
			newState.loadTaboola = nextProps.forumSite.f_taboola && nextProps.currentPage === nextProps.totalPages;
			let loadTaboolaWidget = parseInt( nextProps.currentPage ) === 1;
			if( state.loadTaboolaWidget !== loadTaboolaWidget ) {
				newState.loadTaboolaWidget = loadTaboolaWidget;
			}
		}

		return Object.keys( newState ).length ? newState : null;
	}

	componentDidMount() {
		this.loadThread();
	}

	componentWillUnmount() {
		this.props.dispatch( resetGallery() );
		this.props.dispatch( resetThread() );
		this.props.dispatch( resetInfinite() );
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			nextProps.threadview.loaded !== this.props.threadview.loaded
			|| nextProps.threadview.isFetching !== this.props.threadview.isFetching
			|| nextProps.threadview.result.posts.length !== this.props.threadview.result.posts.length
			|| nextProps.threadview.threadid !== this.props.threadview.threadid
			|| nextProps.history.location.search !== this.props.history.location.search
			|| nextState.loadTaboola !== this.state.loadTaboola
			|| nextState.loadTaboolaWidget !== this.state.loadTaboolaWidget
			|| nextState.threadId !== this.state.threadId
			|| nextState.postId !== this.state.postId
			|| (
				 nextState.page !== this.state.page
				&& nextProps.history.action !== 'REPLACE'
				&& nextProps.history.location.pathname === nextProps.location.pathname
			)
		);
	}

	componentDidUpdate( prevProps, prevState ) {
		if( this.props.threadview.isFetching !== false ) {
			return false;
		}

		// if we were redirected to a post ID in the queryString
		if( this.state.postId ) {
			let query = {},
				pathname = '/topics/' + (this.props.meta.threadid || this.state.threadId || 0),
				postContainer = document.getElementById( 'post_container_' + this.state.postId );

			// do we have the post already loaded? and we know what thread we are at?
			if( postContainer && this.props.meta.threadid ) {
				let postPage = postContainer.parentNode.className.match( /page_(\d+)/ );
				query.page = postPage && postPage.length > 0 ? parseInt( postPage[ 1 ] ) : page;

				delete query.postid;

				// we'll scroll to it
				this.scrollToPost( this.state.postId );

				// new location
				this.props.history.replace( {
					pathname: pathname,
					search: queryString.stringify( query )
				} );

				this.props.dispatch( resetMetaLoading() );
			} else if( this.props.threadview.lastError === 'No connection' && !this.props.threadview.isFetching ) {
				// no connection and no cache
				return false;

			} else {
				// We are not in same thread or the page hasn't been loaded yet
				this.loadThread();
				return false;
			}

		} else if( !this.state.page && this.state.threadId === parseInt( this.props.meta.threadid ) ) {
			// if we get the last read position on the metadata
			setTimeout( () => this.scrollToPost( this.props.threadview.postid || 0 ), 250 );

		} else if( prevState.page !== this.state.page && this.props.history.action !== 'REPLACE' ) {
			// we move to a different page (push or pop)
			this.props.dispatch( resetGallery() );
			this.props.dispatch( resetInfinite() );
			this.props.dispatch( resetThread() );

			this.loadPage( this.state.page, false );

		} else if( this.state.page === null && this.props.history.action !== 'REPLACE' ) {
			this.loadThread();
			return false;
		}

		if( this.props.threadview.loaded && !prevProps.threadview.loaded ) {
			// trigger ad updates
			this.props.dispatch( onPageView( { captify: "", pageTransition: true } ) );
		}

		// Taboola?
		if(
			this.state.loadTaboola
			&& (
				!prevState.loadTaboola
				|| this.props.currentPage === this.props.totalPages
			)
		) {
			this.props.dispatch( refreshTaboola( 'taboola' ) );
		}
		if(
			this.state.loadTaboolaWidget
			&& (
				!prevState.loadTaboolaWidget
				|| prevProps.threadview.loaded !== this.props.threadview.loaded
			)
		) {
			this.props.dispatch( refreshTaboola( 'recirculation' ) );
		}
	}

	loadThread = () => {
		this.props.dispatch( resetGallery() );
		this.props.dispatch( resetInfinite() );
		this.props.dispatch( resetThread() );

		this.props.dispatch( fetchThread( {
			id: (this.state.postId ? 0 : this.state.threadId),
			page: this.state.page,
			postid: this.state.postId
		} ) );
	};

	scrollToPost = ( postId ) => {
		let postContainer = postId ? document.getElementById( 'post_container_' + postId ) : null;

		// scrolling to the post
		document.getElementById( 'appContainer' ).scrollTop = postContainer ? (postContainer.offsetTop - 15) : 0;

		try {

			if( postContainer ) {
				// flash color
				postContainer.firstChild.classList.add( 'selected-item' );

				setTimeout( () => postContainer.firstChild.classList.remove( 'selected-item' ), 750 );

				setTimeout( () => {
					// smoothly reposition after 500ms
					postContainer.scrollIntoView( {
						behavior: 'smooth',
						block: 'center'
					} )
				}, 500 );
			}
		} catch( e ) { /* ignore it */ }
	};

	loadPage = ( page, forceRefresh ) => {
		this.props.dispatch( fetchThread( {
			id: parseInt( this.props.match.params.threadId ),
			page: page,
			forceRefresh: forceRefresh
		} ) );
	};

	render() {
		const { threadid, loaded, isFetching, entities: { topics }, result: { posts, polls, relatedThreads }, lastError, pages } = this.props.threadview;

		if( lastError === 'No connection' && !isFetching && pages.length === 0 ) {
			return <OffLineNote />
		}

		if (!loaded) {
			return <LoaderCss />;
		}

		let campaignAd = <AdComponent key={ 'Campaign_Top_Ad' } adType={ 108 } adName="Campaign_Top_Ad" />,
			bottomAdUnit = <AdComponent key={ `Bottom_Ad` } adType={ 2 } adName="Bottom_Content_Rectangle"  isFetching={ isFetching } />,
			taboolaAd = posts.length > 0 && this.state.loadTaboola ? <InThreadAdComponent adContainerId="taboola-mobile-below-article-thumbnails" className="taboola-placeholder ad-card" /> : null;

		let topNote = isFetching === false && ( topics[ threadid ].isDeleted || !topics[ threadid ].isOpen ) ? <div className="topic-alert">This topic is { topics[ threadid ].isDeleted ? 'deleted' : ( !topics[ threadid ].isOpen ? 'closed' : '' ) }</div> : null,
			pollData = polls.length > 0 ? <PollData /> : null;

		// if we are on last page or last pages has been already loaded
		let relatedThreadsList = relatedThreads && relatedThreads.length && this.props.currentPage === this.props.totalPages || pages.filter( p => this.props.totalPages === p ) ? <RelatedThreadListComponent /> : null;

		return (
			<React.Fragment>
				<Breadcrumb routeParams={this.props.match.params} />
				{ topNote }
				{ campaignAd }
				{ pollData }
				<InfiniteScroll
					scrollRef={ i => this.infiniteScroll = i }
					isFetching={ isFetching }
					loadPage={ this.loadPage }
					className={ 'posts' }
				>
					<PostList />
				</InfiniteScroll>
				{ relatedThreadsList }
				<PhotoSwipe />
				{ taboolaAd }
				{ bottomAdUnit }
			</React.Fragment>
		);
	}
}

export default connect( threadViewSelector )( ThreadViewComponent );
