import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import PageSelector from 'Components/infiniteScroll/PageSelectorComponent';
import LoaderCss from 'Components/common/LoaderCssComponent';
import OffLineNote from 'Components/alerts/OfflineNoteComponent';

import infiniteScrollSelector from 'Selectors/infiniteScrollSelector';
import { updatePageNumber } from 'Actions/InfiniteScrollActions';

import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';

class InfiniteScrollComponent extends React.Component {
	constructor( props ) {
		super( props );

		this.bottomPosition= 10;
		this.appContainer = document.getElementById( 'appContainer' );

		this.listRef = [];
		this.suppressScrollListener = true;
		this.justRepositioned = false;
		this.currentPos = 0;
		this.previousPos = 0;

		this.isLoadingTop = false;
		this.isLoadingBottom = false;
		this.previousOffset = 0;
		this.breadCrumbHeight = 0;
		this.headerHeight = 0;
		this.bottomFloatingAd = null;
		this.rePositionFixAdTimer = null;
	}

	componentDidMount() {
		// Getting header height, for the breadcrumb we need to wait until is mounted
		let header = document.getElementById( 'header' );
		this.headerHeight = header ? header.getBoundingClientRect().height : 48;

		// To be sure we have the correct page number
		let page = this.props.locationQueryString.page ? parseInt( this.props.locationQueryString.page ) : this.props.page;
		this.updatePageNumber( page );

		// Getting breadcrumb height
		let breadCrumb = document.getElementById( 'breadCrumbBar' );
		this.breadCrumbHeight = ( breadCrumb ? breadCrumb.getBoundingClientRect().height : 0 );

		// Getting bottom floating height
		this.bottomFloatingAd = document.getElementById( 'Fixed_Bottom_Leaderboard_container' );
		this.rePositionFixAdTimer = setTimeout( () => this._getBottomFixAdPosition(), 1500 );

		this.attachScrollListener();
	}

	componentWillUnmount() {
		this.detachScrollListener();

		if( this.rePositionFixAdTimer ) {
			clearTimeout( this.rePositionFixAdTimer );
			this.rePositionFixAdTimer = undefined;
		}
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			!isEqual( nextProps.retrievableContent, this.props.retrievableContent ) ||
			nextProps.isOnline !== this.props.isOnline ||
			nextProps.totalPages !== this.props.totalPages ||
			nextProps.page !== this.props.page ||
			nextProps.isFetching !== this.props.isFetching ||
			!isEqual( nextProps.pages, this.props.pages ) ||
			nextProps.extraHeaderHeight !== this.props.extraHeaderHeight
		);
	}

	getSnapshotBeforeUpdate( prevProps, prevState ) {
		this.suppressScrollListener = true;

		// Let's get the current scroll position
		this.previousOffset = this._getPageDimensions( this.props.page ).offsetTop;
		return {
			scrollTopBeforeUpdate: this.appContainer.scrollTop,
			page: this.props.pages[ 0 ],
			offset: this.previousOffset
		}
	}

	componentDidUpdate( prevProps, prevState, snapshot ) {
		// If the fixed bottom ad changed then reposition the page selector
		this._getBottomFixAdPosition();

		// Do we need to fix Scroll Position?
		if(
			this.listRef.length &&
			this.props.pages[ 0 ] !== this.props.page &&
			(
				(
					this.props.isFetching === false &&
					snapshot.page === this.props.pages[ 0 ]
				) || // when going up: not fetching and new page (lastPage) will be on pos 0
				prevProps.pages[ 1 ] === this.props.pages[ 0 ] // when going down: first page removed and previous pos 1 is now 0
			)
		) {
			// diff between distances from current page to the top and
			// previous current page before to the top is the diff in the scroll
			let pageDims = this._getPageDimensions( this.props.page ),
				diffFromTop = pageDims.offsetTop - snapshot.offset;

			if( diffFromTop ) {
				requestAnimationFrame( () => this.appContainer.scrollTop = snapshot.scrollTopBeforeUpdate + diffFromTop );
				this.justRepositioned = true;
			}

			this.previousPos = this.appContainer.scrollTop;
		}

		// Reset if needed isLoadingTop and isLoadingBottom
		if( prevProps.isFetching !== this.props.isFetching && !this.props.isFetching ) {
			this.isLoadingTop = this.isLoadingTop && this.props.isFetching;
			this.isLoadingBottom = this.isLoadingBottom && this.props.isFetching;
		}

		this.suppressScrollListener = false;

		// If page changed or if we just landed on the section,
		// we need to check if we need to load next page
		// even before the user scrolls
		if(
			this.props.lastError === '' && // we don't have an error
			this._overflowItemsOpen() === false &&
			this.props.totalPages > 0 && // If we have pages at all
			this.props.retrievableContent.includes( this.props.page ) === false && // we don't have the page on cache
			this.props.pages.includes( this.props.page ) && // we already got the current page
			(
				prevProps.page !== this.props.page || // page changed
				( this.props.pages.length === 1 && this.props.totalPages !== this.props.page )// we only have 1 page loaded and there are more
			)
		) {
			this._needLoadNewPage();

		} else if(
			(
				( this.props.isOnline && prevProps.isOnline === false ) // if We just come online
				|| ( !this.props.lastError && prevProps.lastError ) // flood time has expired
			)
			&& this._needLoadNewPage() === false
		) {
			this.synchronizePages();

		} else if( this.props.lastError ) {
			this.synchronizePages();
		}
	}

	_overflowItemsOpen = () => {
		return (
			this.props.announcementsOpen ||
			this.props.shareMenuOpened ||
			this.props.headerOverflowOpened ||
			this.props.sidebarOpened ||
			this.props.userModalOpened ||
			this.props.searchBarOpened
		);
	};

	attachScrollListener() {
		this.suppressScrollListener = false;
		window.addEventListener( "resize", this.synchronizePages );
		this.appContainer.addEventListener( "scroll", this.throttleScroll );
		document.addEventListener( 'adPositionChanged', this.adPositionChanged );
	}

	detachScrollListener() {
		this.suppressScrollListener = true;
		this.throttleScroll.cancel();
		window.removeEventListener( "resize", this.synchronizePages );
		this.appContainer.removeEventListener( "scroll", this.throttleScroll );
		document.removeEventListener( 'adPositionChanged', this.adPositionChanged );
	}

	loadPage = ( page, forceRefresh = false ) => {
		page = parseInt( page );
		if( page === this.props.page ) {
			return null;
		}

		// Stop listening for scroll
		this.suppressScrollListener = true;

		// If the page we want is already in screen, just scroll to it
		let gotoPageDim = this._getPageDimensions( page );
		if( gotoPageDim.height ) {
			// If we have breadCrumb bar we want to scroll bellow it
			let topPageHeight = this.props.extraHeaderHeight + this.headerHeight + this.breadCrumbHeight;
			// Scroll to the top of the page we want to go to
			this.appContainer.scrollTop += gotoPageDim.top - topPageHeight;

			// Update the page number
			this.updatePageNumber( page );

			// Done
			return true;
		}

		// If we still didn't load the page, get it
		if( this.props.pages.some( p => p === page ) === false ) {
			if( page < this.props.page - 1 || page > this.props.page + 1 ) {
				forceRefresh = true;
				requestAnimationFrame( () => this.appContainer.scrollTop = 0 );
			}

			new Promise( resolve => {
				resolve( this.props.loadPage( page, forceRefresh ) );

			} ).then( () => {
				this.isLoadingTop = false;
				this.isLoadingBottom = false;
				this.suppressScrollListener = false;
			} );

		} else {
			this.appContainer.scrollTop += this._getPageDimensions( page ).top;
			this.updatePageNumber( page );
		}
	};

	loadPreviousPage = () => {
		const { page } = this.props;
		this.isLoadingTop = true;
		this.loadPage( page - 1 );
	};

	loadNextPage() {
		const { page } = this.props;
		this.isLoadingBottom = true;
		this.loadPage( page + 1 );
	}

	gotoPage = ( page, forceRefresh = false ) => {
		this.loadPage( page, forceRefresh );
		this.updatePageNumber( page );
	};

	synchronizePages = () => {
		const scrollDirection = this.scrollDirection; // calling it once
		// Skip sync if we have a replyModal or confirmModal open
		let skipUpdate = history.state && ( history.state.type === 'confirmModal' || history.state.type === 'replyModal' );

		if( this._isLoading() || skipUpdate || scrollDirection === undefined ) {
			return null;

		} else {
			let pageRect,
				theSelectedPage = null,
				topPageHeight = this.props.extraHeaderHeight + this.headerHeight - this.breadCrumbHeight;

			// Go through all current pages to check where we are
			this.props.pages.forEach( ( p ) => {
				// if we are 'up' we take the first one matching
				// otherwise we'll take the latest
				if( scrollDirection === 'up' && theSelectedPage ) {
					return; // skip to next one because forEach will run through all
				}
				pageRect = this._getPageDimensions( p );
				let innerHeight = window.innerHeight - this.bottomPosition;

				if(
					( pageRect.top > 0 && pageRect.top < innerHeight ) || // just show up from the bottom
					( pageRect.bottom < window.innerHeight && pageRect.bottom > topPageHeight ) || // just show up from the top
					( pageRect.top < innerHeight && pageRect.bottom >= innerHeight ) // it's totally on viewport
				) {
					theSelectedPage = p;
				}
			} );

			this.updatePageNumber( ( theSelectedPage || this.props.page ) );
		}
	};

	get scrollDirection() {
		if( this.previousPos ) {
			switch( true ) {
				case (this.currentPos > this.previousPos):
					this.prevDirection = 'down';
					break;

				case (this.currentPos < this.previousPos):
					this.prevDirection = 'up';
					break;
			}
		}

		this.previousPos = this.currentPos;
		return this.prevDirection;
	}

	// Throttle the scroll listener to every 100ms
	throttleScroll = throttle( () => {
		const { page }  = this.props,
			diffPos = ( this.previousPos - this.currentPos );

		// Skip the first catch after forcing reposition
		if( this.justRepositioned ) {
			this.justRepositioned = false;
			return true;
		}

		// save position
		this.currentPos = this.appContainer.scrollTop;

		// do we force skip?
		if( this.suppressScrollListener || this._overflowItemsOpen() ) {
			return false;
		}

		// if the page is loading or there is not retrievable content skip
		if( this._isLoading() ) {
			return false;
		}

		let currentPage = this._getPageDimensions( page ),
			diffOffset = this.previousOffset - currentPage.offsetTop;

		// Skip because previous page have changed in size,
		// probably ads or images loading or ads clearing
		if( diffOffset === diffPos ) {
			return false;
		}

		// Do we need to load a new page or sync pages?
		if( this._needLoadNewPage() === false ) {
			this.synchronizePages();
		}

	}, 100, {
		'leading': true,
		'trailing': true
	} );

	updatePageNumber( page ) {
		const { locationQueryString } = this.props;

		// Do we need to update? avoid updating if everything is already OK
		let currentQueryPage = locationQueryString && locationQueryString.page ? parseInt( locationQueryString.page ) : null;

		if(
			isFinite( page ) === false || // page passed is not a number
			( page === 1 && currentQueryPage === null ) || // we are in page 1 and we have the query saying otherwise
			currentQueryPage === page // querystring already says we are here
		) {
			this.previousPos = this.appContainer.scrollTop;
			this.suppressScrollListener = false;
			return true;
		}

		this.suppressScrollListener = true;

		new Promise( ( resolve ) => {
			resolve( this.props.dispatch( updatePageNumber( page, this.props.location ) ) );
		} ).then( () => {
			this.suppressScrollListener = false;
		} );
	}

	loadingAnimation = ( pos ) => {
		const page = this.props.page + ( pos === 'top' ? -1 : 1 );
		return this.props.retrievableContent.includes( page ) ? <OffLineNote /> : <LoaderCss container={ false } className={ pos }/>;
	};

	loadPreviousButton() {
		return this.props.retrievableContent.includes( this.props.page - 1 ) ? <OffLineNote /> : <div className="loadMore" onClick={this.loadPreviousPage}>Load Previous</div>;
	}

	topLoader() {
		const { page, pages } = this.props;

		if( this.isLoadingTop ) {
			return this.loadingAnimation( 'top' );

		} else if( pages[ 0 ] > 1 && ( pages.length <= 1 || page === pages[ 0 ] ) ) {
			return this.loadPreviousButton();
		}
	}

	bottomLoader() {
		const { totalPages, page, pages, isOnline, lastError } = this.props;

		if( page < totalPages && pages.filter( p => totalPages === p ).length === 0 && ( this.isLoadingBottom || !isOnline || !lastError ) ) {
			return this.loadingAnimation( 'bottom' );
		}
	}

	/**
	 * Get all dimensions we wanna use about one page
	 * getBoundingClientRect ( height, top, bottom... ) and offsetTop
	 *
	 * @param page
	 * @returns {*}
	 * @private
	 */
	_getPageDimensions( page ) {
		let defaultDimensions = { height: 0, top: 0, bottom: 0, width: 0, offsetTop: 0 };

		try {
			let thePage = this.listRef[ page ];
			return Object.assign( thePage.getBoundingClientRect(), { offsetTop: thePage.offsetTop } );
		} catch( e ) {
			return defaultDimensions;
		}
	}

	_isLoading() {
		return this.props.isFetching || this.isLoadingTop || this.isLoadingBottom;
	}

	_needLoadNewPage() {
		if(
			!this.props.retrievableContent.includes( this.props.page + 1 ) &&
			!this._isLoading() && // if loading, skip
			this.props.totalPages > this.props.page && // if we are at the bottom, skip
			this.props.pages.indexOf( this.props.page + 1 ) < 0 // if we have the page already loaded, no need
		) {
			// Load Next page
			this.loadNextPage();
			return true;
		}

		return false;
	}

	adPositionChanged = ( e ) => {
		if( e.detail && e.detail.adName ) {
			this._getBottomFixAdPosition();
		}
	};

	_getBottomFixAdPosition() {
		this.bottomFloatingAd = this.bottomFloatingAd || document.getElementById( 'Fixed_Bottom_Leaderboard_container' );
		if( this.bottomFloatingAd ) {
			try {
				this.bottomPosition = this.bottomFloatingAd.getBoundingClientRect().height;
			} catch( e ) { /* ignore */ }
		}
	}

	_getChildrenWithProps = () => {
		return React.Children.map( this.props.children, ( child ) => {
			if( child ) {
				return this.props.pages.reduce( ( a, page ) => {
					let childPage = React.cloneElement( child, {
							pages: [ page ],
							page: page,
							key: page,
							listRef: list => this.listRef[ page ] = list
						} );
					return a.concat( childPage );
				}, [] );
			}
		} );
	};

	render() {
		const { page, totalPages } = this.props,
			style = this.props.extraHeaderHeight ? { marginTop: this.props.extraHeaderHeight + 'px' } : null;

		return (
			<div id='scroll' ref={ this.props.scrollRef } className={ this.props.className } style={ style } >
				{ this.topLoader() }
				{ this._getChildrenWithProps() }
				{ this.bottomLoader() }
				<PageSelector
					currentPage={ page }
					totalPages={ totalPages }
					onFirstButtonClick={ () => this.gotoPage( 1, false ) }
					onLastButtonClick={ () => this.gotoPage( totalPages, false ) }
					onJumpPage={ ( newPage ) => this.gotoPage( newPage, true ) }
				/>
			</div>
		);
	}
}

InfiniteScrollComponent.defaultProps = {
	page: 1,
	totalPages: 0,
	extraHeaderHeight: 0,
	earliestLoadedPage: null,
	latestLoadedPage: null,
	lastError: ''
};

InfiniteScrollComponent.propTypes = {
	page: PropTypes.oneOfType( [
		PropTypes.string,
		PropTypes.number
	] ),
	totalPages: PropTypes.number.isRequired,
	isOnline: PropTypes.bool.isRequired,
	isFetching: PropTypes.bool.isRequired,
	loadPage: PropTypes.func.isRequired,
	locationQueryString: PropTypes.object.isRequired,
	earliestLoadedPage: PropTypes.number,
	latestLoadedPage: PropTypes.number,
	scrollRef: PropTypes.func.isRequired,
	lastError: PropTypes.string
};

export default withRouter( connect( infiniteScrollSelector )( InfiniteScrollComponent ) );
