import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import SvgIcon from 'Components/svg/SvgIcon';

class PageSelectorComponent extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			bottomPosition: 0,
			isOpen: false,
			jumpPage: props.currentPage,
			currentPage: props.currentPage
		};

		this.rePositionFixAdTimer = null;
		this.bottomFloatingAd = null;
	}

	static getDerivedStateFromProps( props, state ) {
		return props.currentPage !== state.currentPage ? { currentPage: props.currentPage, jumpPage: props.currentPage } : null;
	}

	componentDidMount() {
		document.addEventListener( 'adPositionChanged', this.adPositionChanged );

		this.bottomFloatingAd = this.bottomFloatingAd || document.getElementById( 'Fixed_Bottom_Leaderboard_container' );
		this.rePositionFixAdTimer = setTimeout( () => this._getBottomFixAdPosition(), 1500 );
	}

	componentWillUnmount() {
		if( this.rePositionFixAdTimer ) {
			clearTimeout( this.rePositionFixAdTimer );
			this.rePositionFixAdTimer = undefined;
		}

		document.removeEventListener( 'adPositionChanged', this.adPositionChanged );
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			nextProps.currentPage !== this.props.currentPage ||
			nextProps.totalPages !== this.props.totalPages ||
			nextState.bottomPosition !== this.state.bottomPosition ||
			nextState.isOpen !== this.state.isOpen ||
			nextState.jumpPage !== this.state.jumpPage
		)
	}

	componentDidUpdate() {
		this._getBottomFixAdPosition();
	}

	toggleBody = () => {
		this.setState( {
			isOpen: !this.state.isOpen
		} );
	};

	onPageInputChange = ( e ) => {
		let newPage = parseInt( e.target.value );

		this.setState( { jumpPage: isNaN( newPage ) ? null : newPage } );
	};

	onFirstButtonClick = () => {
		this.setState( {
			isOpen: false
		} );

		if( this.props.onFirstButtonClick ) {
			this.props.onFirstButtonClick();
		}
	};

	onLastButtonClick = () => {
		this.setState( {
			isOpen: false
		} );

		if( this.props.onLastButtonClick ) {
			this.props.onLastButtonClick();
		}
	};

	onJumpPage = ( e ) => {
		e.preventDefault();

		// Validate input
		let newPage = this.state.jumpPage;

		if( isNaN( newPage ) ) {
			newPage = this.props.currentPage;
		}

		if( newPage < 1 ) {
			newPage = 1;
		}

		if( newPage > this.props.totalPages ) {
			newPage = this.props.totalPages;
		}

		this.setState( {
			isOpen: false,
			jumpPage: newPage
		} );

		if( this.props.onJumpPage ) {
			// we need to delay the jump because of safari and give time to close the keypad
			requestAnimationFrame( () => requestAnimationFrame( () => this.props.onJumpPage( newPage ) ) );
		}
	};

	adPositionChanged = ( e ) => {
		if( e.detail && e.detail.adName ) {
			this._getBottomFixAdPosition();
		}
	};

	_getBottomFixAdPosition() {
		this.bottomFloatingAd = this.bottomFloatingAd || document.getElementById( 'Fixed_Bottom_Leaderboard_container' );
		if( this.bottomFloatingAd ) {
			try {
				let bottomPosition = this.bottomFloatingAd.getBoundingClientRect().height;
				this.setState( { bottomPosition: bottomPosition } );
			} catch( e ) { /* ignore */ }
		}
	}

	render() {
		let { currentPage, totalPages } = this.props;
		let { isOpen, jumpPage, bottomPosition } = this.state;

		if( isNaN( totalPages ) || totalPages <= 1 ) {
			return <span />
		}

		return (
			<div className="page-selector" style={{ bottom: bottomPosition + 'px' }}>
				<div className="page-selector-header" onClick={ this.toggleBody }>
					<span>{currentPage} / {totalPages}</span>
					<span className="toggle-icon">
					<SvgIcon size="18px" icon={isOpen ? "angle_down" : "angle_up"}/>
				</span>
				</div>
				<div className={"page-selector-body" + (isOpen ? "" : " hidden") }>
					<div>
						<button className={ "full-width" + ( currentPage === 1 ? ' disabled' : '' ) } onClick={this.onFirstButtonClick}>
							<SvgIcon size="1.5em" icon="first_page"/> First page
						</button>
					</div>
					<div className={ parseInt( totalPages ) <= 2 ? 'hidden' : '' }>
						<input className="page-input" type="number" value={ jumpPage || '' }
							onChange={ this.onPageInputChange }
							onFocus={ this.focusInput }
							onBlur={ this.blurInput }
						/>
						<button className="jump-button" onClick={ this.onJumpPage } >Jump</button>
					</div>
					<div>
						<button className={ "full-width" + ( currentPage === totalPages ? ' disabled' : '' ) } onClick={this.onLastButtonClick}>
							Last page <SvgIcon size="1.5em" icon="last_page"/></button>
					</div>
				</div>
			</div>
		);
	}
}

PageSelectorComponent.propTypes = {
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	onFirstButtonClick: PropTypes.func,
	onLastButtonClick: PropTypes.func,
	onJumpPage: PropTypes.func
};

export default connect()( PageSelectorComponent );
