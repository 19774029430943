import { createSelector } from 'reselect';
import metaSelector from 'Selectors/metaSelector';
import querySelector from 'Selectors/queryStringSelector';

const forumviewSelector = state => ( state.forumview.forumId || state.forumview.isFetching ? state.forumview : state.searchresult );
const topifyPresetsSelector = state => state.topifyPresets;
const userStateSelector = state => state.ustate;
const uiStateSelector = state => state.ui;

export default createSelector(
	[forumviewSelector, metaSelector, topifyPresetsSelector, userStateSelector, uiStateSelector, querySelector],
	(forumview, meta, topifyPresets, ustate, ui, query) => {
		return {
			forumview: forumview,
			meta: meta,
			ustate: ustate,
			userSettings: ui.userSettings,
			forumSite: topifyPresets.forumSite,
			firstLoad: topifyPresets.firstLoad,
			initialRoute: topifyPresets.initialRoute,
			initialId: topifyPresets.initialId,
			secondaryId: topifyPresets.secondaryId,
			initialPage: topifyPresets.initialPage,
			hotDeals: topifyPresets.hotDeals,
			...query
		}
	}
);
