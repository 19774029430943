import { SET_CURRENT_USER, SET_USTATE } from '../actions/UstateActions';

export default function ustate( prevState = { id: 0 }, action ) {
	switch( action.type ) {
		case SET_CURRENT_USER:
			return {
				...prevState,
				...action.ustate
			};

		case SET_USTATE:
			return {
				...action.state,
				cacheDate: action.date
			};

		default:
			return prevState;
	}
}
