import React from 'react';

import Loadable from 'react-loadable';

// lazy loaded chunks
const HeaderOverflow = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/header/HeaderOverflowComponentGuests' ),
	loading: () => null
} );
const HeaderOverflowLogged = Loadable( {
	loader: () => import( /* webpackChunkName: "logged" */ 'Components/header/HeaderOverflowComponentLogged' ),
	loading: () => null
} );

const HeaderOverflowLoader = ( { isLoggedIn, deferredPrompt, cleanDeferredPrompt } ) => {
	return isLoggedIn ?
		<HeaderOverflowLogged deferredPrompt={ deferredPrompt } cleanPrompt={ cleanDeferredPrompt } /> :
		<HeaderOverflow deferredPrompt={ deferredPrompt } cleanPrompt={ cleanDeferredPrompt } />;
};

export default HeaderOverflowLoader;
