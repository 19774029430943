import { createSelector } from 'reselect';
import queryString from 'query-string';

const routerSelector = state => state.router;

export default createSelector(
	[routerSelector],
	(router) => {
		return {
			locationQueryString: ( router.location && router.location.search ? queryString.parse( router.location.search ) : {} )
		};
	}
);