import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import UserIcon from 'Components/common/UserIconComponent';
import SvgIcon from 'Components/svg/SvgIcon';
import CheckboxComponent from 'Components/common/CheckboxComponent';
import DateComponent from 'Components/common/dateComponent';

import decode from 'Utils/encoding/decode';
import rawToReact from 'Utils/bbcode/RawToReact';
import { pathToThread } from 'Utils/nav/NavHelpers';

import { resetOverlay, showLoginModal, showConfirmModal } from 'Actions/UiActions';
import { updateFollowing } from 'Actions/ForumviewActions';
import { toggleSelectedItem } from 'Actions/MetaActions';

class ThreadComponent extends React.Component {
	subscribeThread = ( event ) => {
		let userState = this.props.ustate, isSub, subType;
		event.stopPropagation(); // stop here

		if( userState.id ) {
			if (typeof this.props.forum !== 'undefined' ) {
				isSub = this.props.forum.isSubscribed;
				subType = 'Forum';
			} else {
				isSub = this.props.thread.isSubscribed;
				subType = 'Topic';
			}

			if( isSub ) {
				this.props.dispatch( showConfirmModal( 'Unsubscribe', `Would you like to unsubscribe from this ${subType}?`, () => this.props.dispatch( updateFollowing( this.props ) ) ) );
			} else {
				this.props.dispatch( updateFollowing( this.props ) );
			}

		} else {
			// for guests show the login modal
			this.props.dispatch( resetOverlay() );
			this.props.dispatch( showLoginModal() );
		}
	};

	selectThread = () => {
		this.props.dispatch( toggleSelectedItem( this.props.thread.id ) );
	};

	_openThreadNewTab = ( event ) => {
		event.preventDefault();
		event.stopPropagation();
		let path = pathToThread( this.props.thread.id );
		window.open( this.props.thread.href + `#${ path }`, `${ path }_${ Date.now() }` );
	};

	render() {
		let { id, title, postsCount, lastDateline, dateline, unread, prefix_title_rich } = this.props.thread;

		let prefix = prefix_title_rich ? <span className="prefix">{ rawToReact( prefix_title_rich ) }</span> : null,
			preview = this.props.showLast && this.props.thread.previewlast ? this.props.thread.previewlast : this.props.thread.preview,
			openThread = this.props.expandedContent ? null : <a className="open-new-tab" onClick={ this._openThreadNewTab }><SvgIcon size="1em" icon="open_new"/></a>,
			content_showed = this.props.expandedContent ?
				rawToReact( this.props.expandedContent, this.props, false )
				:
				decode( preview );

		const statsData = postsCount ?
			<div className="stats">
				<div className="stats-counter"><SvgIcon size="12px" icon="forum"/>{ postsCount.toLocaleString() } { `post${ parseInt( postsCount ) !== 1 ? 's' : '' }` }</div>
				<DateComponent timestamp={ lastDateline } icon={ true } />
			</div> :
			<div className="stats single-right"><DateComponent timestamp={ dateline } icon={ true } /></div>;

		const subscribe = this.props.isSubscribable ?
			<div className="subscribe" onClick={ this.subscribeThread }>
				<SvgIcon size="20px" icon="subscribe"/>
			</div> : null;

		const deleted = this.props.thread.isDeleted ?
			<div className="thread-deleted" >
				<SvgIcon size="20px" icon="delete"/>
			</div> : null;

		const isSticky = this.props.isSearch && this.props.thread.isSticky ?
			<div className="announcements-icon sticky thread-sticky" >
				<SvgIcon size="16px" icon="pin"/>
			</div> : null;

		// phpbb shows whether a thread has been reported
		const isReported = this.props.thread.isReported && parseInt( this.props.thread.isReported ) === 1 ?
			<div className="thread-reported" >
				<SvgIcon size="20px" icon="error_outline"/>
			</div> : null;

		const user = this.props.user || { avatar: '', userName: 'Guest' };

		let checkBox = this.props.ustate.id && this.props.isSubscribable && this.props.isSearch !== true && !deleted ?
				<CheckboxComponent
					name={ `threadSelect_${ this.props.thread.forum }` }
					id={ `threadSelect_${ id }` }
					value={ id }
					checked={ this.props.isSelected || false }
					multiple={ true }
					className={ 'inPost' }
					onChange={ this.selectThread }
				/> : null;

		// checking what classes need to be added
		let classNames = 'nolinks noskim thread';
		classNames += this.props.ustate.id && unread ? ' indicator' : '';
		classNames += this.props.isSubscribed ? ' subscribed' : '';
		classNames += deleted ? ' deleted' : '';
		classNames += this.props.isSelected ? ' selected-item' : '';

		return (
			<li onClick={ this.props.onClick } data-threadid={ id } className={ this.props.className } >
				<div className={ classNames }>
					<div className="left">
						<UserIcon user={ user } />
					</div>
					<div className="right">
						<div className="thread-info">
							{ checkBox }
							{ isReported }
							{ isSticky }
							{ subscribe }
							{ deleted }
						</div>
						<div className="title">
							{ prefix }{ prefix ? ' ' : '' }{ rawToReact( title ) }{ openThread }
						</div>
						{ statsData }
						<div className="topic-preview">
							{ content_showed }
						</div>
					</div>
				</div>
			</li>
		)
	}
}

ThreadComponent.defaultProps = {
	className: '',
	showLast: false
};

ThreadComponent.propTypes = {
	onClick: PropTypes.func.isRequired,
	thread: PropTypes.object.isRequired,
	ustate: PropTypes.object.isRequired,
	isSelected: PropTypes.bool.isRequired,
	isSubscribed: PropTypes.bool.isRequired,
	isSubscribable: PropTypes.bool.isRequired,
	user: PropTypes.object,
	showLast: PropTypes.bool,
	expandedContent: PropTypes.string,
	className: PropTypes.string,
};

export default connect()( ThreadComponent );