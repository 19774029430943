import { createSelector } from 'reselect';

const metaSelector = state => state.meta;
const userPermissionsSelector = state => state.ustate.perms;

export default createSelector(
	[metaSelector,userPermissionsSelector],
	(meta, userPermissions) => {
		return {
			...meta,
			userPermissions
		};
	}
);