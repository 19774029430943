import React from 'react';

import Loadable from 'react-loadable';

// lazy loaded chunks
const SvgIcons = Loadable( {
	loader: () => import( /* webpackChunkName: "modals" */ 'Components/svg/SvgIconsComponent' ),
	loading: () => <span>&#x02235;</span>,
	delay: 0.1
} );

const SvgIconsComponent = ( props ) => {
	return <SvgIcons { ...props } />;
};

export default SvgIconsComponent;
