import { PORTALPAGE_FAILURE, PORTALPAGE_FETCH, PORTALPAGE_RESET, PORTALPAGE_SUCCESS } from 'Actions/PortalPageActions';

const initialState = {
	isFetching: false,
	loaded: false,
	lastError: '',
	entities: {},
	result: {},
};

export default function portalpage( prevState = initialState, action ) {
	switch( action.type ) {
		case PORTALPAGE_FETCH:
			return Object.assign( {},
				prevState,
				{
					isFetching: true,
					// Only set loaded to false if we are doing a full refresh
					loaded: false
				} );

		case PORTALPAGE_SUCCESS:
			return Object.assign( {},
				action.data,
				{
					isFetching: false,
					loaded: true
				}
			);

		case PORTALPAGE_FAILURE:
			return Object.assign( {}, prevState, {
				lastError: action.error,
				isFetching: false
			} );

		case PORTALPAGE_RESET:
			return initialState;

		default :
			return prevState;
	}
}

