import React from 'react';

import OfflineNote from 'Components/alerts/OfflineNoteComponent';
import RetryNote from 'Components/alerts/RetryNoteComponent';

export default ( props ) => {
	let style = props.style ? props.style : {},
		extraClassName = props.className ? `  ${ props.className }` : '',
		hasContainer = !!( props.container !== false || props.containerClass ),
		containerClass = props.containerClass ? props.containerClass : '';

	let loader = <div className={ `loader-css${ extraClassName }` } style={ style }><div /></div>;

	if( props.lazyLoad ) {
		if( props.error ) {
			return navigator.onLine ? <RetryNote retry={ props.retry } /> : <OfflineNote />;

		} else if( props.timedOut ) {
			return <header className="thread topLink"><p className="subheader-selector">Taking a long time... { loader }</p></header>;

		} else if( props.pastDelay ) {
			return loader;

		} else {
			return null;
		}
	}

	return hasContainer === false ? loader : <div className={ containerClass }>{ loader }</div>;
};
