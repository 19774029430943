import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'Components/svg/SvgIcon';
import prettyDate from 'Utils/time';

const dateComponent = ( { timestamp, icon = false, icon_name = 'clock', format = 'MMM DD, YYYY' } ) => {
	let iconTag = icon ? <SvgIcon size="12px" icon={ icon_name } /> : null;

	if( timestamp ) {
		return <time dateTime={prettyDate( timestamp, 'datetime' )}>{iconTag}{prettyDate( timestamp, format )}</time>;
	} else {
		return <time>{iconTag}Never</time>;
	}
};

dateComponent.propTypes = {
	timestamp: PropTypes.oneOfType( [
		PropTypes.string,
		PropTypes.number
	] ).isRequired,
	icon: PropTypes.bool,
	icon_name: PropTypes.string
};

export default dateComponent;
