import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import infiniteScrollSelector from 'Selectors/infiniteScrollSelector';

const inThreadAdComponent = ( { adContainerId, className, tag } ) => {
	const CustomTag = `${ tag || 'div' }`;

	return <CustomTag  className={ `inThreadAd-placeholder ${ className }` }><div id={ adContainerId } /></CustomTag>;
};

inThreadAdComponent.propTypes = {
	adContainerId: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default connect( infiniteScrollSelector )( inThreadAdComponent );
