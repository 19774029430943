import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import forumViewSelector from 'Selectors/forumViewSelector';

import Forum from 'Components/forums/ForumComponent';
import ForumLink from 'Components/forums/ForumLinkComponent'
import AdComponent from 'Components/ads/AdComponent';

import { pathToForum } from 'Utils/nav/NavHelpers';
import { resetInfinite } from 'Actions/InfiniteScrollActions';
import { resetForum } from 'Actions/ForumviewActions';
import { resetThread } from 'Actions/ThreadviewActions';

class ForumListComponent extends React.Component {
	gotoForum = ( id ) => {
		this.props.dispatch( resetInfinite() );
		this.props.dispatch( resetForum() );
		this.props.dispatch( resetThread() );

		this.props.history.push( pathToForum( id ) );
	};

	buildForum = ( forum ) => {
		const { forumId } = this.props.forumview,
			{ ustate } = this.props;

		if (forum.id !== forumId) {
			if( forum.link ) {
				return <ForumLink
					key={ `forum_${ forum.id }` }
					forum={ forum }
				/>;
			} else {
				return <Forum
					key={ `forum_${ forum.id }` }
					forum={ forum }
					ustate={ ustate }
					dispatch={ this.props.dispatch }
					onClick={ this.gotoForum }
				/>;
			}
		}
	};

	buildPage = ( pageNum ) => {
		const { entities, result } = this.props.forumview;

		let forums = result.forums.filter( e => entities.forums[ e ] && entities.forums[ e ].page === pageNum );
		return forums.map( id => this.buildForum( entities.forums[ id ] ) );
	};

	render() {
		const { entities: { forums }, result, forumId } = this.props.forumview;

		let forumListPage = null;
		let adUnitIncontent = ( typeof this.props.adUnitIncontent !== 'undefined' && this.props.adUnitIncontent );

		if( forums === undefined ) {
			return null;

		} else if( this.props.isScrolled ) { // if we use infiniteSroll build pages
			forumListPage = this.buildPage( this.props.page );

		} else {
			let listForums = this.props.forumList || result.forums,
				isThePlaceForTheAd = listForums.length > 2 ? 1 : 0, // for > 2 subforums: after the second, otherwise after first
				forumList = listForums.map(
					(k, i) => {
						let the_forum,
							forum = forums[k];

						if (forum.id !== forumId) {
							if( forum.link ) {
								the_forum = [
									<ForumLink
										key={ `forum_${ forum.id }` }
										forum={ forum }
									/>
								];
							} else {
								the_forum = [
									<Forum
										key={ `forum_${ forum.id }` }
										forum={ forum }
										ustate={ this.props.ustate }
										dispatch={ this.props.dispatch }
										onClick={ this.gotoForum }
									/>
								];
							}
						}

						// if we are in the place where the ad needs to be, place it
						let adUnit = ( adUnitIncontent && i === isThePlaceForTheAd ) ? <AdComponent key={ `HomePage_In_Content_Ad_${ forum.id }` } adType={ 101 } adName={ 'HomePage_In_Content_Ad_' + forum.id } tag="li" /> : '';

						if( adUnit ) {
							the_forum.push( adUnit );
						}

						return the_forum;
					}
			);

			forumListPage = <ul>{ forumList }</ul>
		}

		if( this.props.isScrolled ) {
			return <ul key={ this.props.page } ref={ this.props.listRef } className={`scroll_page forums_page page_${this.props.page}`}>
				{ forumListPage }
			</ul>;
		}

		return (
			<div ref={ this.props.listRef } className="forums nolinks noskim">
				{ forumListPage }
			</div>
		);
	}
}

ForumListComponent.propTypes = {
	dispatch: PropTypes.func.isRequired,
	page: PropTypes.number
};

export default withRouter( connect( forumViewSelector )( ForumListComponent ) );
