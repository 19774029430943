import {
	FETCH_ONLINE_USERS,
	FETCH_ONLINE_USERS_SUCCESS,
	RESET_ONLINE_USERS,
	ONLINE_USERS_FAILURE
} from '../actions/OnlineUsersActions';

import { LOADING_BEHAVIOR } from '../actions/InfiniteScrollActions';

import merge from 'lodash/merge';

const initialState = {
	entities: {},
	result: {
		users: [],
		online: []
	},
	page: 0,
	totals: {
		members: 0,
		guests: 0
	},
	isFetching: false,
	loaded: false,
	lastError: '',
	pages: []
};

export default function onlineUsers( prevState = initialState, action ) {

	switch( action.type ) {
		case FETCH_ONLINE_USERS:
			return {
				...prevState,
				isFetching: true,
				loaded: !(action.loadingBehavior === LOADING_BEHAVIOR.REFRESH)
			};

		case FETCH_ONLINE_USERS_SUCCESS:
			if( action.loadingBehavior === LOADING_BEHAVIOR.REFRESH ) {
				return {
					...prevState,
					...action.data,
					isFetching: false,
					lastError: '',
					loaded: true,
					pages: [ action.data.page ]
				}
			} else {
				let pages, online;
				if( action.loadingBehavior === LOADING_BEHAVIOR.PREPEND ) {
					online = [ ...action.data.result.online, ...prevState.result.online ];
					pages = [ action.data.page, ...prevState.pages ]
				} else {
					online = [ ...prevState.result.online, ...action.data.result.online ];
					pages = [ ...prevState.pages, action.data.page ]
				}
				return merge(
					{},
					prevState,
					action.data,
					{
						result: {
							users: prevState.users,
							online: online
						},
						isFetching: false,
						loaded: true,
						lastError: '',
						pages: pages
					}
				)
			}

		case ONLINE_USERS_FAILURE:
			return Object.assign( {}, prevState, {
				lastError: action.error,
				isFetching: false
			} );

		case RESET_ONLINE_USERS:
			return initialState;

		default:
			return prevState
	}
};

