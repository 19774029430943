import React from 'react';
import PropTypes from 'prop-types';

const secondaryAdComponent = ({ adName, type }) => {
	return <iframe id={ `SecondaryAd_${ adName }` } className={ `secondary-ad ${ type }_secondary-ad` } scrolling="no" />;
};

secondaryAdComponent.propTypes = {
	adName: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired
};

export default secondaryAdComponent;
