import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import isEqual from 'lodash/isEqual';
import Loadable from 'react-loadable';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import UserIcon from 'Components/common/UserIconComponent';
import CheckboxComponent from 'Components/common/CheckboxComponent';
import SvgIcon from 'Components/svg/SvgIcon';
import DateComponent from 'Components/common/dateComponent';

import rawToReact from 'Utils/bbcode/RawToReact';
import decode from 'Utils/encoding/decode';

import { toggleSelectedItem } from 'Actions/MetaActions';

const PhotoSwipeGallery = Loadable( {
	loader: () => import( /* webpackChunkName: "inThread" */ 'Components/common/PhotoSwipeGallery' ),
	loading: () => null
} );

class PostComponent extends React.Component {
	constructor(props) {
		super( props );

		this.state = {
			showAttachmentsPreview: this.props.userSettings.showAttachmentsPreview
		}
	}

	shouldComponentUpdate( nextProps, nextState ) {
		return (
			nextProps.isSelected !== this.props.isSelected ||
			nextProps.postId !== this.props.postId ||
			nextProps.edited !== this.props.edited ||
			nextProps.post.message !== this.props.post.message ||
			!isEqual( nextProps.post.attachments, this.props.post.attachments ) ||
			nextProps.userSettings.showAttachmentsPreview !== this.props.userSettings.showAttachmentsPreview ||
			nextState.showAttachmentsPreview !== this.state.showAttachmentsPreview
		);
	}

	selectPost = () => {
		this.props.dispatch( toggleSelectedItem( this.props.postId ) );
	};

	_getThumbnailContent = (item) => {
		return <img src={item.thumbnail} />;
	};

	_getVisibleAttachments = () => {
		return this.props.items[ this.props.postId ] || [];
	};

	_getEditNote = () => {
		let { post } = this.props;

		if( post.edited && post.edited.when ) {
			let editedWho = post.edited.who_username ? <React.Fragment>Last edited by <em>{ decode( post.edited.who_username ) }</em>&nbsp;-&nbsp;</React.Fragment> : `Last edited `,
				editedReason = post.edited.why ? <span> - Reason: <em>{ post.edited.why }</em></span> : null;
			return (
					<div className="postFooter postEdited">
						<div className="title">Post Edited</div>
						<ul>
							<li>
								{ editedWho }
								<DateComponent timestamp={ post.edited.when } />
								{ editedReason }
							</li>
						</ul>
					</div>
			);
		}
		return null;
	};

	showAttachments = () => {
		this.setState( { showAttachmentsPreview: true } );
	};

	render() {
		let { users, post, isSearch, isUserProfile, perms } = this.props,
			userName = post.forumUserName ? `Guest (${ post.forumUserName })` : ``,
			user = post.forumUser ? users[post.forumUser] : { userName: userName || 'Guest', avatar: '' },
			postNum = post.isDeleted ? '_' : post.num || this.props.postNum,
			reducedView = isSearch || isUserProfile;

		// Parse bbCode
		let allowHtml = !reducedView && this.props.allowHtml;
		const reactComponents = rawToReact( ( reducedView || post.isDeleted ? post.preview : post.message ), this.props, allowHtml );

		// If we have a title show it
		const title = isUserProfile ?
			<div className="post_title">
				<span className="post_title_title">{post.threadStarter ? 'Started:' : 'Replied to:'}</span>
				<em>{rawToReact( this.props.threadTitle )}</em>
			</div> :
			( post.title ? <div className="post_title">{rawToReact( post.title )}</div> : '' );

		const permissions = perms.canseeattachments ? '' : <span className="note">not allowed to see attachments</span> ;

		// If the post has attachments show them, unless we are in a search
		const attachmentList = post.attachments && post.attachments.length > 0 ? (
			reducedView || !perms.canseeattachments ?
				<div className="postFooter inSearch">
					<ul>
						<li className="attachmentItem">{ post.attachments.length } <SvgIcon icon="clip" size="16px"/> { permissions }</li>
					</ul>
				</div>
				:
				( this.state.showAttachmentsPreview ?
					<PhotoSwipeGallery postId={post.id} attachments={this._getVisibleAttachments} thumbnailContent={this._getThumbnailContent}/> :
					<div className="postFooter inSearch">
						<div className="title">Attachments</div>
						<ul>
							<li className="attachmentItem">{ post.attachments.length } <SvgIcon icon="clip" size="16px"/>
								<div className="action-buttons">
									<button className="action-button" onClick={ this.showAttachments }>
										Show { post.attachments.length > 1 ? `these` : `this` } thumbnail{ post.attachments.length > 1 ? `s` : `` }
									</button>
								</div>
							</li>
						</ul>
					</div>
				)
			) : <span />;

		// phpbb shows whether a post has been reported
		const isReported = post.isReported && parseInt( post.isReported ) === 1 ?
			<div className="thread-reported" >
				<SvgIcon size="20px" icon="error_outline"/>
			</div> : null;


		let itemSelected = this.props.isSelected ? ' selected-item' : '',
			itemDeleted = post.isDeleted ? ' deleted' : '',
			itemModerated = post.isModerated ? ' moderated' : '',
			itemReported = isReported ? ' reported' : '';

		let checkBox = this.props.isUserLoggedin && !reducedView ?
			<CheckboxComponent
				name={ `postSelect_${post.topic}` }
				id={ `postSelected_${post.id}` }
				value={ post.id }
				checked={ this.props.isSelected }
				multiple={ true }
				className={ 'inPost' }
				onChange={ this.selectPost }
			/> : null;

		let editNote = this._getEditNote(),
			className = `post${ itemModerated }${ itemDeleted }${ itemSelected }${ itemReported }`;

		return (
			<li
				id={`post_container_${ post.id }`}
				className="post_container"
				data-postid={ reducedView ? post.id : '' }
				onClick={ this.props.onClick }
			>
				<div className={ className } >
					<div className="left">
						<UserIcon user={ user } />
					</div>
					<div className="right">
						<div className="time">
							<DateComponent timestamp={ post.dateline } /> &middot; #{ postNum }
							{ checkBox }
							{ isReported }
						</div>
						{ title }
						<div className="chevron" />
						<div className="body" id={ `post_message_${post.id}` }>
							{ reactComponents }
							<span>{ ( reducedView ? ' [...]' : '' ) }</span>
						</div>
						{ attachmentList }
						{ editNote }
					</div>
				</div>
			</li>
		);
	}
}

PostComponent.propTypes = {
	isSearch: PropTypes.bool.isRequired,
	isUserProfile: PropTypes.bool.isRequired,
	postId: PropTypes.oneOfType( [
		PropTypes.string,
		PropTypes.number
	] ).isRequired,
	postNum: PropTypes.number.isRequired,
	edited: PropTypes.number.isRequired,
	post: PropTypes.object.isRequired,
	users: PropTypes.object.isRequired,
	perms:PropTypes.object.isRequired,
	allowHtml: PropTypes.bool.isRequired,
	threadTitle: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
	return {
		items: state.photogallery.items,
		userSettings: state.ui.userSettings,
		isSelected: !!( state.meta.selectedElements[ props.postId ] ),
		isUserLoggedin: !!( state.ustate.id )
	}
};

export default connect( mapStateToProps )( PostComponent );

