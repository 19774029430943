import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import throttle from 'lodash/throttle';

import { fireBlockthrough, loadSecondaryAds, trackGAEvent } from 'Actions/adsActions';

import adSelector from 'Selectors/adSelector';

import AFSAdComponent from 'Components/ads/afsAdComponent';
import BlockthroughAdComponent from 'Components/ads/blockthroughAdComponent';
import InContentAdComponent from 'Components/ads/inContentAdComponent';
import SecondaryAdComponent from 'Components/ads/secondaryAdComponent';

import * as Swipe from 'Utils/swipe';

const refreshInterval = 45 * 1000, // 45 seconds
	lazyLoadMarginPercent = 100; // 1 viewport
let prebid_slot_used = {};

/**
 * Component to render Ads
 * We can pass the following props:
 *  - adType int *[mandatory] indicates what ad we want to render. see bellow for types
 *  - adName string *[mandatory] is the name of the ad and the id of the container div
 *  - search object *[mandatory for AFS] for AFS we need to pass some info about the ad (is top, query, page)
 *  - fallbackAdType array [optional] in case the adType is not defined we'll check for any of these adTypes
 *  - className string [optional] className by type are automatically added but if we need to include any extra we can use this prop
 *  - tag string [optional] if we want the ad to wrapped on a tag
 *  - isFetching bool [optional] if refresh depends upon we are not fetching data at the moment, like Bottom_Content_Rectangle
 *
 * Ads active for a site are defined in: topifyPresets.forumSite.dfpTags
 * adTypes will match those on topifyPresets but adType=0 which is AFS and doesn't need Ad Unit
 *
 * Ad Types in app can have the following types:
 * - 0 - 'AFS': AdSense For Search
 * - 1 - 'InContentLeaderboard': 300x250 (previously 320x50) ad inserted every # threads or posts
 * - 2 - 'InContentBottomRectangle': 300x250 at the bottom of some pages
 * - 3 - 'FixedBottomLeaderboard': 320x50 ad fixed at the bottom of the viewport and always visible
 * - 4 - 'Insterstitial': Interstitial triggered once after 2 pageviews
 * - 5 - 'InContentTopicAd' - Yieldmo: ad inserted in index page between categories and at the top of threads (usually fallbackAdType = [1])
 * - 6 - 'beaconLastPost': beacon triggered on some sites
 * - 9 - 'HomePageVendorAd': Local Vendor Ad: similar to 208 but in index page, if present we don't run 5
 * - 50 - 'PortalInsurance1': Insurance ad on portal page
 * - 51 - 'PortalInsurance2': Insurance ad on portal page
 * - 52 - 'PortalInsurance3': Insurance ad on portal page
 * - 53 - 'PortalInsurance4': Insurance ad on portal page
 * - 54 - 'PortalInsurance5': Insurance ad on portal page
 * - 101 - 'InContentHomePage': Insurance fake forum in index page
 * - 105 - 'InContentTopicAd_1' - ShareThrough: every # posts (usually fallbackAdType = [1])
 * - 108 - 'InContentTopicVendorCampaign' - Sharethrough Video Campaigns: Top of threads and posts
 * - 205 - 'InContentTopicAd_2' - Yieldmo Hyperscroll/Outstream: every # posts (usually fallbackAdType = [1])
 * - 208 - 'InContentTopicVendorAd'- Local Vendor Ad: after 4th post or last if less than 4
 */
class AdComponent extends React.Component {
	constructor( props ) {
		super( props );

		this.visibilityObserver = null;
		this.yieldmoWrapperIn = null;
		this.ym_wrapper_meter = null;

		this.theAdElement = null;
		this.refreshTimer = null;

		this.adUnit = null;
		this.ad_slot = {};
		this.apstagSlots = [];

		this.propsChanged = false;
		this.lastRefreshTime = 0;
		this.refreshCount = 0;

		this.state = {
			adTypes: [ this.props.adType, ...(this.props.fallbackAdType ? this.props.fallbackAdType : []) ].map( Number ),
			adType: parseInt( this.props.adType ),
			ready: false,
			inViewport: false,
			hidden: false,
			hiddenByUser: false,
			adFreeze: false,
			privacySettings: {
				cookiesOk: this.props.ads.GDPRConsent.cookiesOk,
				trackUserOk: this.props.ads.GDPRConsent.trackUserOk
			},
			resetHiddenAds: null,
			documentVisible: true,
			overlayElements: { ...this.props.overlayElements }
		};
	}

	static getDerivedStateFromProps( props, state ) {
		let newState = {};

		// show those ads hidden, being reset by perms
		if( props.ads.resetHiddenAds !== state.resetHiddenAds ) {
			newState = { ...newState, resetHiddenAds: props.ads.resetHiddenAds, hidden: false, hiddenByUser: false }
		}

		// We want to freeze ads refreshing when the document is out of focus, we have open any overlay or we didn't get a cookie consent
		if(
			props.ads.GDPRConsent.cookiesOk !== state.privacySettings.cookiesOk
			|| props.ads.GDPRConsent.trackUserOk !== state.privacySettings.trackUserOk
			|| props.ads.documentVisible !== state.documentVisible
			|| Object.keys( props.overlayElements ).some( k => props.overlayElements[ k ] !== state.overlayElements[ k ] )
		) {
			newState = {
				...newState,
				overlayElements: { ...props.overlayElements },
				documentVisible: props.ads.documentVisible,
				adFreeze: Object.values( props.overlayElements ).some( v => v ) || !props.ads.documentVisible || ( props.topifyPresets.GDPR && !props.ads.GDPRConsent.cookiesOk ),
				privacySettings: { ...props.ads.GDPRConsent }
			}
		}

		// do we need to update the state?
		return Object.keys( newState ).length ? newState : null;
	}

	componentDidMount() {
		topifyConsole.log( 'Mounted ad:', this.state.adType, this.props.adName );

		// get ad Unit: AFS or DFP
		this.adUnit = this.getAdUnit();

		if( this.adUnit ) {
			this.setState( { adType: parseInt( this.adUnit.adType ), ready: true } );
		}
	}

	componentWillUnmount() {
		this._disconnectYieldmoObserver();
		this._disconnectVisibilityObserver();

		// if we allowed to swipe to hide, remove events
		if( this._swipeToHide() && this.theAdElement ) {
			Swipe.kill( this.theAdElement );
		}

		// Remove the ad unit
		this.removeAdUnit();
	}

	shouldComponentUpdate( nextProps, nextState ) {
		this.propsChanged = (
			nextState.ready !== this.state.ready ||
			nextState.hidden !== this.state.hidden ||
			nextState.inViewport !== this.state.inViewport ||
			nextState.hiddenByUser !== this.state.hiddenByUser ||
			nextProps.ads.noAdsPermissions !== this.props.ads.noAdsPermissions ||
			( this._isVendorAd() && nextProps.ads.vendorAds !== this.props.ads.vendorAds ) ||
			nextProps.ads.secondaryAds !== this.props.ads.secondaryAds ||
			nextProps.isFetching !== this.props.isFetching
		);

		if( this.propsChanged ) {
			this.lastRefreshTime = 0;
		}

		return (
			this.propsChanged
			|| nextState.adFreeze !== this.state.adFreeze
		);
	}

	componentDidUpdate( prevProps, prevState ) {
		// If we are ready initiate observers
		if(
			prevState.ready !== this.state.ready
			|| prevState.adType !== this.state.adType
		) {
			this._initObservers();
		}

		if( this.propsChanged ) {
			this._triggerAdPositionChange();
		}

		// If the ad is freeze, stop here
		if( this.state.adFreeze ) {
			return;

		} else if(
			prevState.adFreeze !== this.state.adFreeze &&
			( this.ad_slot.getSlotElementId || this.props.ads.secondaryAds ) &&
			this._skipAutoRefresh() === false
		) {
			// Else If the ad is not freeze anymore ( we have closed the overlays or the document is on focus again )
			// and the ad_slot exists and it's a refreshable ad
			// then re-start refreshing
			this.lastRefreshTime = 0;
			this.scheduleRefresh( 500 );

		} else if(
			this.state.ready &&
			this.state.inViewport &&
			!this.state.hidden
		) {
			// if the ad is ready and is in viewport and is not hidden, display it
			this.addPrebidAdUnit();
			topifyConsole.log( `adType:${ this.state.adType } - ${ this.props.adName } - `, ( this._isPrebid() ? ( pbjs.adUnits || [] ) : `no prebid` ) );
			this.displayAdUnit();

		} else {
			this.removeAdUnit();
		}

		// do we allow to swipe to hide?
		if( this._swipeToHide() && this.theAdElement ) {
			Swipe.kill( this.theAdElement );
			Swipe.init( this.theAdElement, ( code ) => { this._swipedThenHide( code ) } );
		}

		let noShow = this._noShowAd();
		if( this.state.hidden !== noShow ) {
			this.setState( { hidden: noShow } );
		}
	}

	displayAdUnit() {
		// If we have a adBlocker preventing googletag from loading skip from here
		if( typeof googletag === 'undefined' ) { return false; }

		if( this.state.adType === 0 ) { // Search AFS
			this.displayAFS();
			return;
		}

		// We already have the ad and nothing have changed
		if( this.ad_slot && this.ad_slot.getSlotElementId && this.propsChanged === false ) {
			return;
		}

		// if we are temporary not showing ads, keep on it expecting a change
		if( this.props.ads.secondaryAds || this.state.hidden ) {
			this.scheduleRefresh();
			return;
		}

		googletag.cmd.push( () => {
			if( googletag.defineSlot && googletag.display ) {
				if( !this.ad_slot || Object.keys( this.ad_slot ).length <= 0 ) {
					let sizeAd = this._getAdSizes();

					if( this.state.adType === 4 ) {
						this.ad_slot = googletag.defineSlot( this.adUnit.path, sizeAd, this.props.adName ).setCollapseEmptyDiv( true, true ).addService( googletag.pubads() );
					} else {
						// If we have Adomik loaded, set target
						if( window.Adomik && Adomik.randomAdGroup ) {
							this.ad_slot = googletag.defineSlot( this.adUnit.path, sizeAd, this.props.adName ).setTargeting( 'ad_group', Adomik.randomAdGroup() ).setTargeting( 'ad_h', ( new Date ).getUTCHours().toString() ).setCollapseEmptyDiv( !this._isFluid() ).addService( googletag.pubads() );
						} else {
							this.ad_slot = googletag.defineSlot( this.adUnit.path, sizeAd, this.props.adName ).setCollapseEmptyDiv( !this._isFluid() ).addService( googletag.pubads() );
						}
					}

					if( window.deployads_srt && window.deployads ) { // sortable enable
						deployads.push( () => deployads.gpt.display( this.props.adName ) );
					} else {
						googletag.display( this.props.adName );
					}
				}

				this.loadAdUnit();
			}
		} );
		topifyConsole.log( 'definedAdSlot ', this.state.adType, this.props.adName );
	}

	displayAFS() {
		if( !this.state.hidden && this.state.inViewport ) {
			this.adUnit.pageOptions.adPage = this.props.search.page; // page
			this.adUnit.pageOptions.query = this.props.search.searchQuery; // search query

			// if we have all the values we need trigger the ads
			if(
				typeof _googCsa === 'function' &&
				this.adUnit.pageOptions.pubId &&
				this.adUnit.pageOptions.channel &&
				this.adUnit.pageOptions.query
			) {
				_googCsa( 'ads', this.adUnit.pageOptions, this.adUnit.adblock );
			}
		}
	}

	/**
	 * Schedules an ad refresh
	 *
	 * @param extraTime if we want to add/remove an extra time
	 */
	scheduleRefresh( extraTime = 0 ) {
		if( this.refreshTimer ) {
			clearTimeout( this.refreshTimer );
			this.refreshTimer = undefined;
		}
		this.refreshTimer = setTimeout( () => {
			this.loadAdUnit();
		}, ( 1000 + extraTime ) );
	}

	loadAdUnit() {
		const _tNow = Date.now();

		// The DOM is not there
		if( this.theAdElement === null ) {
			return;
		}

		// Do we skip refresh...?
		// only skip if needed on refreshable ads
		if( this.state.adFreeze && this._skipAutoRefresh() === false ) {
			topifyConsole.log( 'Ad not visible, skip refresh:', this.state.adType, this.props.adName );
			return;
		}

		// If the adSlot wasn't generated when we need it
		if( !( this.ad_slot || this.ad_slot.getSlotElementId ) && !this.props.ads.secondaryAds ) {
			this.displayAdUnit();
			return;
		}

		// only refresh at most every refreshInterval
		if( ( _tNow - refreshInterval ) < this.lastRefreshTime && this._skipAutoRefresh() === false ) {
			this.scheduleRefresh();
			return;
		}

		// in some cases we don't want to reload ads
		if(
			this.state.adType === 0 || // AFS
			this.state.hidden === true ||
			this.state.inViewport === false
		) {
			return;
		}

		this.lastRefreshTime = 0;

		// Refresh ads
		if( this.props.ads.secondaryAds ) { // If we use secondary ads... Rubicon standalone
			this._refreshSecondary( _tNow );

		} else if( this._isPrebid() && pbjs.libLoaded ) { // If it's prebid and prebid loaded
			this._refreshPrebid( _tNow );

		} else { // If prebid is not ready yet or it's not present we load ads without it
			this._refreshGoogleTag( _tNow );
		}

		fireBlockthrough();
	}

	// Prebid with A9 integrated refresh
	_refreshPrebid( timestamp ) {
		// If we have a adBlocker preventing googletag from loading skip from here
		if( typeof googletag === 'undefined' ) { return false; }

		let skipRefresh = this._skipAutoRefresh();

		this.theAdElement.classList.remove( 'hidden' );
		topifyConsole.info( 'A9 and/or prebid - ', this.state.adType, this.props.adName );

		// if we have come back from no ads space, we can have an ad defined, display it
		if( !document.getElementById( this.ad_slot.getSlotElementId() ).getAttribute( 'data-google-query-id' ) ) {
			googletag.display( this.ad_slot.getSlotElementId() );
		}

		if( skipRefresh === false ) {
			// just in case, we'll refresh after the refresh time
			this.scheduleRefresh( refreshInterval );
		}

		// Start bidder manager
		// is A9 loaded? then use it
		let bidders = typeof apstag !== 'undefined' ? [ 'a9', 'prebid' ] : [ 'prebid' ];

		// create a requestManager to keep track of bidder state to
		// determine when to send ad server request
		let requestManager = {
			adserverRequestSent: false,
			apstagSlots: this.apstagSlots,
			adUnits: prebid_adUnits
		};

		//loop through bidder array and add the bidders to the request manager:
		bidders.forEach( bidder => { requestManager[ bidder ] = false; } );

		// handler for header bidder responses
		let headerBidderBack = ( bidder ) => {
			// return early if request to adserver is already sent
			if( requestManager.adserverRequestSent === true ) {
				return;
			}

			// set the bidder targeting and flip bidder back flag
			requestManager[ bidder ] = true;

			// if all bidders are back, send the request to the ad server
			if( bidders.map( bidder => requestManager[ bidder ] ).filter( Boolean ).length === bidders.length ) {
				sendAdserverRequest();
			}
		};

		// actually get ads from DFP
		let sendAdserverRequest = () => {
			// return early if request already sent
			if( requestManager.adserverRequestSent === true ) {
				return;
			}
			// flip the boolean that keeps track of whether the adserver request was sent
			requestManager.adserverRequestSent = true;

			// just in case we'll refresh after the refresh time
			this.scheduleRefresh( refreshInterval );

			if( !( this.ad_slot && this.ad_slot.getSlotElementId ) ) {
				topifyConsole.info( "Abort Prebid/A9 refresh, slot doesn't exist", this.props.adName, this.ad_slot );
				this.lastRefreshTime = skipRefresh ? 0 : timestamp - 10000;
				return;
			}

			// Clear previous slot content if any
			googletag.cmd.push( () => {
				if( googletag.pubads().clear ) {
					googletag.pubads().clear( [ this.ad_slot ] );
				}
			} );

			// make ad request to DFP
			googletag.cmd.push( () => {
				try {
					if( typeof apstag !== 'undefined' ) {
						apstag.setDisplayBids();
					}
					pbjs.que.push( () => { pbjs.setTargetingForGPTAsync( [ this.ad_slot.getSlotElementId() ] ) } );

					this.lastRefreshTime = skipRefresh ? 0 : timestamp - 1000;
					if( skipRefresh === false ) {
						// Let's trigger the refresh
						this.scheduleRefresh( refreshInterval );
					}

					googletag.pubads().refresh( [ this.ad_slot ], { changeCorrelator: false } );

					this._triggerAdPositionChange();

				} catch( e ) {
					this.scheduleRefresh( refreshInterval );
					topifyConsole.error( 'Prebid exception thrown: ', e, this.props.adName, this.ad_slot );
				}
			} );
		};

		// ==== Request bids ==== //

		// fetch apstag bids, set bid targeting, then call headerBidderBack
		// to get the ads for the first time
		if( typeof apstag !== 'undefined' && bidders.indexOf( 'a9' ) !== -1 ) {
			apstag.fetchBids( {
				slots: this.apstagSlots,
				timeout: window.PREBID_TIMEOUT
			}, ( bids ) => {
				topifyConsole.info( 'A9 Bids', this.props.adName, bids[ 0 ] );
				headerBidderBack( 'a9' );
			} );
		}

		// request bids from prebid
		pbjs.que.push( () => {
			pbjs.requestBids( {
				timeout: window.PREBID_TIMEOUT,
				adUnitCodes: [ this.ad_slot.getSlotElementId() ],
				bidsBackHandler: ( bidResponses ) => {
					topifyConsole.info( 'prebid Bids', this.props.adName, bidResponses );
					headerBidderBack( 'prebid' );
				}
			} );
		} );

		// set timeout to send request to call sendAdserverRequest() after timeout
		// if all bidders haven't returned before then
		window.setTimeout( function() { sendAdserverRequest(); }, window.PREBID_TIMEOUT );
	};

	// Loads Secondary ads
	_refreshSecondary( timestamp ) {
		let type = 'rubicon',
			secAdsProps = {
				size: this.adUnit.size,
				container: `SecondaryAd_${ this.props.adName }`
			};
		topifyConsole.info( "Secondary Ads", type, secAdsProps );
		let callBack = () => {
				topifyConsole.log( 'Secondary Ad loaded!! (Rubicon)', this.props.adName );
				this.theAdElement.classList.remove( 'hidden' );
				this.lastRefreshTime = timestamp - 1000;

				this._triggerAdPositionChange();
			},
			onError = () => {
				topifyConsole.warn( 'Secondary Ad failed!! (Rubicon)', this.props.adName );
				this.theAdElement.classList.add( 'hidden' );
				this.removeAdUnit();
			};

		this.props.dispatch( loadSecondaryAds( type, secAdsProps, callBack, onError ) );

		if( this._skipAutoRefresh() === false ) {
			// leave 2 sec before regular refresh
			this.scheduleRefresh( refreshInterval - 2000 );
		}
	}

	// GoogleTag refresh
	_refreshGoogleTag( timestamp ) {
		// If we have a adBlocker preventing googletag from loading skip from here
		if( typeof googletag === 'undefined' ) { return false; }

		topifyConsole.info( 'using googletag', this.ad_slot.getSlotElementId() );
		this.theAdElement.classList.remove( 'hidden' );
		googletag.cmd.push( () => {
			if( googletag.pubads().clear ) {
				googletag.pubads().clear( [ this.ad_slot ] );
			}
		} );

		if( !document.getElementById( this.ad_slot.getSlotElementId() ).getAttribute( 'data-google-query-id' ) ) {
			googletag.cmd.push( () => {
				if( window.deployads_srt && window.deployads ) { // sortable enable
					deployads.push( () => deployads.gpt.display( this.ad_slot.getSlotElementId() ) );
				} else {
					googletag.display( this.ad_slot.getSlotElementId() );
				}
			} );
		}

		googletag.cmd.push( () => {
			this.lastRefreshTime = timestamp - 1000;
			if( this._skipAutoRefresh() === false ) {
				this.scheduleRefresh( refreshInterval );
			}

			if( window.deployads_srt && window.deployads ) { // sortable enable
				deployads.push( () => deployads.gpt.pubadsRefresh( [ this.ad_slot ], { changeCorrelator: false } ) );
			} else {
				googletag.pubads().refresh( [ this.ad_slot ], { changeCorrelator: false } );
			}

			this.refreshCount++;

			this._triggerAdPositionChange();
		} );
	}

	addPrebidAdUnit() {
		// Skip if we are in search or it doesn't need prebid
		if(
			this.props.ads.secondaryAds
			|| this.state.adType === 0
			|| this.state.hidden
			|| !this.state.inViewport
			|| !this.state.ready
			|| !this._isPrebid()
			|| !this.adUnit.size
		) {
			return;
		}

		// already used?
		if( Object.keys( prebid_slot_used ).some( u_size => prebid_slot_used[ u_size ] === this.props.adName ) ) {
			topifyConsole.warn( 'prebid slot already used', this.state.adType, this.props.adName );
			return;
		}

		let size = new RegExp( this.adUnit.size[ 0 ] + 'x' + this.adUnit.size[ 1 ] + '_', 'ig' );

		// In some ad units we need to choose one prebid compatible size
		// 208 -> if it's not last we'll need a valid size for bidders
		switch( this.state.adType ) {
			// 208: 'InContentTopicVendorAd'
			case 208:
				let adSize = this._defaultAdUnitSize( this.state.adType );
				size = new RegExp( adSize[ 0 ] + 'x' + adSize[ 1 ] + '_', 'ig' );
				break;
		}

		let u = prebid_adUnits_def.find( ( the_ad ) => ( the_ad.code.match( size ) && typeof prebid_slot_used[ the_ad.code ] === 'undefined' ) ),
			unit = u && u.code ? JSON.parse( JSON.stringify( u ) ) : {};

		if( unit.code ) {
			unit.Topifytype = unit.code + '';
			unit.code = this.props.adName;

			// create a9 ad unit
			let a9Unit = {
				slotID: this.props.adName,
				slotName: this.adUnit.path,
				sizes: unit.sizes,
				Topifytype: unit.Topifytype
			};

			let currentAdUnits = pbjs.adUnits || [];

			// prebid
			if( currentAdUnits.findIndex( ad => ad.code === this.props.adName ) < 0 ) {
				pbjs.que.push( () => {
					if( pbjs.addAdUnits ) {
						pbjs.addAdUnits( unit );
					}
				} );
				// adding a9 ad unit
				if( this.apstagSlots.length <= 0 || this.apstagSlots.findIndex( ad => ad.slotID === this.props.adName ) < 0 ) {
					this.apstagSlots.push( a9Unit );
				}
				// Keep track of adUnits used
				if( prebid_adUnits.findIndex( ad => ad.code === this.props.adName ) < 0 ) {
					prebid_adUnits.push( unit );

					// Tracking which adUnits are being used
					prebid_slot_used[ unit.Topifytype ] = this.props.adName;
					topifyConsole.log( 'prebid_slot_used: ', prebid_slot_used );
					topifyConsole.log( 'a9: ', this.apstagSlots );
				}
				topifyConsole.log( 'added AdUnit prebid', this.state.adType, this.props.adName );
			}
		}
	}

	removeAdUnit() {
		clearTimeout( this.refreshTimer );
		this.refreshTimer = undefined;

		// remove ad slots
		let adName = this.props.adName;
		topifyConsole.log( 'removeAdUnit ', this.state.adType, this.props.adName );
		if( typeof googletag !== 'undefined' && this.ad_slot && Object.keys( this.ad_slot ).length !== 0 ) {
			googletag.cmd.push( () => {
				if( window.deployads_srt && window.deployads ) {
					deployads.push( () => deployads.gpt.destroySlots( [ this.ad_slot ] ) );
				} else {
					googletag.destroySlots( [ this.ad_slot ] );
				}
			} );
			this.ad_slot = {};
		}

		if( this._isPrebid() ) {
			// Delete from prebis adUnits
			let adIndex = prebid_adUnits.findIndex( ( ad ) => ad.code === adName );

			if( adIndex >= 0 ) {
				// Remove adUnits from tracking
				if( prebid_adUnits[ adIndex ] && prebid_adUnits[ adIndex ].Topifytype ) {
					delete prebid_slot_used[ prebid_adUnits[ adIndex ].Topifytype ];
				}

				// Remove adUnits from prebid adUnits
				if( pbjs.removeAdUnit ) {
					pbjs.removeAdUnit( adName );
				}
				prebid_adUnits.splice( adIndex, 1 );
			}

			// Delete a9
			this.apstagSlots = [];
		}
	}

	getAdUnit() {
		if( this.state.adType === 0 ) {
			return this._getAFSAdUnit();
		}

		let ad_unit = this._find_adType( this.props.topifyPresets.forumSite.dfpTags, 0 );
		if( ad_unit ) {
			return this._parseAd( ad_unit );
		}

		return null;
	}

	_getAFSAdUnit() {
		const forumSite = this.props.topifyPresets.forumSite;

		// AFS - page options base values
		let adUnit = {
			adType: 0,
			pageOptions: {
				pubId: forumSite.f_afs_publisherid,
				channel: forumSite.f_afs_channelid,
				personalizedAds: this.props.topifyPresets.GDPR ? this.props.ads.GDPRConsent.trackUserOk : true,
				adsafe: "medium",
				query: '',
				hl: 'en',
				adtest: ( process.env.NODE_ENV === 'production' ? 'off' : 'on' ),
				adPage: 1,
				location: false,
				siteLinks: false
			},
			// AFS - adblock options base values
			adblock: {
				container: this.props.adName,
				detailedAttribution: false,
				// style
				width: ( ( window.innerWidth || document.documentElement.clientWidth ) - ( this.props.search.showposts ? 0 : 20 ) ) + 'px',
				fontFamily: 'helvetica,Helvetica Neue,sans-serif',
				fontSizeTitle: 16,
				titleBold: true,
				noTitleUnderline: true,
				verticalSpacing: 10,
				fontSizeAttribution: 15,
				fontSizeAnnotation: 15,
				fontSizeDescription: 15,
				fontSizeDomainLink: 15,
				colorBackground: ( this.props.styleTheme === 'dark' ? '#404040' : '#FFFFFF' ),
				colorAdSeparator: ( this.props.styleTheme === 'dark' ? '#222222' : '#AAAAAA' ),
				colorText: ( this.props.styleTheme === 'dark' ? '#d8d8d8' : '#555555' ),
				colorAnnotation: ( this.props.styleTheme === 'dark' ? '#d8d8d8' : '#555555' ),
				colorTitleLink: ( this.props.styleTheme === 'dark' ? '#d8d8d8' : '#555555' ),
				colorDomainLink: ( this.props.styleTheme === 'dark' ? '#f70' : '#0cbbf0' ),
				adBorderSelections: 'left, right',
				colorAdBorder: ( this.props.styleTheme === 'dark' ? '#404040' : '#FFFFFF' )
			}
		};

		// Other settings
		if( this.props.search.isTop === true ) {
			delete adUnit.adblock.number;
			adUnit.adblock.maxTop = 2;
		} else {
			delete adUnit.adblock.maxTop;
			adUnit.adblock.number = 2;
		}

		return adUnit;
	}

	_find_adType( units, i ) {
		let ad_unit,
			adType = this.state.adTypes[ i ],
			noVendorAd = !( this._isVendorAd( adType ) && this.props.ads.vendorAds === false );

		if( noVendorAd && units.length > 0 ) {
			ad_unit = units.find( ad => parseInt( ad.at_id ) === adType );
		}

		if( ad_unit ) {
			ad_unit.fda_path = ad_unit.fda_path.trim();
		} else if( i < this.state.adTypes.length - 1 ) {
			return this._find_adType( units, ( i + 1 ) );
		}

		return ad_unit;
	}

	_parseAd = ( ad_unit ) => {
		let re = /(?:\/(\d+)\/)?(?:([_a-zA-Z0-9]+)_)?((?:\d+x\d+)|(?:fluid))(?:_([_a-zA-Z0-9]+))?/ig;

		let _adPath = re.exec( ad_unit.fda_path ) || [];
		_adPath[ 'path' ] = ad_unit.fda_path;
		_adPath[ 'adType' ] = ad_unit.at_id;
		_adPath[ 'btId' ] = ad_unit.btid || null;

		// Formatting sizes
		if( _adPath[ 3 ] ) {
			let size = _adPath[ 3 ].toLowerCase().split( 'x' );
			if( ( parseInt( size[ 0 ] ) && parseInt( size[ 1 ] ) ) || size[ 0 ] === 'fluid' ) {
				_adPath[ 'size' ] = size[ 0 ] === 'fluid' ? [ size[ 0 ] ] : [ parseInt( size[ 0 ] ), parseInt( size[ 1 ] ) ];
			}
		} else {
			_adPath[ 'size' ] = this._defaultAdUnitSize( ad_unit.at_id );
		}

		return _adPath;
	};

	_defaultAdUnitSize = ( type ) => {
		switch( parseInt( type ) ) {
			case 1: // 'inContentLeaderboard'
			case 3: // 'bottomFixedLeaderboard'
			case 205: // 'inContentTopicAd_2' - yieldmo Hyperscroll/Outstream
				return [ 320, 50 ];

			case 2: //'endContentRectangle'
				return [ 300, 250 ];

			case 4: // 'interstitial'
			case 101: // 'InContentHomePage'
			case 108: // 'InContentTopicVendorCampaign' - ShareThrough
				return [ 1, 1 ];

			case 5: // 'inContentTopicAd'
			case 105: // 'inContentTopicAd_1'
				return [ 320, 150 ];

			case 9: //  'HomePageVendorAd'
			case 208: // 'InContentTopicVendorAd'
				return [ 300, 120 ];

			default:
				return [ 320, 50 ];
		}
	};

	_getAdComponent() {
		if( this.props.ads.secondaryAds ) {
			switch( this.state.adType ) {
				case 1: // 'inContentLeaderboard'
				case 2: // 'endContentRectangle'
				case 3: // 'bottomFixedLeaderboard'
				case 5: // 'inContentTopicAd'
				case 105: // 'inContentTopicAd_1'
				case 205: // 'inContentTopicAd_2'
					return <SecondaryAdComponent key={ this.props.adName } adName={ this.props.adName } type="rubicon" />;

				default:
					return null
			}
		} else {
			switch( this.state.adType ) {
				case 0: // 'AFS adsense for search'
					return <AFSAdComponent key={ this.props.adName } adName={ this.props.adName } />;

				case 1: // 'inContentLeaderboard'
				case 2: // 'endContentRectangle'
				case 3: // 'bottomFixedLeaderboard'
				case 5: // 'inContentTopicAd'
				case 4: // 'interstitialAd'
				case 9: //  'HomePageVendorAd'
				case 101: // 'InContentHomePage'
				case 105: // 'inContentTopicAd_1' - ShareThrough
				case 108: // 'InContentTopicVendorCampaign' - ShareThrough
				case 205: // 'inContentTopicAd_2' - yieldmo Hyperscroll/Outstream
				case 208: // 'InContentTopicVendorAd'
					return <InContentAdComponent key={ this.props.adName } adName={ this.props.adName } />;

				default:
					return null
			}
		}
	}

	_getAdComponentClasses() {
		let className = 'ad-card';

		className += this.props.ads.secondaryAds ? ' secondary-ad-container ' : '';

		switch( this.state.adType ) {
			case 0: // AFS
				className += ' search-ad' + ( this.props.search.isTop === true ? ' search-top-ad' : '' ) + ( this.props.search.showposts ? ' in-posts post' : '' );
				break;

			case 1: // 'inContentLeaderboard'
				className += ' in-content-ad in-content-leaderboard-ad';
				if( this.props.inPosts ) {
					className += ' in-content-ad-inrow';
				}
				break;

			case 2: //'endContentRectangle'
				className += ' bottom-ad';
				break;

			case 3: //'bottomFixedLeaderboard'
				className += '';
				break;

			case 4: // 'interstitialAd'
				className += ' interstitial-ad';
				break;

			case 5: // 'inContentTopicAd'
			case 105: // 'inContentTopicAd_1' - ShareThrough
			case 205: // 'inContentTopicAd_2' - yieldmo Hyperscroll/Outstream
				className += ' in-content-ad';
				if( this.props.inPosts ) {
					className += ' in-content-ad-inrow';
				}
				break;

			case 9: //  'HomePageVendorAd'
				className += ' in-content-ad vendor-ad';
				break;

			case 101: // 'InContentHomePage'
				className += ' homepage-in-content-ad';
				break;

			case 108: //'InContentTopicVendorCampaign' - ShareThrough
				className += ' in-content-ad';
				break;

			case 208: // 'InContentTopicVendorAd'
				className += ' in-content-ad in-content-ad-inrow vendor-ad';
				break;

			default:
				className += '';
				break;
		}

		return className + ( this.props.className ? ' ' + this.props.className : '' ) + ( this.state.hidden ? ' hidden ' : '');
	}

	_getBtTag() {
		return <BlockthroughAdComponent adName={ this.props.adName } btId={ this.adUnit.btId } />;
	}

	_getAdSizes() {
		switch( true ) {
			case this.state.adType === 205:
				return [ [ 320, 50 ], [ 320, 186 ], [ 300, 250 ] ];

			case this._isYieldmo():
				return [ [ 320, 50 ], [ 320, 186 ], [ 320, 150 ], [ 300, 250 ], 'fluid' ];

			case this.state.adType === 105:
				return [ [ 1, 1 ], [ 300, 250 ], 'fluid' ];

			case this._isFluid():
				return [ this.adUnit.size, 'fluid' ];

			default:
				return this.adUnit.size;
		}
	}

	_isFluid() {
		return (
			this.state.adType === 2 || // 'endContentRectangle'
			this.state.adType === 5 || // 'inContentTopicAd' - yieldmo
			this.state.adType === 9 || //  'HomePageVendorAd'
			this.state.adType === 101 || // 'InContentHomePage'
			this.state.adType === 105 || // 'inContentTopicAd_1' - ShareThrough
			this.state.adType === 108 || // 'InContentTopicVendorCampaign' - ShareThrough
			( this.state.adType >= 50 && this.state.adType <= 59 ) // Insurance ads for portal page
		);
	}

	/**
	 * Initiates Observers - yieldmo wrapper and visibility
	 *
	 * @private
	 */
	_initObservers() {
		// Clear old ones if exist, just in case
		this._disconnectYieldmoObserver();
		this._disconnectVisibilityObserver();

		let inViewport = false;

		if( this._isAlwaysShown() ) {
			inViewport = true;

			// For yieldmo wrapper we need to play around their footer
			// to avoid our fixed bottom being hidden by them
			if( this.adUnit && this.state.adType === 3 && this.props.topifyPresets.forumSite.f_yieldmo ) {
				this._yieldmoWrapperInteration();
			}

		} else {
			const appContainer = document.getElementById( 'appContainer' );

			// Use IntersectionObserver if is available
			if( 'IntersectionObserver' in window ) {
				const options = {
					root: appContainer,
					rootMargin: `${ lazyLoadMarginPercent }% 0% ${ lazyLoadMarginPercent }% 0%`, // 1 viewport above and below
					threshold: 0 // just when it shows up
				};

				this.visibilityObserver = new IntersectionObserver( this._checkAdVisibility, options );

				if( this.visibilityObserver && this.theAdElement ) {
					// Are we using the polyfill?
					if( 'POLL_INTERVAL' in IntersectionObserver.prototype ) {
						topifyConsole.info( 'IntersectionObserver polyfill in use' );
						this.visibilityObserver.POLL_INTERVAL = 100; // Time in milliseconds.
					}
					this.visibilityObserver.observe( this.theAdElement );
				}

			} else {
				// others just show or hide per scroll position
				appContainer.addEventListener( "scroll", this.handleScroll, { passive: true } );
				this._checkAdVisibility();
			}
		}

		if( this.state.inViewport !== inViewport ) {
			this.setState( { inViewport: inViewport } );
		}
	}

	handleScroll = throttle( () => this._checkAdVisibility(), 200 );

	/**
	 * Prepares the ad to interact with the yieldmo wrapper
	 * @private
	 */
	_yieldmoWrapperInteration() {
		// Mutation observer to catch when the wrapper gets in
		this.yieldmoWrapperIn = new MutationObserver( ( mutations ) => {
			// For performance, skip any path we don't need
			let allowed = this.props.router.location && this.props.router.location.pathname ?
					/^\/(?:$|forums|topics)/i.test( this.props.router.location.pathname )
					: false;

			if( allowed !== true ) {
				return false;
			}

			mutations.forEach( ( mutation ) => {
				[ ...mutation.addedNodes ].forEach( node => {
					if( node && node.classList && node.classList.contains( 'ym-wrapper-meter' ) ) {
						// Save it and trigger the IntersectionObserver
						this.ym_wrapper_meter = node;
						this._observeYieldmoWrapper();
					}
				} );
			} );
		});
		// Observe <body>
		this.yieldmoWrapperIn.observe( document.body, { childList: true } );
	}

	/**
	 * This method will observe the yieldmo wrapper and modify
	 * the bottom fixed ad styles to stay above their footer
	 *
	 * @private
	 */
	_observeYieldmoWrapper() {
		// only for the Bottom_Fixed_ad and when we have Yieldmo active
		if( this.state.adType !== 3 || !this.props.topifyPresets.forumSite.f_yieldmo ) {
			return;
		}

		if( this.visibilityObserver ) {
			this.visibilityObserver.disconnect();
		}

		if( this.ym_wrapper_meter && 'IntersectionObserver' in window ) {
			this.visibilityObserver = this.visibilityObserver || new IntersectionObserver( ( entry ) => {
				if( entry.length && entry[ 0 ].isIntersecting ) {
					// if we have the footer in the viewport,
					// change to position absolute and the right position our bottom ad
					this.theAdElement.style.position = 'absolute';
					this.theAdElement.style.top = ( this.ym_wrapper_meter.offsetTop - 54 ) + 'px';
					this._triggerAdPositionChange();
				} else {
					// clean styles otherwise
					this.theAdElement.style.position = null;
					this.theAdElement.style.top = null;
					this._triggerAdPositionChange();
				}
			} );

			// Are we using the polyfill?
			if( 'POLL_INTERVAL' in IntersectionObserver.prototype ) {
				topifyConsole.info( 'IntersectionObserver polyfill in use' );
				this.visibilityObserver.POLL_INTERVAL = 100; // Time in milliseconds.
			}

			this.visibilityObserver.observe( this.ym_wrapper_meter );
			topifyConsole.log( 'Yieldmo Wrapper Observer set' );
		}
	}

	/**
	 * disconnects yieldmo Wrapper observer if exists
	 *
	 * @private
	 */
	_disconnectYieldmoObserver() {
		if( this.yieldmoWrapperIn ) {
			this.yieldmoWrapperIn.disconnect();
		}
	}

	/**
	 * disconnects visibility observer or remove scroll event if exist either one
	 *
	 * @private
	 */
	_disconnectVisibilityObserver() {
		// disconnecting visibilityObserver
		if( this.visibilityObserver ) {
			this.visibilityObserver.disconnect();

		} else { // or the scroll event listener
			if(  this.handleScroll && this.handleScroll.cancel ) {
				this.handleScroll.cancel();
			}
			// removing scroll event
			document.getElementById( 'appContainer' ).removeEventListener( "scroll", this.handleScroll, { passive: true } );
		}
	}

	/**
	 * Trigger an event to indicate the ad could have changed its size
	 * @private
	 */
	_triggerAdPositionChange() {
		if( this.state.adType === 3 ) {
			document.dispatchEvent( new CustomEvent( 'adPositionChanged', { detail: { adName: this.props.adName } } ) );
		}
	}

	/**
	 * Checks whether the ad is in viewport or not
	 * and sets state
	 *
	 * @param entries
	 * @private
	 */
	_checkAdVisibility = ( entries ) => {
		// We are in a noAds subforum
		if( this._noShowAd() ) {
			return;
		}

		let entry = this.visibilityObserver && entries.length ? entries[ 0 ] : null;

		if( this._isAlwaysShown() || this._isPositionOk( entry ) ) {
			if( !this.state.inViewport ) {
				this.setState( { inViewport: true } );
			}

		} else if( this.state.inViewport === true ) {
			if( this.state.inViewport ) {
				this.setState( { inViewport: false } );
			}
			this.lastRefreshTime = 0;
		}
	};

	_isPositionOk( ad = null ) {
		let positionOk = false;

		// Bottom endContentRectangle will only load when the page is fully loaded
		if( this.state.adType === 2 && this.props.isFetching === true ) {
			return false;
		}

		if( ad ) { // Using IntersectionObserver
			positionOk = ( ad.isIntersecting && ad.intersectionRatio >= 0 );

		} else { // old method
			let rect = this.theAdElement ? this.theAdElement.getBoundingClientRect() : null,
				viewport = (window.innerHeight || document.documentElement.clientHeight);
			positionOk = rect && (
				rect.top >= 0 && ( rect.top - ( viewport * lazyLoadMarginPercent / 100 ) ) <= viewport ||
				rect.bottom <= 0 && ( rect.bottom + ( viewport * lazyLoadMarginPercent / 100 ) ) >= 0
			);
		}

		return positionOk;
	}

	_swipedThenHide() {
		this.props.dispatch( trackGAEvent( 1, 'swipeHide', 'userAction', 'ads' ) );
		this.setState( { hiddenByUser: true } );
	}

	/**
	 * Some ads must not auto-refresh
	 *
	 * @returns boolean
	 * @private
	 */
	_skipAutoRefresh() {
		return (
			( this.props.topifyPresets.forumSite.f_sortable_refresh && window.deployads_srt && window.deployads ) || // we want sortable refresh rather than topify's
			( !this.props.ads.secondaryAds && this._isYieldmo() ) || // inContentTopicAd && Yieldmo
			this.state.adType === 4 || // Interstitial
			this._isInsuranceAd() || // home page insurance ad or portal page insurance ads
			this._isSharethrough() || // sharethrough
			this._isVendorAd() // Vendor ad
		);
	}

	/**
	 * Vendor Ad permissions check
	 *
	 * @returns boolean T: show ad; F: don't show ad
	 * @private
	 */
	_showVendorAds() {
		return this._isVendorAd() && this.props.ads.vendorAds;
	}

	/**
	 * Checks perms to avoid showing ads
	 *
	 * @returns boolean TRUE: do not show ad; FALSE: show ad
	 * @private
	 */
	_noShowAd() {
		return (
			this.state.hiddenByUser
			|| (
				this.props.ads.noAdsPermissions
				&& ( !this._showVendorAds() || !this._isInsuranceAd() )
			)
		);
	}

	/**
	 * We want some ads to be shown always and skip the in-viewport check
	 *
	 * @returns boolean
	 * @private
	 */
	_isAlwaysShown() {
		return (
			this.state.adType === 0 // AFS
			|| this.state.adType === 3 // bottomFixedLeaderboard
			|| this._skipAutoRefresh()
		);
	}

	/**
	 * Is the Vendor position using prebid?
	 * no Vendor Ad returns true to avoid interfere
	 *
	 * @returns {boolean}
	 * @private
	 */
	_isVendorAdPrebid() {
		return this._isVendorAd() ? (this.props.last !== true && this.props.ads.vendorAds !== true) : true;
	}

	/**
	 * Do we trigger prebid bids?
	 *
	 * @returns {boolean}
	 * @private
	 */
	_isPrebid() {
		return (
			!!( typeof pbjs !== 'undefined' && pbjs )
			&& !this._isYieldmo()
			&& this._isVendorAdPrebid() // Vendor ad with no vendor ads perms and not last in page
			&& !this._isSharethrough()
			&& !this._isInsuranceAd()
			&& !( this.state.adType === 4 && this.props.ads.showInterstitial ) // interstitial
		);
	}

	_isYieldmo() {
		return (
			this.props.topifyPresets.forumSite.f_yieldmo
			&& (
				this.state.adType === 5 // 'inContentTopicAd' - yieldmo
				|| this.state.adType === 205 // 'inContentTopicAd2' - yieldmo Hyperscroll/Outstream
			)
		);
	}

	_isSharethrough() {
		return (
			this.props.topifyPresets.forumSite.f_sharethrough &&
			(
				this.state.adType === 105 || // ShareThrough
				this.state.adType === 108 // 'InContentTopicVendorCampaign' - ShareThrough
			)
		);
	}

	_isInsuranceAd() {
		return (
			(this.state.adType >= 50 && this.state.adType <= 59) || // Insurance ads for portal page
			this.state.adType === 101 // InContentHomePage
		);
	}

	/**
	 * Vendor ad adType == 208
	 */
	_isVendorAd( adType = this.state.adType ) {
		return adType === 208 || adType === 9;
	}

	/**
	 * Some ad units don't need us to insert DOM
	 *
	 * @returns {boolean}
	 * @private
	 */
	_doesItNeedDOM() {
		return !( this.state.adType >= 50 && this.state.adType <= 59 ); // portal ads
	}

	/**
	 * Ads allowing swipe to hide
	 *
	 * @returns {boolean}
	 * @private
	 */
	_swipeToHide() {
		return this.state.adType === 3; // Fixed_Bottom_Leaderboard
	}

	render() {
		if(
			!this.state.ready ||
			this._doesItNeedDOM() === false ||
			this.adUnit === null
		) {
			this.theAdElement = document.getElementById( this.props.adName ) || null;
			return null;
		}

		const _adComponent = this._getAdComponent(),
			adUnit = !this.state.hidden && this.state.inViewport ? <React.Fragment>{ _adComponent }{ this._getBtTag() }</React.Fragment> : null;

		if( _adComponent === null || this.state.hiddenByUser ) {
			return null;
		}

		let content = (
			<div id={ this.props.adName + '_container' } className={ this._getAdComponentClasses() } ref={ ad => this.theAdElement = ad }>
				{ adUnit }
			</div>
		);

		// If we have a wrapper tag
		if( this.props.tag ) {
			const CustomTag = `${this.props.tag}`;
			return ( <CustomTag>{ content }</CustomTag> );

		} else {
			return content;
		}
	}
}

AdComponent.defaultProps = {
	isFetching: false
};

AdComponent.propTypes = {
	adName: PropTypes.string.isRequired,
	adType: PropTypes.number.isRequired,
	search: PropTypes.object,
	fallbackAdType: PropTypes.array,
	className: PropTypes.string,
	tag: PropTypes.string,
	isFetching: PropTypes.bool
};

export default connect( adSelector )( AdComponent );
