import { createSelector } from 'reselect';
import querySelector from 'Selectors/queryStringSelector';

const uiSelector = state => state.ui;
const routerSelector = state => state.router;
const userPermissionsSelector = state => state.ustate.perms;
const infiniteScrollSelector = state => state.infiniteScroll;
const topifyPresetsSelector = state => state.topifyPresets;
const adsSelector = state => state.ads;
const isLoggedInSelector = state => state.ustate.id;

	export default createSelector(
	[ uiSelector, routerSelector, userPermissionsSelector, querySelector, infiniteScrollSelector, topifyPresetsSelector, adsSelector, isLoggedInSelector ],
	( ui, router, userPermissions, query, infiniteScroll, topifyPresets, ads, isLoggedIn ) => {
		return {
			ads: {
				showInterstitial: ads.showInterstitial,
				analytics: ads.analytics
			},
			privacySettings: {
				GDPR: topifyPresets.GDPR,
				...ads.GDPRConsent
			},
			ui: {
				overflows: {
					sidebarOpened: ui.sidebarOpened,
					searchBarOpened: ui.searchBarOpened,
					headerOverflowOpened: ui.headerOverflowOpened,
					shareMenuOpened: ui.shareMenuOpened,
					globalLoading: ui.globalLoading,
				},
				modals: {
					loginModalOpened: ui.loginModalOpened,
					confirmModalOpened: ui.confirmModalOpened,
					replyModalOpened: ui.replyModal.opened,
					userModalOpened: ui.userModal.opened,
				}
			},
			announcementsOpen: ui.announcementsOpen,
			userSettings: ui.userSettings,
			firstLoad: topifyPresets.firstLoad,
			initialRoute: topifyPresets.initialRoute,
			initialId: topifyPresets.initialId,
			secondaryId: topifyPresets.secondaryId,
			initialPage: topifyPresets.initialPage,
			forum_url: topifyPresets.forumSite.f_url,
			ff_url: topifyPresets.forumSite.url,
			isVS: topifyPresets.forumSite.isVS,
			userInfo: topifyPresets.userInfo,
			initialMsg: topifyPresets.message,
			infiniteScrollPage: infiniteScroll.page,
			isLoggedIn: topifyPresets.userInfo ? topifyPresets.userInfo.id : isLoggedIn,
			userPermissions,
			router,
			...query,
		};
	}
);
