import React from 'react';
import PropTypes from 'prop-types';

class CountDownComponent extends React.PureComponent {
	constructor(props) {
		super( props );

		this.state = {
			time: props.time || 0
		}
	}

	componentDidMount() {
		if( this.state.time ) {
			// update every second
			this.interval = setInterval( this.countDown, 1000 );
		} else {
			this.stop();
		}
	}

	componentWillUnmount() {
		this.stop( false );
	}

	stop = ( done = true ) => {
		clearInterval( this.interval );

		if( done && this.props.onComplete ) {
			this.props.onComplete();
		}
	};

	countDown = () => {
		if( this.state.time > 0 ) {
			this.setState( { time: this.state.time - 1 } );
		} else {
			this.stop();
		}
	};

	parseTime = () => {
		let prefix = this.props.prefix ? <span key="prefix"> in </span> : '',
			t = 0,
			timeLeft = [],
			diff = this.state.time;

		if( diff >= 3600 ) { // 60 * 60
			t = Math.floor( diff / 3600 );
			diff -= t * 3600;
			timeLeft.push( <span key="hour" className="hour">{`${ t } hour${ t === 1 ? '' : 's'} `}</span> );
		}

		if( diff >= 60 ) {
			t = Math.floor( diff / 60 );
			diff -= t * 60;
			timeLeft.push( <span key="min" className="min">{`${ t } min${ t === 1 ? '' : 's'} `}</span> );
		}

		if( diff ) {
			timeLeft.push( <span key="sec" className="sec">{`${ diff } sec${ diff === 1 ? '' : 's'} `}</span> );

		} else if( timeLeft.length === 0 ) {
			prefix = '';
			timeLeft.push( <span key="now" className="now">now!</span> );
		}

		timeLeft.unshift( prefix );

		return timeLeft;
	};

	render() {
		return <span className="countDown">{ this.parseTime() }</span>
	}
}

CountDownComponent.propTypes = {
	time: PropTypes.number.isRequired
};

export default CountDownComponent;