import {
	SEARCH_FETCH,
	SEARCH_SUCCESS,
	SEARCH_FAILURE,
	RESET_SEARCH,
	SET_SEARCH,
	SEARCH_REQUEST_EPIC,
} from '../actions/SearchResultActions';

import {
	FORUM_FOLLOWING,
	THREAD_FOLLOWING
} from '../actions/ForumviewActions';

import { LOADING_BEHAVIOR } from '../actions/InfiniteScrollActions';
import { isCachedChangedForMultiple } from '../actions/CacheActions';

import { threadFollowing, forumFollowing } from "./followingReducerHelper";

import merge from 'lodash/merge';
import union from 'lodash/union';
import uniq from 'lodash/uniq';

const initialState = {
	isFetching: false,
	isBgFetching: false,
	cacheDate: null,
	loaded: false,
	lastError: '',
	entities: {},
	pages: [],
	result: {
		forums: [],
		posts: [],
		topics: [],
		users: []
	},
	searchId: 0,
	commonwords: [],
	query: {
		searchQuery: '',
		showposts: 0,
		showtopics: 1,
		forumId: '',
		action: ''
	},
	page: 1,
};

export default function searchresult( prevState = initialState, action ) {
	switch( action.type ) {
		case SEARCH_FETCH:
			return Object.assign( {},
				prevState,
				{
					isFetching: true,
					// Only set loaded to false if we are doing a full refresh
					loaded: !(action.query.loadingBehavior === LOADING_BEHAVIOR.REFRESH)
				} );

		case SEARCH_SUCCESS:
			if(
				prevState.query.searchQuery !== action.data.query.searchQuery
				|| ( parseInt( prevState.query.showposts ) !== parseInt( action.data.query.showposts ) && prevState.query.showposts !== action.data.query.showposts )
				|| ( parseInt( prevState.query.showtopics ) !== parseInt( action.data.query.showtopics ) && prevState.query.showtopics !== action.data.query.showtopics )
				|| action.query.loadingBehavior === LOADING_BEHAVIOR.REFRESH
			) {
				return merge(
					{},
					action.data,
					{
						isFetching: false,
						isBgFetching: false,
						cacheDate: null,
						lastError: '',
						cacheChanged: isCachedChangedForMultiple( {
							prevResult: prevState.result,
							prevPage: prevState.page,
							multiple: {
								posts: action.data.result.posts,
								topics: action.data.result.topics,
								forums: action.data.result.forums,
							},
							currPage: action.data.page,
						} ),
						loaded: true,
						query: action.data.query,
						pages: [ parseInt( action.data.page ) ]
					}
				);

			} else {
				let postsResult, topicsResult, forumsResult, usersResult, pages;

				if( action.query.loadingBehavior === LOADING_BEHAVIOR.PREPEND ) {
					postsResult = uniq( [ ...action.data.result.posts, ...prevState.result.posts ] );
					topicsResult = uniq( [ ...action.data.result.topics, ...prevState.result.topics ] );
					forumsResult = uniq( [ ...action.data.result.forums, ...prevState.result.forums ] );
					usersResult = union( action.data.result.users, prevState.result.users );
					pages = uniq( [ parseInt( action.data.page ), ...prevState.pages ] );
				} else if( action.query.loadingBehavior === LOADING_BEHAVIOR.APPEND ) {
					postsResult = uniq( [ ...prevState.result.posts, ...action.data.result.posts ] );
					topicsResult = uniq( [ ...prevState.result.topics, ...action.data.result.topics ] );
					forumsResult = uniq( [ ...prevState.result.forums, ...action.data.result.forums ] );
					usersResult = union( prevState.result.users, action.data.result.users );
					pages = uniq( [ ...prevState.pages, parseInt( action.data.page ) ] );
				}

				return merge(
					{},
					prevState,
					action.data,
					{
						isFetching: false,
						isBgFetching: false,
						cacheDate: null,
						lastError: '',
						cacheChanged: isCachedChangedForMultiple( {
							prevResult: prevState.result,
							prevPage: prevState.page,
							multiple: {
								posts: postsResult,
								topics: topicsResult,
								forums: forumsResult,
							},
							currPage: action.data.page,
						} ),
						loaded: true,
						query: action.query,
					},
					{
						pages: pages,
						result: {
							posts: postsResult,
							topics: topicsResult,
							forums: forumsResult,
							users: usersResult
						}
					}
				);
			}

		case RESET_SEARCH:
			return initialState;

		case SEARCH_FAILURE:
			return Object.assign( {}, prevState, {
				lastError: action.error,
				isFetching: false
			} );

		case FORUM_FOLLOWING:
			return forumFollowing( prevState, action );

		case THREAD_FOLLOWING:
			return threadFollowing( prevState, action );

		case SET_SEARCH:
			return {
				...action.state,
				cacheDate: action.date,
				cacheChanged: false,
			};

		case SEARCH_REQUEST_EPIC:
			return {
				...prevState,
				isBgFetching: true
			};

		default :
			return prevState;
	}

}

